import { SVG } from '../SVG'

const PngIcon = ({ style = {}, ...props }) => (
  <SVG
    width="50"
    height="50"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
    style={{"fill": "var(--primary-color)", ...style}}
    {...props}
  >
    <path d="M12.5 0C10.7812 0 9.375 1.40625 9.375 3.125V46.875C9.375 48.5938 10.7812 50 12.5 50H43.75C45.4688 50 46.875 48.5938 46.875 46.875V12.5L34.375 0H12.5Z" fill="var(--secondary-color)" />
    <path d="M37.5 12.5H46.875L34.375 0V9.375C34.375 11.0938 35.7812 12.5 37.5 12.5Z" fill="var(--secondary-color-darker)" />
    <path d="M46.875 21.875L37.5 12.5H46.875V21.875Z" fill="var(--secondary-color-dark)"/>
    <path d="M40.625 40.625C40.625 41.4844 39.9219 42.1875 39.0625 42.1875H4.6875C3.82812 42.1875 3.125 41.4844 3.125 40.625V25C3.125 24.1406 3.82812 23.4375 4.6875 23.4375H39.0625C39.9219 23.4375 40.625 24.1406 40.625 25V40.625Z" fill="#5F5CF1"/>
    <path d="M39.0625 42.1875H9.375V43.75H39.0625C39.9219 43.75 40.625 43.0469 40.625 42.1875V40.625C40.625 41.4844 39.9219 42.1875 39.0625 42.1875Z" fill="var(--secondary-color-dark)"/>
    <path d="M14.0684 33.8288H11.731V32.3545H14.0684C14.4296 32.3545 14.7237 32.2957 14.9505 32.178C15.1773 32.0562 15.3432 31.8882 15.4482 31.674C15.5532 31.4598 15.6057 31.2183 15.6057 30.9495C15.6057 30.6765 15.5532 30.4223 15.4482 30.1871C15.3432 29.9519 15.1773 29.7629 14.9505 29.6201C14.7237 29.4773 14.4296 29.4059 14.0684 29.4059H12.3862V37.0987H10.4961V27.9253H14.0684C14.7867 27.9253 15.402 28.0555 15.9144 28.3159C16.4311 28.5721 16.8259 28.927 17.0989 29.3807C17.3719 29.8343 17.5084 30.353 17.5084 30.9369C17.5084 31.5291 17.3719 32.0415 17.0989 32.4742C16.8259 32.9068 16.4311 33.2407 15.9144 33.4759C15.402 33.7111 14.7867 33.8288 14.0684 33.8288Z" fill="var(--color-white)"/>
    <path d="M26.0205 27.9253V37.0987H24.1304L20.4447 30.9495V37.0987H18.5546V27.9253H20.4447L24.1367 34.0808V27.9253H26.0205Z" fill="var(--color-white)"/>
    <path d="M34.6775 32.3041V35.9394C34.5347 36.1116 34.3142 36.2985 34.016 36.5001C33.7178 36.6975 33.3335 36.8677 32.863 37.0105C32.3926 37.1533 31.8235 37.2247 31.1556 37.2247C30.5634 37.2247 30.0236 37.1281 29.5364 36.9349C29.0492 36.7374 28.6292 36.4497 28.2763 36.0717C27.9277 35.6937 27.6589 35.2316 27.4699 34.6856C27.2809 34.1354 27.1864 33.5074 27.1864 32.8018V32.2285C27.1864 31.5228 27.2767 30.8949 27.4573 30.3446C27.6421 29.7944 27.9046 29.3303 28.2448 28.9522C28.5851 28.5742 28.9904 28.2865 29.4608 28.0891C29.9312 27.8917 30.4542 27.793 31.0296 27.793C31.8277 27.793 32.4829 27.9232 32.9953 28.1836C33.5078 28.4398 33.8984 28.7968 34.1672 29.2547C34.4402 29.7083 34.6082 30.2291 34.6712 30.8172H32.8378C32.7916 30.5063 32.7034 30.2354 32.5732 30.0044C32.443 29.7734 32.2561 29.5928 32.0125 29.4626C31.7731 29.3324 31.4622 29.2673 31.08 29.2673C30.765 29.2673 30.4836 29.3324 30.2358 29.4626C29.9921 29.5886 29.7863 29.7755 29.6183 30.0233C29.4503 30.2711 29.3222 30.5777 29.234 30.9432C29.1458 31.3086 29.1017 31.7328 29.1017 32.2159V32.8018C29.1017 33.2806 29.1479 33.7048 29.2403 34.0745C29.3327 34.4399 29.4692 34.7486 29.6498 35.0006C29.8346 35.2484 30.0615 35.4354 30.3303 35.5614C30.5991 35.6874 30.912 35.7504 31.269 35.7504C31.5672 35.7504 31.8151 35.7252 32.0125 35.6748C32.2141 35.6244 32.3758 35.5635 32.4976 35.4921C32.6236 35.4165 32.7202 35.3451 32.7874 35.2778V33.6523H31.0611V32.3041H34.6775Z" fill="var(--color-white)"/>
  </SVG>
)

export default PngIcon
