import { useEffect, useMemo, useState } from 'react'
import { BackBtn, Loader } from '@nv/basic-components'
import { ActionDropdown, ListTable } from '@nv/components'
import BalletBoxIcon from '@nv/images/design/ballet-box-icon'
import DividerIcon from '@nv/images/design/divider-icon'
import { AdminVoterAuthService } from '@nv/services'
import AdminServiceQUT from 'apps/queensland/src/app/services/AdminServiceQUT'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import AdminContestDetails from './ContestDetails.module.scss'

const NUM_VOTERS_PER_PAGE = 50

export const ContestDetails = () => {
  const { eventId, qid } = useParams()
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const currentLanguage = i18n.language
  const [event, setEvent]: any = useState([])
  const [constestName, setContestName] = useState('')
  const [loading, setLoading] = useState(true)
  const [tableData, setTableData] = useState([])
  const [tableDataLength, setTableDataLength] = useState(0)
  const [currentPageNum, setCurrentPageNum] = useState(0)
  const [search, setSearch] = useState('')
  const electorActionOptions = [
    { value: 'Edit', label: t('admin.dropdown.edit') },
  ]

  const fetchEvent = async () => {
    setLoading(true)
    const event: any = await AdminVoterAuthService.getEventById(eventId)
    if (event) {
      setEvent(event)
      const contest = event.questions.find((q) => q.qid === qid)
      setContestName(contest.texts[currentLanguage])
      const candidates: any = await AdminServiceQUT.getNomineesPosition(
        eventId,
        qid,
        NUM_VOTERS_PER_PAGE,
        currentPageNum * NUM_VOTERS_PER_PAGE,
        search
      )
      if (candidates) {
        setTableData(candidates.nominees)
        setTableDataLength(candidates.numNominees)
      }
    }
    setLoading(false)
  }
  useEffect(() => {
    fetchEvent()
  }, [])

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'nominee.voterId',
        cell: (info) => info.getValue() || '',
        header: () => t('admin.electorsList.voterId'),
      },
      {
        accessorKey: 'nominee.firstName',
        cell: (info) => info.getValue() || '',
        header: () => t('First Name'),
      },
      {
        accessorKey: 'nominee.preferredName',
        cell: (info) => info.getValue() || '',
        header: () => t('Preferred Name'),
      },
      {
        accessorKey: 'nominee.lastName',
        cell: (info) => info.getValue() || '',
        header: () => t('Last Name'),
      },
      {
        accessorKey: 'nominee.email',
        cell: (info) => info.getValue() || '',
        header: () => t('Email'),
      },
      {
        accessorKey: 'nominee.userType',
        cell: (info) => info.getValue() || '',
        header: () => t('Type'),
      },

      {
        accessorKey: 'nominationState',
        cell: (info) => info.getValue() || '',
        header: () => t('admin.status'),
      },
      {
        accessorKey: 'action',
        cell: (info) => (
          <ActionDropdown
            values={electorActionOptions}
            onCallback={() => {
              history.push(`/admin/candidates/${info.row.original._id}`)
            }}
          />
        ),
        header: () => t('buttons.action'),
      },
    ]
  }, [currentLanguage])
  if (loading) return <Loader />

  return (
    <div className={AdminContestDetails['container']}>
      <BackBtn />
      <div className={AdminContestDetails['header']}>
        <div className={AdminContestDetails['header__details']}>
          <BalletBoxIcon />
          <span className={AdminContestDetails['header__title']}>{event.name[currentLanguage]}</span>
          <span className={AdminContestDetails['header__nominate']}>Nominations are: {event.nominationState}</span>
          <DividerIcon style={{ marginRight: '1.25rem', marginLeft: '1.25rem' }} />
          <span className={AdminContestDetails['header__contests']}>{`${event.questions.length} ${t('Contests')}`}</span>
        </div>
      </div>
      <div>Contest Name: {constestName}</div>
      <div className={AdminContestDetails['table-container']}>
        <ListTable
          data={tableData}
          columns={columns}
          currentPageNum={currentPageNum}
          loading={loading}
          dataLength={tableDataLength}
          setCurrentPageNum={setCurrentPageNum}
          numVotersPerPage={NUM_VOTERS_PER_PAGE}
        />
      </div>
    </div>
  )
}
