import { useState } from 'react'
import PwEyeIcon from '@nv/images/design/pw-eye-icon'
import PwEyeSlashIcon from '@nv/images/design/pw-eye-slash-icon'
import clsx from 'clsx'
import { FormikError } from '../formik_error/FormikError'
import TextFieldStyles from './TextField.module.scss'

export const TextField = ({
  formik,
  field,
  placeholder,
  label,
  disabled = false,
  type = 'text',
  isRequired = false,
  isPassword = false,
  customClass = {},
  min = undefined,
  max = undefined,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false)

  return (
    <div className={clsx(TextFieldStyles['container'], customClass)}>
      {label && (
        <label
          htmlFor={field}
          className={clsx(TextFieldStyles['label'], isRequired && TextFieldStyles['required'])}
        >
          {label}
        </label>
      )}
      <div className={TextFieldStyles['input__container']}>
        <input
          type={isPassword && passwordVisible ? 'text' : isPassword ? 'password' : type}
          placeholder={placeholder}
          className={TextFieldStyles['input']}
          disabled={disabled}
          min={type === 'number' ? min : undefined}
          max={type === 'number' ? max : undefined}
          {...formik.getFieldProps(field)}
        />

        {isPassword && (
          <div className={TextFieldStyles['icon']}>
            {!passwordVisible ? (
              <PwEyeIcon
                onClick={() => setPasswordVisible(true)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') setPasswordVisible(true)
                }}
                role="button"
                tabIndex={0}
              />
            ) : (
              <PwEyeSlashIcon
                onClick={() => setPasswordVisible(false)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') setPasswordVisible(false)
                }}
                role="button"
                tabIndex={0}
              />
            )}
          </div>
        )}
      </div>

      {formik && formik.touched[field] && formik.errors[field] && (
        <FormikError
          formik={formik}
          fieldName={field}
          errorLabel={formik.errors[field]}
        />
      )}
    </div>
  )
}

export default TextField
