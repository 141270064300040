import { STATE_STATUSES, getEventStateStatus } from '@nv/utils'
import CountdownTimerBig from './CountdownTimerBig'
import CountdownTimerSmall from './CountdownTimerSmall'
import FormattedDate from './FormattedDate'
import RenderTBD from './RenderTBD'

const CountdownTimer = (props) => {
  const { size } = props
  if (size === 'small') {
    return <CountdownTimerSmall {...props} />
  }
  return <CountdownTimerBig {...props} />
}

export const EventCountdownTimeDateContainer = ({
  event,
  votingOpen,
  size = 'big',
  layout = null,
  hideFormattedDate = false,
}) => {
  const eventStateStatus = getEventStateStatus(event.state, votingOpen, event.nominationState)

  const diffVotingOpen = new Date().getTime() - new Date(event.openDate).getTime()
  const diffNominationOpen = event.nominationOpenDate
    ? new Date().getTime() - new Date(event.nominationOpenDate).getTime()
    : 0
  switch (eventStateStatus) {
    case STATE_STATUSES.nominationUpcoming:
      if (diffNominationOpen <= 0)
        return (
          <CountdownTimer
            size={size}
            countDownDate={event.nominationOpenDate}
            status={STATE_STATUSES.nominationUpcoming}
            layout={layout}
          />
        )
      return <RenderTBD status={STATE_STATUSES.upcoming} size={size} layout={layout} />
    case STATE_STATUSES.nominationStarted:
      return (
        <CountdownTimer
          size={size}
          countDownDate={event.nominationCloseDate}
          status={STATE_STATUSES.nominationStarted}
          layout={layout}
        />
      )
    case STATE_STATUSES.upcoming:
      if (diffVotingOpen <= 0)
        return (
          <CountdownTimer
            size={size}
            countDownDate={event.openDate}
            status={STATE_STATUSES.upcoming}
            layout={layout}
          />
        )
      return <RenderTBD status={STATE_STATUSES.upcoming} size={size} layout={layout} />
    case STATE_STATUSES.active:
      return (
        <CountdownTimer
          size={size}
          countDownDate={event.closeDate}
          status={STATE_STATUSES.active}
          layout={layout}
        />
      )
    case STATE_STATUSES.closed:
      if (hideFormattedDate) {
        return <div />
      }
      return <FormattedDate date={event.closeDate} status={STATE_STATUSES.closed} size={size} />
  }
}
export default EventCountdownTimeDateContainer
