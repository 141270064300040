import { useState } from 'react'
import DeleteIcon from '@nv/images/design/delete-icon'
import DownloadFileIcon from '@nv/images/design/download-file-icon'
import ViewIcon from '@nv/images/design/view-icon'
import { AdminVoterAuthService } from '@nv/services'
import { beforeUnload } from '@nv/utils'
import { useTranslation } from 'react-i18next'
import { ActionDropdown } from '../action_dropdown/ActionDropdown'
import FileIcon from '../file_icon/FileIcon'
import { DeleteFileModal } from '../modal_master/modals/delete_file_modal/DeleteFileModal'
import FileViewerModal from '../modal_master/modals/file_viewer_modal/FileViewerModal'
import DocCardStyles from './DocCard.module.scss'

const DocCard = ({ doc, getDocs, index, viewOnly }) => {
  const [deleteModal, setDeleteModal] = useState(false)
  const [openFileModal, setOpenFileModal] = useState(false)
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language

  const deleteFile = async () => {
    try {
      await AdminVoterAuthService.deleteHelpDoc(doc.id)
      getDocs()
      setDeleteModal(false)
    } catch (error) {
      // Handle error
    }
  }

  const downloadFile = async () => {
    try {
      window.removeEventListener('beforeunload', beforeUnload)

      const alink = document.createElement('a')
      alink.href = doc.fileUrl[currentLanguage]
      alink.download = `${doc.filename[currentLanguage]}`
      document.body.appendChild(alink)
      alink.click()
      document.body.removeChild(alink)
    } catch (error) {
      // Handle error
    }
  }

  if (!doc.filename || !doc.filename[currentLanguage] || !doc.title || !doc.title[currentLanguage]) {
    return <div />
  }

  const actionData = [
    {
      value: 'Download',
      label: 'Download',
      callback: downloadFile,
      icon: <DownloadFileIcon color={'var(--secondary-color-darker)'} />,
    },
    {
      value: 'View',
      label: 'View',
      callback: () => setOpenFileModal(true),
      icon: <ViewIcon color={'var(--secondary-color-darker)'} />,
    },
    {
      value: 'Delete',
      label: 'Delete',
      callback: () => setDeleteModal(true),
      icon: <DeleteIcon color={'var(--secondary-color-darker)'} />,
    }
  ]

  return (
    <div className={DocCardStyles['container']} key={index}>
      <DeleteFileModal
        modalOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        onDelete={deleteFile}
      />

      {openFileModal && (
        <FileViewerModal
          file={doc}
          modalIsOpen={openFileModal}
          closeModal={() => setOpenFileModal(false)}
        />
      )}

      {/* body */}
      <div className={DocCardStyles['body']}>
        <span className={DocCardStyles['body__txt']}>{doc.title[currentLanguage]}</span>
      </div>

      {/* footer */}
      <div className={DocCardStyles['footer']}>
        <FileIcon filename={doc.filename} />

        <ActionDropdown
          values={actionData}
        />
      </div>
    </div>
  )
}

export default DocCard
