export const STATE_STATUSES = {
  active: 'active',
  upcoming: 'upcoming',
  closed: 'closed',
  nominationUpcoming: 'nominationUpcoming',
  nominationStarted: 'nominationStarted',
}

export const COUNTDOWN_LABEL = {
  active: 'Voting closes in',
  upcoming: 'Voting opens in',
  closed: 'Voting closed on',
  nominationUpcoming: 'Nominations open in',
  nominationStarted: 'Nominations are open',
}

export const getEventStateStatus = (eventState, votingOpen, nominationState = 'disabled') => {
  if (votingOpen == null) {
    return
  }
  if (votingOpen) {
    return STATE_STATUSES.active
  } else if (eventState === 'started' && !votingOpen) {
    return STATE_STATUSES.closed
  } else if (['created', 'finalized'].includes(eventState)) {
    // nominations are open when voting is upcoming
    if (nominationState === 'upcoming') {
      return STATE_STATUSES.nominationUpcoming
    } else if (nominationState === 'started') {
      return STATE_STATUSES.nominationStarted
    }
    return STATE_STATUSES.upcoming
  } else if (['ended', 'decrypting', 'decrypted', 'tallied'].includes(eventState)) {
    return STATE_STATUSES.closed
  }
}
