import { SVG } from '../SVG'

const DividerIcon = ({ style = {}, ...props }) => (
  <SVG
    width='2'
    height='24'
    viewBox='0 0 2 24'
    xmlns='http://www.w3.org/2000/svg'
    style={{ stroke: 'var(--secondary-color)', ...style }}
    {...props}
  >
    <path d='M1 0V24' strokeWidth='2' />
  </SVG>
)

export default DividerIcon
