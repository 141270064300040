import HandIcon from '@nv/images/design/hand-icon.jsx'
import { useTranslation } from 'react-i18next'
import UsernameBannerStyles from './UsernameBanner.module.scss'

export const UsernameBanner = () => {
  const { t } = useTranslation()

  return (
    <div className={UsernameBannerStyles['container']}>
      <HandIcon className={UsernameBannerStyles['hand-icon']} />
      <span className={UsernameBannerStyles['text']}>{t('auth.authMethod.username-banner')}</span>
    </div>
  )
}
