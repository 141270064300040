import { Suspense, useEffect } from 'react'
import { useKeycloakSession } from '@nv/hooks'
import { BrowserRouter } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { Routes } from './routing/Routes'
import './styles/global.scss'

const cookies = new Cookies()
const VERIFY_COOKIE = 'verifyToken'
const VERIFY_COOKIE_ADMIN = 'verifyTokenAdmin'

export const beforeUnload = (event) => {
  event.returnValue = 'Are you sure you want to leave?'
}
const App = ({ basename }) => {
  useEffect(() => {
    cookies.remove(VERIFY_COOKIE)
    cookies.remove(VERIFY_COOKIE_ADMIN)
  }, [])

  useKeycloakSession()

  return (
    <Suspense fallback={<div></div>}>
      <BrowserRouter basename={basename}>
        <Routes />
      </BrowserRouter>
    </Suspense>
  )
}

export { App }
