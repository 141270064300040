import { jumioInstance as instance } from './Instance'

export const JumioRegistration = {
  startIdIdentityFlow: async () => {
    try {
      const res = await instance.post('/start')
      //   //console.log(res)
      return { success: true, iframeUrl: res.iframeUrl }
    } catch (error) {
      return { error: true }
    }
  },
  onSuccessdIdentityFlow: async ({ accountId, workflowId }) => {
    try {
      const res = await instance.get(`/success/accounts/${accountId}/flows/${workflowId}/`)
      return { success: true, userData: res.userData }
    } catch (error) {
      return { error, data: error.data }
    }
  },
  onIntitiateVerification: async ({ verifyMethod }, data) => {
    try {
      verifyMethod = verifyMethod.toLowerCase().replace(/-/g, '')
      const res = await instance.post(`/register/mfa/initiate/${verifyMethod}`, data)
      return { success: true, qrCodeDataUrl: res.qrCodeDataUrl, factorSid: res.factorSid }
    } catch (error) {
      return { error: true }
    }
  },
  onSubmitRegistration: async (
    { accountId, workflowId, verificationCode, qrCodeDataUrl, factorSid, verifyMethod },
    data
  ) => {
    try {
      data.verificationCode = verificationCode
      data.qrCodeDataUrl = qrCodeDataUrl
      data.factorSid = factorSid
      verifyMethod = verifyMethod.toLowerCase().replace(/-/g, '')
      const res = await instance.post(
        `/register/accounts/${accountId}/flows/${workflowId}/verify/${verifyMethod}`,
        data
      )
      return {
        success: true,
        userData: res.data,
        applicationCode: res.data.applicationCode,
        status: res.data.status,
      }
    } catch (error) {
      //console.log(error)
      return { error }
    }
  },
}
