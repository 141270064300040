import { useAuthAdminKc } from '@nv/zustand'
import AdminAxiosInstance from './AdminAxiosInstance'

const axiosInstance = AdminAxiosInstance

export const AdminVoterService = {
  getUserInfoKeycloakToken: () => {
    try {
      return useAuthAdminKc.getState().user
    } catch (error) {
      /* empty */
    }
  },
  // TODO user not logged in keycloak
  // TODO update on login & if logged in whenever change
  //   updateUserLanguage: async (language) => {
  //     try {
  //       await axiosInstance.put('/account/language', {language: language})
  //     } catch (error) {}
  //   },
  syncEvents: async () => {
    try {
      const events = await axiosInstance.get('/events/v2/sync')
      return { events }
    } catch (error) {
      /* empty */
    }
  },
  syncCalendarEvents: async () => {
    try {
      const events = await axiosInstance.get('/events/sync')
      return { events }
    } catch (error) {
      /* empty */
    }
  },
  updateEvent: async (eventId, votingDistrict, votingChannel) => {
    try {
      const event = await axiosInstance.put(`/events/${eventId}`, {
        votingDistrict: votingDistrict,
        votingChannel: votingChannel,
      })
      return { event, success: true }
    } catch (error) {
      //console.log(error)
    }
  },
  getVoterCount: async () => {
    const count = await axiosInstance.get('/count-users-db')
    try {
      return count
    } catch (error) {
      //console.error(count)
    }
  },
  getEligibleVoterCount: async () => {
    const count = await axiosInstance.get('/count-eligible-users-db')
    try {
      return count
    } catch (error) {
      //console.error(count)
    }
  },
  uploadVoterList: async (voterlist) => {
    try {
      const resp = await axiosInstance.post('/voters', voterlist)
      //console.log(resp)
      return { success: true, ...resp }
    } catch (error: any) {
      if (error?.response?.data) {
        //console.error(error.response.data)
        const errorDisplay = error.response.data
        if (errorDisplay.error?.code === 500) {
          return {
            error: {
              ...errorDisplay,
              message: 'Something went wrong, please contact support',
            },
          }
        }
        return errorDisplay
      } else {
        //console.error(error)
        return {
          error: {
            code: 500,
            message: 'Something went wrong, please contact support',
          },
        }
      }
    }
  },
  uploadEligibleVoterList: async (voterlist) => {
    try {
      const resp = await axiosInstance.post('/eligiblevoters', voterlist)
      //console.log(resp)
      return { success: true, ...resp }
    } catch (error: any) {
      if (error?.response?.data) {
        //console.error(error.response.data)
        const errorDisplay = error.response.data
        if (errorDisplay.error?.code === 500) {
          return {
            error: {
              ...errorDisplay,
              message: 'Something went wrong, please contact support',
            },
          }
        }
        return errorDisplay
      } else {
        //console.error(error)
        return {
          error: {
            code: 500,
            message: 'Something went wrong, please contact support',
          },
        }
      }
    }
  },
  uploadStrikeOffVoterList: async (voterlist) => {
    try {
      const resp = await axiosInstance.post('/eligiblevoters/strikeoff', voterlist)
      //console.log(resp)
      return { success: true, ...resp }
    } catch (error: any) {
      if (error?.response?.data) {
        //console.error(error.response.data)
        const errorDisplay = error.response.data
        if (errorDisplay.error?.code === 500) {
          return {
            error: {
              ...errorDisplay,
              message: 'Something went wrong, please contact support',
            },
          }
        }
        return errorDisplay
      } else {
        //console.error(error)
        return {
          error: {
            code: 500,
            message: 'Something went wrong, please contact support',
          },
        }
      }
    }
  },
  removeIneligibleVoters: async (voterlist) => {
    try {
      const resp = await axiosInstance.post('/eligiblevoters/delete', voterlist)
      //console.log(resp)
      return { success: true, ...resp }
    } catch (error: any) {
      if (error?.response?.data) {
        //console.error(error.response.data)
        const errorDisplay = error.response.data
        if (errorDisplay.error?.code === 500) {
          return {
            error: {
              ...errorDisplay,
              message: 'Something went wrong, please contact support',
            },
          }
        }
        return errorDisplay
      } else {
        //console.error(error)
        return {
          error: {
            code: 500,
            message: 'Something went wrong, please contact support',
          },
        }
      }
    }
  },
  fetchVoters: async (filters = null, channelFilter = 'online', limit = 1000, skip = 0, search = '') => {
    try {
      let url = `/users?votingChannel=${channelFilter}&limit=${limit}&skip=${skip}&search=${search}`
      const keys = Object.keys(filters)
      for (var key of keys) {
        const filter = filters[key]
        if (filter) {
          let fitlerString = ''
          if (Array.isArray(filter)) {
            const values = filter.map((f) => f.accountId)
            fitlerString = `&${key}=${values.toString()}`
          } else {
            fitlerString = `&${key}=${filter.accountId}`
          }
          url += fitlerString
        }
      }
      //console.log(url)
      const res: any = await axiosInstance.get(url)
      //console.log(res)
      return { success: true, users: res.users, numUsers: res.numUsers }
    } catch (error) {
      //console.log(error)
    }
  },
  fetchEligibleVoters: async (districtFilter = 'all', limit = 1000, skip = 0, search = '') => {
    try {
      const res: any = await axiosInstance.get(
        `/eligiblevoters?votingDistrict=${districtFilter}&limit=${limit}&skip=${skip}&search=${search}`,
      )
      // console.log(res)
      return { success: true, users: res.users, numUsers: res.numUsers }
    } catch (error) {
      //console.log(error)
    }
  },
  getVoterCsv: async (filters = null, channelFilter = 'online') => {
    try {
      let url = `/users/csv?votingChannel=${channelFilter}`
      const keys = Object.keys(filters)
      for (var key of keys) {
        const filter = filters[key]
        if (filter) {
          let fitlerString = ''
          if (Array.isArray(filter)) {
            const values = filter.map((f) => f.accountId)
            fitlerString = `&${key}=${values.toString()}`
          } else {
            fitlerString = `&${key}=${filter.accountId}`
          }
          url += fitlerString
        }
      }
      //console.log(url)
      const result = await axiosInstance.get(url, {
        responseType: 'blob',
      })
      return { success: true, data: result }
    } catch (error) {
      //console.log(error)
    }
  },
  getEventVoterList: async (tiviId, limit = 1000, skip = 0, search = '') => {
    try {
      const res: any = await axiosInstance.get(`/events/${tiviId}/voters?limit=${limit}&skip=${skip}&search=${search}`)
      return { users: res.users, numUsers: res.numUsers }
    } catch (error) {
      //console.error(error)
      //return {error}
    }
  },
  getEventVoterListCSV: async (tiviId) => {
    try {
      const result = await axiosInstance.get(`/events/${tiviId}/voters/csv`, {
        responseType: 'blob',
      })
      return { success: true, data: result }
    } catch (error) {
      //console.error(error)
      //return {error}
    }
  },
  getOnlineEventsList: async (district) => {
    try {
      const events = await axiosInstance.get(`/events/online?votingDistrict=${district}`)
      return events
    } catch (error) {
      //console.error(error)
      // return {error}
    }
  },
  sendEmailsOnlineVoters: async (tiviId) => {
    try {
      if (tiviId) {
        await axiosInstance.post(`/events/${tiviId}/email/voters`)
      } else {
        await axiosInstance.post(`/email/voters`)
      }
      return { success: true }
    } catch (error) {
      //console.error(error)
      // return {error}
    }
  },
  getVoteReceiptReport: async (tiviId, language) => {
    try {
      const data = await axiosInstance.get(`/votereceiptreport/${tiviId}?language=${language}`, {
        responseType: 'blob',
      })
      return { success: true, data }
    } catch (error) {
      //console.error(error)
      // return {error}
    }
  },
  getAuthLogReport: async (formData, language) => {
    try {
      let url = `/authlogs/${formData.userType}?language=${language}`

      let startDate = ''
      let endDate = ''
      if (formData.startDate && formData.endDate) {
        startDate = formData.startDate.toISOString()
        endDate = formData.endDate.toISOString()
        url = `${url}&startDate=${startDate}&endDate=${endDate}`
      }
      const data = await axiosInstance.get(url, {
        responseType: 'blob',
      })
      return { success: true, data }
    } catch (error) {
      //console.error(error)
      // return {error}
    }
  },
  updateUserLanguage: async (language) => {
    try {
      await axiosInstance.put('/account/language', { language: language })
    } catch (error) {
      /* empty */
    }
  },
  // updateType is enum : ['edit, 'update']
  // voterId is user.voterId
  // tiviId is event.tiviId
  updateVoter: async ({ voterId, email = '', phone = '', updateType }) => {
    try {
      await axiosInstance.post(`/users/${voterId}/update/${updateType}`, {
        email,
        phone,
      })
      return { success: true }
    } catch (error) {
      return { success: false }
    }
  },
  resetVoterPassword: async ({ keycloakId }) => {
    try {
      await axiosInstance.post(`/users/${keycloakId}/resetpassword`)
      return { success: true }
    } catch (error) {
      return { success: false }
    }
  },
  getParentFilters: async (accountType) => {
    try {
      //console.log('accountType', accountType)

      const response: any = await axiosInstance.get(`/pipsc/accounts?accountType=${accountType}`)
      const listOptions = response.map((r) => {
        return {
          ...r,
          label: r.title?.en ? r.title : r.abbreviation,
          value: r.accountId,
        }
      })
      return listOptions
    } catch (error) {
      //console.log(error)
    }
  },
  getChildFilters: async (parentId) => {
    try {
      //console.log('parentId', parentId)
      const response: any = await axiosInstance.get(`/pipsc/accounts?parentId=${parentId}`)
      const listOptions = response.map((r) => {
        return {
          ...r,
          label: r.title?.en ? r.title : r.abbreviation,
          value: r.accountId,
        }
      })
      return listOptions
    } catch (error) {
      //console.log(error)
    }
  },
  approveVoter: async (id, electorId) => {
    try {
      await axiosInstance.put(`/voters/${id}/approve/${electorId}`)
      return { success: true }
    } catch (error) {
      return { error }
    }
  },
  declineVoter: async (id) => {
    try {
      await axiosInstance.put(`/voters/${id}/decline`)
      return { success: true }
    } catch (error) {
      return { success: false }
    }
  },
  disableVoter: async (electorId) => {
    try {
      const response = await axiosInstance.put(`/eligiblevoters/${electorId}/disable`)

      return response
    } catch (error) {
      //console.log(error)
    }
  },
  getFilterByGroup: async (accountType) => {
    try {
      const response = await axiosInstance.get(`/pipsc/accounts?accountType=${accountType}`)

      return response
    } catch (error) {
      //console.log(error)
    }
  },
  getSubGroupOptions: async ({ parentId }) => {
    try {
      const response = await axiosInstance.get(`/pipsc/accounts?parentId=${parentId}`)

      return response
    } catch (error) {
      //console.log(error)
    }
  },
}
