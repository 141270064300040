import { useCallback, useEffect, useState } from 'react'
import { useResizeObserver } from '@wojtekmaj/react-hooks'
import { Document as PDFDocument, Page, pdfjs } from 'react-pdf'
import type { PDFDocumentProxy } from 'pdfjs-dist'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import PdfViewerStyles from './PdfViewer.module.scss'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
}

const resizeObserverOptions = {}

const maxWidth = 800

const PDFViewer = ({ pdfViewURL }) => {
  useEffect(() => {
    //console.log('pdfViewURL', pdfViewURL)
  }, [])

  const [numPages, setNumPages] = useState<number>()
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null)
  const [containerWidth, setContainerWidth] = useState<number>()

  const onResize = useCallback<ResizeObserverCallback>((entries) => {
    const [entry] = entries

    if (entry) {
      setContainerWidth(entry.contentRect.width)
    }
  }, [])

  useResizeObserver(containerRef, resizeObserverOptions, onResize)

  function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy): void {
    setNumPages(nextNumPages)
  }

  return (
    <div className={PdfViewerStyles['container__document']} ref={setContainerRef}>
      <PDFDocument file={pdfViewURL} onLoadSuccess={onDocumentLoadSuccess} options={options}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
            renderTextLayer={false}
          />
        ))}
      </PDFDocument>
    </div>
  )
}

export default PDFViewer
