import { useState } from 'react'
import clsx from 'clsx'
import TabsStyles from './Tabs.module.scss'

export const Tabs = ({ tabs, className = null }) => {
  const [activeTab, setActiveTab] = useState(0)

  const handleTabClick = (index: number) => {
    setActiveTab(index)
  }

  return (
    <>
      <div className={clsx(TabsStyles['container'], className)}>
        <div className={clsx(TabsStyles['wrapper'], className)}>
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={clsx(TabsStyles['button'], {
                [TabsStyles['active']]: activeTab === index,
              })}
              onClick={() => handleTabClick(index)}
            >
              {tab.label}
            </button>
          ))}
        </div>
      </div>
      {tabs[activeTab].content}
    </>
  )
}
