import { useEffect, useState } from 'react'
import { FormikError, TextField } from '@nv/basic-components'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { ModalWrapper } from '../../ModalWrapper'
import AddLinkModalStyles from './AddLinkModal.module.scss'

const initialValues = {
  englishTitle: '',
  frenchTitle: '',
  url: '',
}

export const AddLinkModal = ({
  modalOpen,
  onRequestClose = undefined
}) => {
  const { t } = useTranslation()

  const [validationSchema, setValidationSchema] = useState(
    Yup.object().shape({
      englishTitle: Yup.string().required(t('uploads.document.validations.title')),
      frenchTitle: Yup.string().required(t('uploads.document.validations.title')),
      url: Yup.string()
        .required('URL is required')
        .matches(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/, 'Invalid URL format')
        .url('Invalid URL format'),
    }),
  )

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        // Handle form submission
      }, 1000)
    },
  })

  const updateSchema = (newSchema) => {
    setValidationSchema(newSchema)
  }

  useEffect(() => {
    if (
      (formik.values['englishTitle'] && formik.values['url'] && !formik.errors['url']) ||
      (formik.values['frenchTitle'] && formik.values['url'] && !formik.errors['url'])
    ) {
      updateSchema(Yup.object().shape({}))
    }
  }, [formik.errors])

  const btns = [
    {
      text: t('buttons.cancel'),
      onClick: onRequestClose,
      isCancel: true,
    },
    {
      text: t('buttons.add'),
      onClick: onRequestClose,
    },
  ]

  return (
    <ModalWrapper
      isOpen={modalOpen}
      onRequestClose={onRequestClose}
      headerLabel={t('form.labels.addLink')}
      btns={btns}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className={AddLinkModalStyles["container"]}>
          <TextField
            formik={formik}
            type="text"
            field={'englishTitle'}
            placeholder={t('form.labels.englishTitle')}
            label={t('form.labels.englishTitle')}
          />

          <TextField
            formik={formik}
            type="text"
            field={'frenchTitle'}
            placeholder={t('form.labels.frenchTitle')}
            label={t('form.labels.frenchTitle')}
          />

          <TextField
            formik={formik}
            type="text"
            field={'url'}
            placeholder={t('form.labels.url')}
            label={t('form.labels.url')}
          />

          {formik.errors['englishTitle'] && formik.errors['frenchTitle'] && (
            <FormikError formik={formik} fieldName="englishTitle" errorLabel={t('errors.titleRequired')} />
          )}

          {formik.errors['url'] && <FormikError formik={formik} fieldName="url" errorLabel={t(formik.errors['url'])} />}
        </div>
      </form>
    </ModalWrapper>
  )
}
