import clsx from 'clsx'
import { FormikError } from '../formik_error/FormikError'
import CheckBoxStyles from './CheckBox.module.scss'

export const CheckBox = ({
  checked = undefined,
  onChange = undefined,
  formik = undefined,
  field = undefined,
  label = undefined,
  labelStyling = {},
  containerStyling = {},
}) => {
  const handleClick = () => {
    onChange(!checked)
  }

  return (
    <>
      <div
        style={containerStyling}
        className={CheckBoxStyles['circle-btn']}
        onClick={handleClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') handleClick()
        }}
        role="button"
        tabIndex={0}
      >
        <div
          className={clsx(CheckBoxStyles['circle-btn__toggle'], {
            [CheckBoxStyles['circle-btn__toggle--active']]: checked,
          })}
        ></div>

        <span className={CheckBoxStyles['circle-btn__label']} style={labelStyling}>
          {label}
        </span>
      </div>
      {formik && formik.touched[field] && formik.errors[field] && (
        <FormikError
          formik={formik}
          fieldName={field}
          errorLabel={formik.errors[field]}
        />
      )}

    </>
  )
}
