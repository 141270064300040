import clsx from 'clsx'
import CustomBtnStyles from './CustomBtn.module.scss'

export const CustomBtn = (props) => {
  const {
    variant = 'action',
    text,
    height,
    width,
    disabled,
    customClass,
    customStyling,
    onClick,
    type = 'button',
  } = props

  const buttonVariants = {
    action: CustomBtnStyles['btn--action'],
    primary: CustomBtnStyles['btn--primary'],
    primaryGrey: CustomBtnStyles['btn--primary-grey'],
    secondary: CustomBtnStyles['btn--cancel'],
  }

  return (
    <button
      type={type}
      style={{ height, width, ...customStyling }}
      className={clsx(CustomBtnStyles['btn'], buttonVariants[variant], customClass, {
        [CustomBtnStyles['btn--disabled']]: disabled,
      })}
      disabled={disabled}
      onClick={onClick}
    >
      <span className={CustomBtnStyles['btn__content']}>{text}</span>
    </button>
  )
}
