import { useTranslation } from 'react-i18next'
import RecaptchaStyles from './RecaptchaDisclaimer.module.scss'

export const RecaptchaDisclaimer = () => {
  const { t } = useTranslation()

  return (
    <span className={RecaptchaStyles['container']}>
      {t('main.recaptcha.disclaimer')}
      <a className={RecaptchaStyles['link']} href='https://policies.google.com/privacy'>
        {t('main.recaptcha.privacy_policy')}
      </a>
      {t('main.recaptcha.and')}
      <a className={RecaptchaStyles['link']} href='https://policies.google.com/terms'>
        {t('main.recaptcha.terms')}
      </a>
      {t('main.recaptcha.apply')}.
    </span>
  )
}
