import AudioIcon from '@nv/images/design/audio-icon'
import DocIcon from '@nv/images/design/doc-icon'
import JpgIcon from '@nv/images/design/jpg-icon'
import PdfIcon from '@nv/images/design/pdf-icon'
import PngIcon from '@nv/images/design/png-icon'
import VideoIcon from '@nv/images/design/video-icon'
import { getFileType } from '@nv/utils'
import { useTranslation } from 'react-i18next'

const FileIcon = ({ filename }) => {
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  const fileType = getFileType(filename[currentLanguage])

  const getIcon = () => {
    switch (fileType) {
      case 'pdf':
        return <PdfIcon />
      case 'jpg':
      case 'jpeg':
        return <JpgIcon />
      case 'png':
        return <PngIcon />
      case 'doc':
      case 'docx':
        return <DocIcon />
      case 'mov':
      case 'mp4':
        return <VideoIcon />
      case 'mp3':
      case 'wav':
        return <AudioIcon />
      default:
        return null
    }
  }

  return getIcon()
}

export default FileIcon
