import { SVG } from '../SVG'

const PrinterFailIcon = ({ style={}, ...props }) => (
  <SVG
    width="72" 
    height="75" 
    viewBox="0 0 72 75" 
    fill="none"
    strokeWidth="4" 
    strokeLinecap="round" 
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
    style={{"stroke": "var(--primary-color)", ...style}}
    {...props}
  >
    <path d="M65.5713 44.7662V24.5391H2.12305V56.7686H13.7143" stroke="var(--tertiary-color)" />
    <path d="M45.5962 46.6719H14.3608V71.1196H55.2841V68.1376" stroke="var(--tertiary-color)" />
    <path d="M54.7351 23.3495C54.8693 22.6558 54.7351 2.15625 54.7351 2.15625H13.5435V23.3495" stroke="var(--tertiary-color)" />
    <path d="M10.6763 33.5938H28.4048" stroke="var(--tertiary-color)" />
    <path d="M56.5653 33.6064C57.2796 33.6064 57.8587 33.0186 57.8587 32.2934C57.8587 31.5683 57.2796 30.9805 56.5653 30.9805C55.851 30.9805 55.272 31.5683 55.272 32.2934C55.272 33.0186 55.851 33.6064 56.5653 33.6064Z" stroke="var(--tertiary-color)" />
    <path d="M56.5715 67.241C63.6723 67.241 69.4286 61.4516 69.4286 54.3099C69.4286 47.1683 63.6723 41.3789 56.5715 41.3789C49.4707 41.3789 43.7144 47.1683 43.7144 54.3099C43.7144 61.4516 49.4707 67.241 56.5715 67.241Z" stroke="var(--warning-color)" />
    <path d="M52.7466 49.3672L61.3562 57.9768" stroke="var(--warning-color)" />
    <path d="M52.8025 57.9141L61.2835 49.4331" stroke="var(--warning-color)" />
  </SVG>
)

export default PrinterFailIcon
