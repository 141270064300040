import { SVG } from '../SVG'

const CheckMarkIcon = ({ style={}, ...props }) => (
  <SVG
    width="16"
    height="12"
    viewBox="0 0 16 12"
    xmlns="http://www.w3.org/2000/svg"
    style={{"stroke": "var(--success-color)", ...style}}
    fill="none"
    {...props}
  >
    <g>
      <path 
        d="M14.5 1.5L6.06806 10.5L1.5 5.59091"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </SVG>
)

export default CheckMarkIcon
