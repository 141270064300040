import { SVG } from '../SVG'

const DropdownArrowIcon = ({ style = {}, ...props }) => (
  <SVG
    width="14" 
    height="14" 
    viewBox="0 0 14 14" 
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ ...style }}
    {...props}
  >
    <path d="M5.46967 8.96967C5.17678 9.26256 5.17678 9.73744 5.46967 10.0303C5.76256 10.3232 6.23744 10.3232 6.53033 10.0303L9.03033 7.53033C9.32322 7.23744 9.32322 6.76256 9.03033 6.46967L6.53033 3.96967C6.23744 3.67678 5.76256 3.67678 5.46967 3.96967C5.17678 4.26256 5.17678 4.73744 5.46967 5.03033L7.43934 7L5.46967 8.96967Z" fill="black" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7 0C10.866 -1.68988e-07 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 1.68988e-07 10.866 0 7C-1.68988e-07 3.13401 3.13401 1.68988e-07 7 0ZM7 1.5C10.0376 1.5 12.5 3.96243 12.5 7C12.5 10.0376 10.0376 12.5 7 12.5C3.96243 12.5 1.5 10.0376 1.5 7C1.5 3.96243 3.96243 1.5 7 1.5Z" fill="black"/>
  </SVG>
)

export default DropdownArrowIcon
