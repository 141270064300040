import { useState } from 'react'
import { CustomBtn, TextField } from '@nv/basic-components'
import UploadIcon from '@nv/images/design/upload-icon'
import { useTranslation } from 'react-i18next'
import FileUploaderModal from './file_uploader_modal/FileUploaderModal'
import UploadedDocument from './uploaded_doc/UploadedDocument'
import DocumentInputUploadStyles from './DocumentInputUpload.module.scss'

const DocumentInputUpload = ({
  formik,
  textFieldName,
  documentFieldName = null,
  label,
  style = {},
  setUploadErr,
  onCallback = (data) => { },
}) => {
  const { t } = useTranslation()
  const [localFileData, setLocalFileData] = useState(null)
  const [openModal, setModalOpen] = useState(false)

  const addDocBtn = (
    <div
      className={DocumentInputUploadStyles['btn']}
      onClick={() => {
        setModalOpen(true)
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') setModalOpen(true)
      }}
      role="button"
      tabIndex={0}
    >
      <div className={DocumentInputUploadStyles['icon']}>
        <UploadIcon />
        <span className={DocumentInputUploadStyles['text']}>{t('uploads.document.add')}</span>
      </div>
      <span>{t('uploads.file.sizeMax', { maxFileSize: 25 })}</span>
    </div>
  )

  return (
    <>
      <div style={style}>
        <TextField
          formik={formik}
          type='text'
          field={textFieldName}
          placeholder={label}
          label={label}
        />

        {localFileData ? (
          <UploadedDocument
            localFile={localFileData}
            onChangeFile={() => {
              setLocalFileData(null)
              setModalOpen(true)
            }}
          />
        ) : (
          <>
            {openModal ? (
              <>
                <div className={DocumentInputUploadStyles['header']}>
                  <CustomBtn
                    variant='primaryGrey'
                    text={t('buttons.close')}
                    height={30}
                    width={100}
                    customStyling={{}}
                    onClick={() => {
                      setModalOpen(false)
                    }}
                  />
                </div>

                <FileUploaderModal
                  setUploadErr={setUploadErr}
                  customStyling={{ height: 200, paddingTop: 20, paddingLeft: 50, paddingRight: 50 }}
                  onCallback={(res) => {
                    setLocalFileData({ name: res.data.name, file: res.data.file })

                    formik.setFieldValue(documentFieldName, {
                      name: res.data.name,
                      file: res.data.file,
                    })
                  }}
                />
              </>
            ) : (
              addDocBtn
            )}
          </>
        )}
      </div>
    </>
  )
}
export default DocumentInputUpload
