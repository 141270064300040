import { FormikError } from '@nv/basic-components'
import { useTranslation } from 'react-i18next'
import ReactPhoneInput from 'react-phone-number-input'
import PhoneInputStyles from './PhoneInput.module.scss'
import 'react-phone-number-input/style.css'

export const PhoneInput = ({
  field,
  formik
}) => {
  const { t } = useTranslation()

  return (
    <div className={PhoneInputStyles['container']}>
      <label htmlFor={field} className={PhoneInputStyles['label']}>
        {t('auth.authMethod.enterPhone')}
      </label>

      <ReactPhoneInput
        id={field}
        name={field}
        defaultCountry='CA'
        className={PhoneInputStyles['input']}
        placeholder={t('auth.authMethod.enterPhone')}
        value={formik.values[field]}
        onChange={(value) => formik.setFieldValue(field, value)}
      />

      {formik && formik.touched[field] && formik.errors[field] && (
        <FormikError
          formik={formik}
          fieldName={field}
          errorLabel={formik.errors[field]}
        />
      )}
    </div>
  )
}
