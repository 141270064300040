import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getFileTypeFromName } from '@nv/utils'
import { Loader } from '@nv/basic-components';
import PDFViewer from '../../../pdf_viewer/PdfViewer'
import UnsupportedFile from '../../../unsuported_file/UnsupportedFile'
import { ModalWrapper } from '../../ModalWrapper'
import FileViewerModalStyles from './FileViewerModal.module.scss'

const FileViewerModal = ({
  file,
  modalIsOpen,
  closeModal
}) => {
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  const [fileUrl, setFileUrl] = useState(null)
  const [fileType, setFileType] = useState(null)
  const [loading, setLoading] = useState(false)

  const loadFile = () => {
    try {
      if (file && file.fileUrl) {
        const url = file.fileUrl[currentLanguage]
        const filename = file.filename[currentLanguage]
        setFileUrl(url)
        const fileType = getFileTypeFromName(filename)
        setFileType(fileType)
      }
    } catch (error) {
      // Handle any errors
    }
  }

  useEffect(() => {
    setLoading(true)
    loadFile()
    setLoading(false)
  }, [file, currentLanguage])

  const renderContent = () => {
    if (loading) {
      return <Loader />
    }

    switch (fileType) {
      case 'document':
        return <PDFViewer pdfViewURL={fileUrl} />
      case 'image':
        return <img className={FileViewerModalStyles['image']} src={fileUrl} alt='File Viewer' />
      default:
        return <UnsupportedFile />
    }
  }

  return (
    <ModalWrapper
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      headerLabel="File Viewer"
      btns={[{
        text: 'Close',
        onClick: closeModal,
        isCancel: true,
      }]}
    >
      <div className={FileViewerModalStyles['container']}>
        {renderContent()}
      </div>
    </ModalWrapper>
  )
}

export default FileViewerModal
