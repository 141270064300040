import PrinterFailIcon from '@nv/images/design/printer-fail-icon'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from '../../ModalWrapper'
import SendJobErrorModalStyles from './SendJobErrorModal.module.scss'

export const SendJobErrorModal = ({
  modalOpen,
  onRequestClose = undefined,
}) => {
  const { t } = useTranslation()

  return (
    <ModalWrapper
      isOpen={modalOpen}
      onRequestClose={onRequestClose}
      headerLabel={t('form.labels.sendTestJob')}
    >
      <div className={SendJobErrorModalStyles["container"]}>
        <PrinterFailIcon />
        Sending job to the printer failed. <br /> Please make sure the printer is connected and send again
      </div>
    </ModalWrapper>
  )
}
