import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getFormattedCurrentTime } from '@nv/utils'

export const useCurrentTime = () => {
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  const [currentTime, setCurrentTime] = useState(null)

  const getAndSetCurrentTime = () => {
    setCurrentTime(getFormattedCurrentTime(currentLanguage))
  }

  //   without this there is a 1 second delay before updating the language
  useEffect(() => {
    getAndSetCurrentTime()
  }, [currentLanguage])

  useEffect(() => {
    const interval = setInterval(getAndSetCurrentTime, 1000)
    return () => clearInterval(interval)
  }, [currentLanguage])

  return currentTime
}
