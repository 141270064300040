import CircleCheckIcon from '@nv/images/design/circle-check-icon'
import XCircleIcon from '@nv/images/design/x-circle-icon'
import clsx from 'clsx'
import PwdValidateTxtStyles from './PasswordValidationTxt.module.scss'

export const PasswordValidationTxt = ({ text, isValid }) => {
  // Mapping icons based on the validity status
  const Icon = () => {
    return isValid ? (
      <CircleCheckIcon className={PwdValidateTxtStyles['icon']} />
    ) : (
      <XCircleIcon className={PwdValidateTxtStyles['icon']} />
    )
  }

  return (
    <div className={PwdValidateTxtStyles['container']}>
      <Icon />

      <span
        className={clsx(
          PwdValidateTxtStyles['text'],
          PwdValidateTxtStyles[isValid ? 'text--valid' : 'text--invalid']
        )}
        role='alert'
      >
        {text}
      </span>
    </div>
  )
}
