import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import UploadIcon from '@nv/images/design/upload-icon'
import FileUploaderModalStyles from './FileUploaderModal.module.scss'

const FileUploaderModal = ({
  setUploadErr,
  customStyling = {},
  onCallback = (p0: { redirect: string; data: any }) => { },
}) => {
  const uploadRef = useRef(null)
  const { t } = useTranslation()
  const allowedFiles = ['png', 'jpg', 'jpeg', 'pdf', 'doc', 'docx', 'mp3', 'mp4', 'mov', 'wav']

  const getFileType = (filename) => {
    const extension = filename.split('.').pop()
    return extension
  }

  const onImageClick = useCallback(() => {
    uploadRef.current.click()
  }, [uploadRef])

  const onFileUpload = async (e) => {
    try {
      e.persist()

      const file = e.target.files[0]
      //setLocalFileData({ name: file.name, file: file })
      //console.log('file', file)

      const isLt2M = file.size / 1024 / 1024 < 25

      // If the file size (calculated in byte form) is greater than 25MB
      if (!isLt2M) {
        setUploadErr(t('uploads.errors.fileSize', { maxFileSize: '25' }))
        return //console.log('The maximum supported file size is 25 MB')
      }

      if (!allowedFiles.includes(getFileType(file.name))) {
        setUploadErr(t('uploads.errors.fileType'))
        return //console.log('wrongFileTypeErr')
      }

      try {
        // setCurrentView('Add Document')
        onCallback({ redirect: 'Add Document', data: { name: file.name, file: file } })
      } catch (error) {
        setUploadErr(t('errors.contactSupport'))
        return //console.log(error)
      }
    } catch (error) {
      //console.error(error)
    }
  }

  return (
    <>
      <div
        className={FileUploaderModalStyles['container']}
        onClick={onImageClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onImageClick()
        }}
        role="button"
        tabIndex={0}

      >
        <UploadIcon />
        <h4 className={FileUploaderModalStyles['text']}>
          {t('uploads.file.uploader')}
        </h4>
        <span className={FileUploaderModalStyles['subtext']}>
          {t(`uploads.file.addOne`, {
            maxFileSize: '25',
            fileTypes: allowedFiles.join(', '),
          })}
        </span>
      </div>

      <input type='file' onChange={onFileUpload} ref={uploadRef} style={{ display: 'none' }} />
    </>
  )
}

export default FileUploaderModal
