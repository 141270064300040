import { useEffect, useState } from 'react'
import CircleArrowLeft from '@nv/images/design/circle-arrow-left-icon'
import CircleArrowRight from '@nv/images/design/circle-arrow-right-icon'
import XIcon from '@nv/images/design/x-icon'
import { useAuthKc } from '@nv/zustand'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import SideBarItem from './components/sidebar_item/SideBarItem'
import SideBarStyles from './SideBar.module.scss'

type SideBarProps = {
  children?: any
  onClose?: any
  sayHello?: boolean
  isMobile?: boolean
  links?: any
  logo?: any
  mobileLogo?: any
  isCollapsed?: boolean
}

export const SideBar = ({
  children,
  onClose,
  sayHello = false,
  isMobile = false,
  links,
  logo = undefined,
  mobileLogo = undefined,
  isCollapsed: propIsCollapsed,
}: SideBarProps) => {
  const { t } = useTranslation()
  const VoterUser = useAuthKc().user

  // Use the passed prop if available
  const [isCollapsed, setIsCollapsed] = useState<boolean>(
    propIsCollapsed ?? JSON.parse(localStorage.getItem('isCollapsed') || 'false')
  )

  const toggleCollapse = () => {
    const newState = !isCollapsed
    localStorage.setItem('isCollapsed', JSON.stringify(newState))
    setIsCollapsed(newState)
  }

  useEffect(() => {
    if (propIsCollapsed !== undefined) {
      setIsCollapsed(propIsCollapsed)
    }
  }, [propIsCollapsed])

  return (
    <div
      className={clsx(
        SideBarStyles['sidebar'],
        isMobile ? SideBarStyles['sidebar__is-mobile'] : SideBarStyles['sidebar__is-not-mobile'],
        {
          [SideBarStyles['sidebar__collapsed']]: isCollapsed,
        }
      )}
    >
      <div className={SideBarStyles['sidebar__header']}>
        {!isCollapsed ? <span className={SideBarStyles['sidebar__logo']}>{logo}</span> : mobileLogo}
        <XIcon onClick={() => onClose()} className={SideBarStyles['sidebar__x-icon']} />
      </div>

      {sayHello && !isCollapsed && (
        <span>
          {t('main.greeting', {
            name: `${VoterUser?.user?.firstName} ${VoterUser?.user?.lastName}`,
          })}
        </span>
      )}
      <div className={SideBarStyles['sidebar__divider']}></div>
      {links && Object.entries(links).map(([tabkey, tabValue]) => (
        <SideBarItem key={tabkey} tabValue={tabValue} isCollapsed={isCollapsed} />
      ))}
      {children}
      <button className={SideBarStyles['sidebar__collapse-btn']} onClick={toggleCollapse}>
        {isCollapsed ? <CircleArrowRight /> : <CircleArrowLeft />}
      </button>
    </div>
  )
}
