import { SVG } from '../SVG'

export const ServerIcon = ({ style={}, ...props }) => (
  <SVG
    width="77"
    height="71"
    viewBox="0 0 77 71"
    fill="none"
    strokeWidth="4"
    strokeMiterlimit="10"
    xmlns="http://www.w3.org/2000/svg"
    style={{"stroke": "var(--success-color)", ...style}}
    {...props}
  >
    <g clipPath="url(#clip0_9005_3632)">
    <path d="M71.7316 1.60938H5.26862C3.24781 1.60938 1.60962 3.24604 1.60962 5.26497V15.2133C1.60962 17.2323 3.24781 18.8689 5.26862 18.8689H71.7316C73.7524 18.8689 75.3906 17.2323 75.3906 15.2133V5.26497C75.3906 3.24604 73.7524 1.60938 71.7316 1.60938Z" />
    <path d="M25.216 14.0119C27.302 14.0119 28.993 12.3224 28.993 10.2384C28.993 8.1543 27.302 6.46484 25.216 6.46484C23.13 6.46484 21.439 8.1543 21.439 10.2384C21.439 12.3224 23.13 14.0119 25.216 14.0119Z" />
    <path d="M12.8976 14.0119C14.9836 14.0119 16.6747 12.3224 16.6747 10.2384C16.6747 8.1543 14.9836 6.46484 12.8976 6.46484C10.8116 6.46484 9.12061 8.1543 9.12061 10.2384C9.12061 12.3224 10.8116 14.0119 12.8976 14.0119Z" />
    <path d="M71.7316 26.5117H5.26862C3.24781 26.5117 1.60962 28.1484 1.60962 30.1673V40.1157C1.60962 42.1346 3.24781 43.7713 5.26862 43.7713H71.7316C73.7524 43.7713 75.3906 42.1346 75.3906 40.1157V30.1673C75.3906 28.1484 73.7524 26.5117 71.7316 26.5117Z" />
    <path d="M25.216 38.9142C27.302 38.9142 28.993 37.2248 28.993 35.1407C28.993 33.0566 27.302 31.3672 25.216 31.3672C23.13 31.3672 21.439 33.0566 21.439 35.1407C21.439 37.2248 23.13 38.9142 25.216 38.9142Z" />
    <path d="M12.8976 38.9142C14.9836 38.9142 16.6747 37.2248 16.6747 35.1407C16.6747 33.0566 14.9836 31.3672 12.8976 31.3672C10.8116 31.3672 9.12061 33.0566 9.12061 35.1407C9.12061 37.2248 10.8116 38.9142 12.8976 38.9142Z" />
    <path d="M71.7316 52.1328H5.26862C3.24781 52.1328 1.60962 53.7695 1.60962 55.7884V65.7368C1.60962 67.7557 3.24781 69.3924 5.26862 69.3924H71.7316C73.7524 69.3924 75.3906 67.7557 75.3906 65.7368V55.7884C75.3906 53.7695 73.7524 52.1328 71.7316 52.1328Z" />
    <path d="M25.216 64.5353C27.302 64.5353 28.993 62.8459 28.993 60.7618C28.993 58.6777 27.302 56.9883 25.216 56.9883C23.13 56.9883 21.439 58.6777 21.439 60.7618C21.439 62.8459 23.13 64.5353 25.216 64.5353Z" />
    <path d="M12.8976 64.5353C14.9836 64.5353 16.6747 62.8459 16.6747 60.7618C16.6747 58.6777 14.9836 56.9883 12.8976 56.9883C10.8116 56.9883 9.12061 58.6777 9.12061 60.7618C9.12061 62.8459 10.8116 64.5353 12.8976 64.5353Z" />
    <path d="M11.7817 18.8672V26.5107" />
    <path d="M67.6863 18.8672V26.5107" />
    <path d="M11.7817 43.7695V51.413" />
    <path d="M67.6863 43.7695V51.413" />
    <path d="M65.8834 10.3873C66.0671 10.3873 66.2161 10.2385 66.2161 10.055C66.2161 9.87144 66.0671 9.72266 65.8834 9.72266C65.6997 9.72266 65.5508 9.87144 65.5508 10.055C65.5508 10.2385 65.6997 10.3873 65.8834 10.3873Z" />
    <path d="M65.8834 35.4732C66.0671 35.4732 66.2161 35.3245 66.2161 35.1409C66.2161 34.9574 66.0671 34.8086 65.8834 34.8086C65.6997 34.8086 65.5508 34.9574 65.5508 35.1409C65.5508 35.3245 65.6997 35.4732 65.8834 35.4732Z" />
    <path d="M65.8834 61.0943C66.0671 61.0943 66.2161 60.9456 66.2161 60.762C66.2161 60.5785 66.0671 60.4297 65.8834 60.4297C65.6997 60.4297 65.5508 60.5785 65.5508 60.762C65.5508 60.9456 65.6997 61.0943 65.8834 61.0943Z" />
    </g>
    <defs>
    <clipPath id="clip0_9005_3632">
    <rect width="77" height="71" fill="white"/>
    </clipPath>
    </defs>
  </SVG>
)
