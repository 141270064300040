import { useEffect, useRef, useState } from 'react'
import ChevronIcon from '@nv/images/design/chevron-icon'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import AdminRoleDropDownStyles from './AdminRoleDropDown.module.scss'

export function AdminRoleDropdown({
  options,
  title,
  value,
  setValue,
  customClass = null,
  isRequired = false,
}) {
  const { t } = useTranslation()
  const [displayValue, setDisplayValue] = useState(t('form.select.select'))
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const topicOpRef = useRef(null)

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isDropdownOpen && topicOpRef.current && !topicOpRef.current.contains(e.target)) {
        setIsDropdownOpen(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isDropdownOpen])

  return (
    <>
      <label className={AdminRoleDropDownStyles['label']}>{title}</label>
      <div
        className={AdminRoleDropDownStyles['select']}
        onClick={() => {
          setIsDropdownOpen(true)
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') setIsDropdownOpen(true)
        }}
        role="button"
        tabIndex={0}
      >
        {displayValue}
        <ChevronIcon
          className={clsx({
            [AdminRoleDropDownStyles['chevron-flip']]: isDropdownOpen,
            [AdminRoleDropDownStyles['chevron']]: !isDropdownOpen
          })}
        />
      </div>

      {isDropdownOpen && (
        <div className={AdminRoleDropDownStyles['options']} ref={topicOpRef}>
          {options.map((topic, index) => {
            return (
              <div
                key={index}
                className={AdminRoleDropDownStyles['option']}
                onClick={() => {
                  setDisplayValue(topic.label)
                  setValue(topic.value)
                  setIsDropdownOpen(false)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setDisplayValue(topic.label)
                    setValue(topic.value)
                    setIsDropdownOpen(false)
                  }
                }}
                role="button"
                tabIndex={0}
              >
                <span className={AdminRoleDropDownStyles['options__text']}>{topic.label}</span>
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}
