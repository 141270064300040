import { useAuthKc } from '@nv/zustand'
import { useAuthAdminKc } from '@nv/zustand'
import { useTranslation } from 'react-i18next'
import WelcomeMessageStyles from './WelcomeMessage.module.scss'

const WelcomeMessage = () => {
  const { t } = useTranslation()
  const VoterUser = useAuthKc().user
  const adminUser = useAuthAdminKc().user
  const username = adminUser?.username || VoterUser?.name || `${VoterUser?.user?.firstName} ${VoterUser?.user?.lastName}`

  return <span className={WelcomeMessageStyles['welcome-message']}>{t('main.greeting', { name: username })}</span>
}

export default WelcomeMessage
