import FormikErrorStyles from './FormikErrors.module.scss'

export const FormikError = ({ formik, fieldName, errorLabel, customStyling = {} }) => {
  return (
    <>
      {formik.errors[fieldName] && (
        <div style={customStyling}>
          <span className={FormikErrorStyles['error']} role='alert'>
            {errorLabel}
          </span>
        </div>
      )}
    </>
  )
}
