import { useTranslation } from 'react-i18next'
import ThumbsUpIcon from '@nv/images/design/thumbs-up-icon'
import { ModalWrapper } from '../../ModalWrapper'
import ReportsDownloadedModalStyles from './ReportsDownloadedModal.module.scss'

export const ReportsDownloadedModal = ({
  isOpen = false,
  onRequestClose = undefined,
}) => {
  const { t } = useTranslation()

  const btns = [
    {
      text: t('buttons.ok'),
      onClick: onRequestClose,
    },
  ]

  return (
    <ModalWrapper
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      headerLabel={t('admin.reports.generateReport')}
      btns={btns}
    >
      <div className={ReportsDownloadedModalStyles["container"]}>
        <ThumbsUpIcon className={ReportsDownloadedModalStyles["icon"]} />
        <span className={ReportsDownloadedModalStyles["title"]}>{t('main.great')}</span>
        <span className={ReportsDownloadedModalStyles["subtitle"]}>{t('admin.reports.downloadSuccess')}</span>
      </div>
    </ModalWrapper>
  )
}
