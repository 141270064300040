import fr from 'date-fns/locale/fr'
import { useField } from 'formik'
import { registerLocale } from 'react-datepicker'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

registerLocale('fr', fr)

const DatePicker = ({ label, isRequired, formik, field, ...props }) => {
  const [fieldProps, meta] = useField(field)

  const isError = meta.touched && meta.error

  return (
    <div className="date-picker-container">
      {label && (
        <label htmlFor={field} className="date-picker-label">
          {label} {isRequired && <span className="required">*</span>}
        </label>
      )}
      <ReactDatePicker
        {...fieldProps}
        {...props}
        selected={fieldProps.value ? new Date(fieldProps.value) : null}
        onChange={(date) => formik.setFieldValue(field, date)}
        className={`date-picker-input ${isError ? 'error' : ''}`}
        locale={formik.language}
      />
      {isError && <div className="error-message">{meta.error}</div>}
    </div>
  )
}

export default DatePicker
