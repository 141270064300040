import XCalanderIcon from '@nv/images/design/x-calandar-icon'
import { useTranslation } from 'react-i18next'
import NoEventsStyles from './NoEvents.module.scss'

export const NoEvents = () => {
  const { t } = useTranslation()

  return (
    <div className={NoEventsStyles['container']}>
      <div className={NoEventsStyles['content']}>
        <XCalanderIcon className={NoEventsStyles['content__icon']} />
        <span className={NoEventsStyles['content__text']}> {t('events.noEvents.any')}</span>
      </div>
    </div>
  )
}
