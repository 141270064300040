import { useTranslation } from 'react-i18next'
import ThumbsUpIcon from '@nv/images/design/thumbs-up-icon'
import { ModalWrapper } from '../../ModalWrapper'
import DownloadedModalStyles from './DownloadedModal.module.scss'

export const DownloadedModal = (props) => {
  const { t } = useTranslation()
  const { isOpen } = props

  const btns = [
    {
      text: t('buttons.ok'),
      onClick: props.onRequestClose,
    },
  ]

  return (
    <ModalWrapper
      isOpen={isOpen}
      onRequestClose={props.onRequestClose}
      headerLabel={t('main.downloaded')}
      btns={btns}
    >
      <div className={DownloadedModalStyles["container"]}>
        <ThumbsUpIcon className={DownloadedModalStyles["img"]} />
        <span className={DownloadedModalStyles["label"]}> {t('main.thankyou')}</span>
        <span className={DownloadedModalStyles["subtitle"]}>{t('admin.electorsList.downloadSuccess')}</span>
      </div>
    </ModalWrapper>
  )
}
