import { DateTime } from 'luxon'

const timeZone = process.env.NX_PUBLIC_TIME_ZONE_DATE_STRING

export const formatDateLocale = (datetime, currentLanguage) => {
  let dateTime = DateTime.fromISO(datetime)
  if (timeZone) dateTime = dateTime.setZone(timeZone)
  if (currentLanguage === 'fr') return dateTime.setLocale('fr').toFormat("d MMM yyyy 'à' HH'h'mm") // e.g., 16 août 2018 à 20h02
  if (currentLanguage === 'en') return dateTime.setLocale('en').toFormat('MMM d, yyyy h:mm a') // e.g., Aug 16, 2018 8:02 PM
}

export const getFormattedCurrentTime = (currentLanguage) => {
  let currentTime = DateTime.now()
  if (timeZone) currentTime = currentTime.setZone(timeZone)

  let fullTime = currentTime.toFormat('HH:mm')
  if (currentLanguage === 'fr') fullTime = fullTime.replace(':', 'h')

  return fullTime
}

export const formatDateLocaleShort = (datetime, currentLanguage) => {
  let dateTime = DateTime.fromISO(datetime)
  if (timeZone) dateTime = dateTime.setZone(timeZone)

  let formattedDate = dateTime.toFormat('dd-MM-yy HH:mm') // e.g., 24-05-24 22:33
  if (currentLanguage === 'fr') formattedDate = formattedDate.replace(':', 'h')

  return formattedDate
}
