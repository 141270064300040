import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import PlacesAutocomplete from 'react-places-autocomplete'
import SearchAddressStyles from './SearchAddress.module.scss'

export const SearchAddress = ({ value, onChange, formik }) => {
  const { t } = useTranslation()
  const [address, setAddress] = useState(value || '')
  const [apiLoaded, setApiLoaded] = useState(false)

  useEffect(() => {
    const apiKey = process.env.NX_PUBLIC_REACT_APP_GOOGLE_API_KEY

    if (!apiKey) {
      console.error('Google API key is missing!')
      return
    }

    const loadGoogleMapsApi = () => {
      return new Promise<void>((resolve, reject) => {
        const script = document.createElement('script')
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`
        script.async = true
        script.defer = true
        script.onload = () => resolve()
        script.onerror = (err) => reject(err)
        document.head.appendChild(script)
      })
    }

    loadGoogleMapsApi()
      .then(() => setApiLoaded(true))
      .catch((err) => console.error('Error loading Google Maps API:', err))
  }, [])

  const handleSelect = async (selectedAddress: string) => {
    setAddress(selectedAddress)
    onChange(selectedAddress)
  }

  if (!apiLoaded) {
    return <div>Loading address list ...</div>
  }

  return (
    <div className={clsx(SearchAddressStyles['container'])}>
      <div className={clsx(SearchAddressStyles['label'], SearchAddressStyles['required'])}>
        {t('registration.searchAddress')}
      </div>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <input
              {...getInputProps({
                placeholder: t('registration.typeAnAddress'),
              })}
              className={SearchAddressStyles['input']}
            />
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const { key, ...restProps } = getSuggestionItemProps(suggestion)
              const style = {
                backgroundColor: suggestion.active ? '#fafafa' : '#ffffff',
                cursor: 'pointer',
                padding: '10px',
              }
              return (
                <div key={suggestion.placeId} {...restProps} style={style}>
                  {suggestion.description}
                </div>
              )
            })}
          </>
        )}
      </PlacesAutocomplete>
      {address && (
        <div style={{ marginTop: '20px', wordWrap: 'break-word' }}>
          <strong>Selected Address:</strong> {address}
        </div>
      )}
      {formik.errors.searchAddress && formik.touched.searchAddress && (
        <div className={SearchAddressStyles['error']}>{formik.errors.searchAddress}</div>
      )}
    </div>
  )
}
