import SearchIcon from '@nv/images/design/search-icon'
import clsx from 'clsx'
import SearchBarStyles from './SearchBar.module.scss'

export const SearchBar = ({
  customStyling = undefined,
  placeholder = undefined,
  name = undefined,
  disabled = undefined,
  onChange = undefined,
  value = undefined
}) => {

  return (
    <div className={clsx(SearchBarStyles['container'])} style={customStyling}>
      <SearchIcon className={SearchBarStyles['img']} />
      <input
        className={SearchBarStyles['search']}
        autoComplete='off'
        placeholder={placeholder}
        name={name}
        disabled={disabled}
        onChange={onChange}
        value={value}
      />
    </div>
  )
}
