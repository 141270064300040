import { SVG } from '../SVG'

export const PrinterLgIcon = ({ style={}, ...props }) => (
  <SVG
    width="72"
    height="75"
    viewBox="0 0 72 75"
    fill="none"
    strokeWidth="4"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
    style={{"stroke": "var(--tertiary-color)", ...style}}
    {...props}
  >
    <path d="M13.7143 56.7686H2.12305V24.5391H65.5713V56.7686H55.5" />
    <path d="M55.2841 46.6719H14.3608V71.1196H55.2841V46.6719Z" />
    <path d="M54.7351 23.3495C54.8693 22.6558 54.7351 2.15625 54.7351 2.15625H13.5435V23.3495" />
    <path d="M10.6763 33.5938H28.4048" />
    <path d="M56.5653 33.6064C57.2796 33.6064 57.8587 33.0186 57.8587 32.2934C57.8587 31.5683 57.2796 30.9805 56.5653 30.9805C55.851 30.9805 55.272 31.5683 55.272 32.2934C55.272 33.0186 55.851 33.6064 56.5653 33.6064Z" />
  </SVG>
)
