import { ModalBtn } from '../modal_btn/ModalBtn'
import ModalFooterStyles from './ModalFooter.module.scss'

export const ModalFooter = ({ btns }) => {
  return (
    <div className={ModalFooterStyles['container']}>
      {btns.map((btn, index) => (
        <ModalBtn
          key={index}
          text={btn.text}
          isCancel={btn.isCancel}
          onClick={btn.onClick}
        />
      ))}
    </div>
  )
}
