import { useEffect } from 'react'
import Modal from 'react-modal'
import { ModalFooter } from './components/modal_footer/ModalFooter'
import { ModalHeader } from './components/modal_header/ModalHeader'
import ModalWrapperStyles from './ModalWrapper.module.scss'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0.75rem',
    padding: 0,
    overflowY: 'scroll',
    maxHeight: 'calc(100% - 1.25rem)',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 3,
    overflowY: 'scroll',
  },
}

export const ModalWrapper = ({
  isOpen = false,
  onRequestClose = false,
  headerLabel = null,
  btns = [],
  children
}) => {

  useEffect(() => {
    Modal.setAppElement('#root')
  }, [])

  return (
    <Modal ariaHideApp={false} isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
      <div className={ModalWrapperStyles['container']}>
        <ModalHeader onClose={onRequestClose} label={headerLabel} />
        {children}
        <ModalFooter btns={btns} />
      </div>
    </Modal>
  )
}
