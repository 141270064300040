import { useTranslation } from 'react-i18next'
import CircleRightIcon from '@nv/images/design/circle-right-icon'
import ClockProgressIcon from '@nv/images/design/clock-progress-icon'
import RightArrowIcon from '@nv/images/design/right-arrow-icon'
import { ModalWrapper } from '../../ModalWrapper'
import ExtendSessionModalStyles from './ExtendSessionModal.module.scss'

export const ExtendSessionModal = ({
  isOpen = false,
  onRequestClose = undefined,
  endSession = undefined,
  doExtendSession = undefined,
  sessionTimeRemaining = '',
  headerLabel = ''
}) => {
  const { t } = useTranslation()

  const btns = [
    {
      text: t('main.logout'),
      onClick: () => {
        endSession()
        onRequestClose()
      },
      isCancel: true,
    },
    {
      text: t('auth.admin.session.extend'),
      onClick: () => {
        doExtendSession()
        onRequestClose()
      },
    },
  ]

  return (
    <ModalWrapper
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      headerLabel={headerLabel}
      btns={btns}
    >
      <div className={ExtendSessionModalStyles['container']}>
        <div className={ExtendSessionModalStyles['icons']}>
          <ClockProgressIcon />
          <RightArrowIcon />
          <CircleRightIcon />
        </div>

        <div className={ExtendSessionModalStyles['session']}>
          {sessionTimeRemaining && !sessionTimeRemaining.includes('NaN') ? (
            <span className={ExtendSessionModalStyles['session__time']}>
              <span className={ExtendSessionModalStyles['session__label']}>{t('main.sessionTime')}</span>

              <div className={ExtendSessionModalStyles['session__time']}>
                <span className={ExtendSessionModalStyles['session__value']}>{sessionTimeRemaining}</span>
              </div>
            </span>
          ) : null}
        </div>

        <span className={ExtendSessionModalStyles['text']}>{t('auth.admin.session.askExtend')}</span>
      </div>
    </ModalWrapper>
  )
}
