import { voterVerifyInstance as instance } from '../VoterAuthService'

export const JumioLogin = {
  startFacedIdLogin: async () => {
    try {
      const res = await instance.post('/auth/jumio/mfa/initiate')
      return { success: true, iframeUrl: res.iframeUrl }
    } catch (error) {
      //console.log(error)
      return { error }
    }
  },
  onFinishFacedIdLogin: async ({ accountId, workflowId }) => {
    try {
      await instance.post(`/auth/jumio/mfa/verify/accounts/${accountId}/flows/${workflowId}`)
      return { success: true }
    } catch (error) {
      //console.log(error)
      return { error }
    }
  },
}
