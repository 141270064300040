import VoterAxiosInstance from './VoterAxiosInstance'

export const MyCandidateService = {
  getMyCandidacy: async () => {
    try {
      const myCandidacy = await VoterAxiosInstance.get(`/nominations/mycandidacy`)
      return { myCandidacy }
    } catch (error) {
      /* empty */
    }
  },
  getMyCandidacyDetails: async (candidacyId) => {
    try {
      const myCandidacy = await VoterAxiosInstance.get(`/nominations/mycandidacy/${candidacyId}`)
      return { myCandidacy }
    } catch (error) {
      /* empty */
    }
  },
  acceptNomination: async (nominationId, data) => {
    try {
      // data includes "bio": string and "acceptTerms":boolean must be true
      await VoterAxiosInstance.put(`/nominations/${nominationId}/accept`, data)
    } catch (error) {
      /* empty */
    }
  },
  rejectNomination: async (nominationId) => {
    try {
      await VoterAxiosInstance.put(`/nominations/${nominationId}/reject`)
    } catch (error) {
      /* empty */
    }
  },
  withdrawNomination: async (nominationId) => {
    try {
      await VoterAxiosInstance.put(`/nominations/${nominationId}/withdraw`)
    } catch (error) {
      /* empty */
    }
  },
  updateBioNomination: async (nominationId, data) => {
    try {
      // data includes "bio": string
      await VoterAxiosInstance.put(`/nominations/${nominationId}/bio`, data)
    } catch (error) {
      /* empty */
    }
  },
}
export default MyCandidateService
