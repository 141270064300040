import ModelBtnStyles from './ModalBtn.module.scss'

export const ModalBtn = ({
  text,
  onClick,
  isCancel = false
}) => {

  return (
    <button
      type='button'
      className={`${isCancel ? ModelBtnStyles['cancel-btn'] : ModelBtnStyles['primary-btn']}`}
      onClick={onClick}
    >
      {text}
    </button>
  )
}
