import { useEffect, useRef, useState } from 'react'
import ElipsisIcon from '@nv/images/design/elipsis-icon'
import clsx from 'clsx'
import ActionDropdownStyles from './ActionDropdown.module.scss'

export const ActionDropdown = ({ values = [], onCallback = null, ...props }) => {
  const [openActionDropdown, setOpenActionDropdown] = useState(false)
  const topicOpRef = useRef(null)

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (openActionDropdown && topicOpRef.current && !topicOpRef.current.contains(e.target)) {
        setOpenActionDropdown(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => document.removeEventListener('mousedown', checkIfClickedOutside)
  }, [openActionDropdown])

  return (
    <div className={ActionDropdownStyles['dropdown']}>
      <ElipsisIcon
        className={ActionDropdownStyles['select']}
        onClick={() => setOpenActionDropdown((prev) => !prev)}
        role="button"
        tabIndex={0}
      />

      {openActionDropdown && (
        <div className={clsx([ActionDropdownStyles['options'], ActionDropdownStyles['options--table-inline']])} ref={topicOpRef}>
          {values.map((value, index) => (
            <div
              key={index}
              className={ActionDropdownStyles['option']}
              onClick={() => {
                value.callback ? value.callback() : onCallback(value.value)

                setOpenActionDropdown(false)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  value.callback ? value.callback() : onCallback(value.value)
                  setOpenActionDropdown(false)
                }
              }}
              role="button"
              tabIndex={0}
            >
              {value.icon && <span className={ActionDropdownStyles['option__icon']}>{value.icon}</span>}
              <span className={ActionDropdownStyles['option__text']}>{value.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
