import { Suspense, lazy, useState } from 'react'
import { KeycloakAdminService } from '@nv/auth'
import { BackgroundBanner, BottomBar, FooterBanner, LoginTopBar, SideBar, TopBar } from '@nv/components'
import { AdminUserProvider, LoginFlowContext, SessionTimerProvider, VoterOnboardingContext } from '@nv/contexts'
import { useAdminKeycloakAuth, useVoterKeyCloakAuth } from '@nv/hooks'
import MobileQueenslandLogo from '@nv/images/logos/queensland/mobile-queensland-logo'
import QueenslandLogo from '@nv/images/logos/queensland/queensland-logo'
import { Elections } from '@nv/images/nav-icons/elections'
import { Users } from '@nv/images/nav-icons/users'
import { useTranslation } from 'react-i18next'
import { Redirect, Switch } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { ContestDetails } from '../pages/admin/admin_event_details/contests/contest_details/ContestDetails'
import { VerifyUserGuard } from './VerifyUserGuard'

// PUBLIC ROUTES:
const AuthMethodLogin = lazy(() => import('../pages/public/auth_method_login/AuthMethodLogin').then((mod: any) => ({ default: mod.AuthMethodLogin })))
const AuthVerify = lazy(() => import('../pages/public/auth_verify/AuthVerify').then((mod: any) => ({ default: mod.AuthVerify })))
const AuthAppSetup = lazy(() => import('../pages/public/auth_app_setup/AuthAppSetup').then((mod: any) => ({ default: mod.AuthAppSetup })))
const VoterLogin = lazy(() => import('../pages/public/voter_login/VoterLogin').then((mod: any) => ({ default: mod.VoterLogin })))
const AdminLogin = lazy(() => import('../pages/public/admin_login/AdminLogin').then((mod: any) => ({ default: mod.AdminLogin })))

// VOTER ROUTES:
const VoterDashboard = lazy(() => import('../pages/voter/voter_dashboard/VoterDashboard').then((mod: any) => ({ default: mod.VoterDashboard })))
const CandidateProfile = lazy(() => import('../pages/voter/candidate_profile/CandidateProfile').then((mod: any) => ({ default: mod.CandidateProfile })))
const VoterMyCandidacy = lazy(() => import('../pages/voter/voter_my_candidacy/VoterMyCandidacy').then((mod: any) => ({ default: mod.VoterMyCandidacy })))
const CandidacyDetails = lazy(() => import('../pages/voter/candidacy_details/CandidacyDetails').then((mod: any) => ({ default: mod.CandidacyDetails })))
const ElectionDetails = lazy(() => import('../pages/voter/election_details/ElectionDetails').then((mod: any) => ({ default: mod.ElectionDetails })))

// ADMINROUTES:
const AdminEvents = lazy(() => import('../pages/admin/admin_events/AdminEvents').then((mod: any) => ({ default: mod.AdminEvents })))
const AdminEventDetails = lazy(() => import('../pages/admin/admin_event_details/AdminEventDetails').then((mod: any) => ({ default: mod.AdminEventDetails })))
const AdminNomineeDetails = lazy(() => import('../pages/admin/admin_event_details/contests/contest_details/nominee_details/AdminNomineeDetails').then((mod: any) => ({ default: mod.AdminNomineeDetails })))
const AdminCreateEvent = lazy(() => import('../pages/admin/admin_create_event/AdminCreateEvent').then((mod: any) => ({ default: mod.AdminCreateEvent })))

export const VoterKeyCloakAuth = () => {
  useVoterKeyCloakAuth()
  return null
}
export const AdminKeycloakAuth = () => {
  useAdminKeycloakAuth()
  return null
}

const NvRoute = ({ layout: Layout = null, component: Component = null, render = null, ...props }) => {
  return Layout ? (
    <Route render={() => <Layout>{render ? render() : <Component />}</Layout>} {...props} />
  ) : (
    <Route render={render} component={Component} {...props} />
  )
}

const VoterLayout = ({ children }) => {
  const { t } = useTranslation()
  const voterLinks = {
    elections: {
      path: '/voting/voter/dashboard',
      tabLabel: t('pages.events'),
      icon: Elections,
      hasPermissionToView: true,
    },
    candidateProfile: {
      path: '/voting/voter/my-candidacy',
      tabLabel: t('pages.myCandidacy'),
      icon: Users,
      hasPermissionToView: true,
    },
  }

  return (
    <>
      <SideBar links={voterLinks} logo={<QueenslandLogo />} mobileLogo={<MobileQueenslandLogo />} />
      <div className="main-content-container">
        <TopBar
          links={voterLinks}
          logo={<QueenslandLogo />}
          mobileLogo={<MobileQueenslandLogo />}
          className={'queensland'}
          disableSessionExtension
        />
        {children}
        <BottomBar links={voterLinks} />
        <FooterBanner />
      </div>
    </>
  )
}

const AdminLayout = ({ children }) => {
  const { t } = useTranslation()

  const adminLinks = {
    events: {
      path: '/admin/dashboard',
      tabLabel: t('pages.events'),
      icon: Elections,
      hasPermissionToView:
        KeycloakAdminService.isSuperAdmin() || KeycloakAdminService.isEventModuleAdmin() || KeycloakAdminService.isMonitoringAdmin(),
    },
  }

  return (
    <>
      <SideBar links={adminLinks} logo={<QueenslandLogo />} mobileLogo={<MobileQueenslandLogo />} />
      <div className="main-content-container">
        <TopBar
          links={adminLinks}
          logo={<QueenslandLogo />}
          mobileLogo={<MobileQueenslandLogo />}
          className={'queensland'}
          disableSessionExtension
        />
        {children}
        <FooterBanner />
      </div>
    </>
  )
}

const PublicLayout = ({ children }) => {
  const [authMethod, setAuthMethod] = useState(null)

  return (
    <div style={{ flexDirection: 'column', width: '100%' }}>
      <VoterOnboardingContext.Provider value={{ authMethod, setAuthMethod }}>
        <LoginTopBar logo={<QueenslandLogo />} />
        <BackgroundBanner banner={'queensland'} />
        {children}
        <FooterBanner />
      </VoterOnboardingContext.Provider>
    </div>
  )
}

const Routes = () => {
  const [loginFlow, setLoginFlow] = useState('Login')

  return (
    <SessionTimerProvider>
      <AdminUserProvider>
        <LoginFlowContext.Provider value={{ loginFlow, setLoginFlow }}>
          <Switch>
            <Route path={['/admin/', '/admin/auth/']} component={AdminKeycloakAuth} />
            <Route component={VoterKeyCloakAuth} />
          </Switch>

          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              {/* ADMIN ROUTES */}
              <NvRoute exact path="/admin/dashboard" render={() => <VerifyUserGuard component={AdminEvents} redirectURL={'/admin/login'} />} layout={AdminLayout} />
              <NvRoute exact path="/admin/events" render={() => <VerifyUserGuard component={AdminEvents} redirectURL={'/admin/login'} />} layout={AdminLayout} />
              <NvRoute exact path="/admin/create-event" render={() => <VerifyUserGuard component={AdminCreateEvent} redirectURL={'/admin/login'} />} layout={AdminLayout} />
              <NvRoute exact path="/admin/event-details/:eventId" render={() => <VerifyUserGuard component={AdminEventDetails} redirectURL={'/admin/login'} />} layout={AdminLayout} />
              <NvRoute exact path="/admin/candidates/:candidateId" render={() => <VerifyUserGuard component={AdminNomineeDetails} redirectURL={'/admin/login'} />} layout={AdminLayout} />
              <NvRoute exact path="/admin/event-details/:eventId/contests/:qid" render={() => <VerifyUserGuard component={ContestDetails} redirectURL={'/admin/login'} />} layout={AdminLayout} />

              {/* VOTER ROUTES */}
              <NvRoute exact path="/voting/voter/dashboard" render={() => <VerifyUserGuard component={VoterDashboard} redirectURL={'/'} />} layout={VoterLayout} />
              <NvRoute exact path="/voting/voter/my-candidacy" render={() => <VerifyUserGuard component={VoterMyCandidacy} redirectURL={'/'} />} layout={VoterLayout} />
              <NvRoute exact path="/voting/voter/candidacy-details/:candidacyId" render={() => <VerifyUserGuard component={CandidacyDetails} redirectURL={'/'} />} layout={VoterLayout} />
              <NvRoute exact path="/voting/voter/election-details/:eventId" render={() => <VerifyUserGuard component={ElectionDetails} redirectURL={'/'} />} layout={VoterLayout} />
              <NvRoute exact path="/voting/voter/candidate-profile" render={() => <VerifyUserGuard component={CandidateProfile} redirectURL={'/'} />} layout={VoterLayout} />

              {/* PUBLIC ROUTES */}
              <NvRoute exact path='/' component={VoterLogin} layout={PublicLayout} />
              <NvRoute exact path='/admin/login' component={AdminLogin} layout={PublicLayout} />

              <NvRoute exact path="/voting/voter-verify/select-verifier" component={AuthMethodLogin} layout={PublicLayout} />
              <NvRoute exact path="/voting/voter-verify/verify" component={AuthVerify} layout={PublicLayout} />

              <NvRoute exact path="/admin/auth/verify" component={AuthVerify} layout={PublicLayout} />
              <NvRoute exact path="/admin/auth/auth-app-setup" component={AuthAppSetup} layout={PublicLayout} />

              <NvRoute path="/voting/voter/" render={() => <Redirect to="/voting/voter/dashboard" />} />
              <NvRoute path="/admin/" render={() => <Redirect to="/admin/dashboard" />} />
              <Redirect to="/" />
            </Switch>
          </Suspense>
        </LoginFlowContext.Provider>
      </AdminUserProvider>
    </SessionTimerProvider>
  )
}
export { Routes }
