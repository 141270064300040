import { useCountdown } from '@nv/hooks'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import CountdownTimerBigStyles from './CountdownTimerBig.module.scss'

const CountdownTimerBig = ({ status, countDownDate, layout }) => {
  const { days, hours, minutes, seconds } = useCountdown(countDownDate)
  const layoutIsColumn = layout !== 'row'

  const { t } = useTranslation()
  return (
    <div
      className={clsx(CountdownTimerBigStyles['container'], {
        [CountdownTimerBigStyles['container--column']]: layoutIsColumn,
      })}
    >
      <span
        className={clsx(CountdownTimerBigStyles['title'], {
          [CountdownTimerBigStyles['title--column']]: layoutIsColumn,
        })}
      >
        {t(`countdown.label.${status}`)}
      </span>

      <div className={CountdownTimerBigStyles['content']}>
        <TimeCard label={t('countdown.time.long.days')} value={days} />
        <DividerColon />
        <TimeCard label={t('countdown.time.long.hours')} value={hours} />
        <DividerColon />
        <TimeCard label={t('countdown.time.long.mins')} value={minutes} />
        <DividerColon />
        <TimeCard label={t('countdown.time.long.sec')} value={seconds} />
      </div>
    </div>
  )
}

const DividerColon = () => {
  return <span className={CountdownTimerBigStyles['divider']}>:</span>
}

const TimeCard = ({ label, value }) => {
  return (
    <div className={CountdownTimerBigStyles['time-card']}>
      <span className={CountdownTimerBigStyles['time-card__label']}>{label}</span>

      <div className={CountdownTimerBigStyles['time-card__value']}>
        <span className={CountdownTimerBigStyles['time-card__value__txt']}>{value}</span>
      </div>
    </div>
  )
}

export default CountdownTimerBig
