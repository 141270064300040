import { useEffect } from 'react'
import { KeycloakAdminService } from '@nv/auth'
import { useAdminUserContext } from '@nv/contexts'
import { useQuery } from '@nv/hooks'
import { AdminVoterAuthService } from '@nv/services'
import { AdminVoterService } from '@nv/services'
import { beforeUnload } from '@nv/utils'
import { languageKeyToValue } from '@nv/utils'
import { setAuthAdminKc, setAuthAdminKcUser, useAuthAdminKc } from '@nv/zustand'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

export const useAdminKeycloakAuth = () => {
  const { setTiviLink } = useAdminUserContext()
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  const { initialized } = useAuthAdminKc()

  useEffect(() => {
    const { NX_PUBLIC_KEYCLOAK_URL_ADMIN } = process.env
    const keycloakAdminConfig = {
      url: NX_PUBLIC_KEYCLOAK_URL_ADMIN,
      clientId: 'voter-portal',
      realm: 'tivi'
    }
    const l = localStorage.getItem('language')
    if (l) localStorage.setItem('language', currentLanguage)
    KeycloakAdminService.initKeycloak(keycloakAdminConfig)
  }, [])

  const history = useHistory()
  const query = useQuery()

  const setLoggedInLocal = async () => {
    if (
      window.location.pathname.startsWith('/admin') &&
      (
        window.location.pathname.includes('login') ||
        window.location.pathname.includes('auth') ||
        query?.get('session_state') !== ''
      )
    ) {
      history.push('/admin/dashboard')
    }


    const tokenParsed = KeycloakAdminService.getTokenParsed()

    if (tokenParsed) {
      setAuthAdminKcUser({
        ...tokenParsed,
        username: tokenParsed.preferred_username,
        phone: tokenParsed.phone_number,
        phoneNumber: tokenParsed.phone_number,
        email: tokenParsed.email,
        adminView: true
      })
    }
    const tiviLink = await AdminVoterAuthService.getTiviElectionComponentLink()
    if (tiviLink && KeycloakAdminService?.isLoggedIn() && !KeycloakAdminService?.hasRole('tivi-contactinfo')) {
      setTiviLink(tiviLink)
    }
    window.addEventListener('beforeunload', beforeUnload)
  }

  const clearLocalSession = () => {
    window.removeEventListener('beforeunload', beforeUnload)

    if (
      window.location.pathname.startsWith('/admin') &&
      window.location.pathname !== '/admin' &&
      !window.location.pathname.includes('reset-password')
    ) {
      history.push('/admin')
      setAuthAdminKc({ user: {} })
    }
  }

  const checkAuth = async () => {
    // is logged in
    if (initialized && KeycloakAdminService?.isLoggedIn()) {
      setLoggedInLocal()
    }
    if (initialized && !KeycloakAdminService?.isLoggedIn()) {
      clearLocalSession()
    }
  }

  useEffect(() => {
    checkAuth()
  }, [initialized, KeycloakAdminService?.isLoggedIn()])

  useEffect(() => {
    // checkVoteReceiptPath()
    checkTiviLanguage()
    checkLanguage()
  }, [])

  // set language saved in app
  const setSavedLanguage = (language) => {
    localStorage.setItem('language', language)
    if (KeycloakAdminService.isLoggedIn()) {
      AdminVoterService.updateUserLanguage(language)
    }
  }

  const seti18nLocale = (language) => {
    i18n.changeLanguage(language)
  }

  const checkTiviLanguage = () => {
    // save local url param in session storage so it will persist redirects
    // remove from session storage once updated in app
    var url = new URL(window.location.href)
    const tiviLocale = url.searchParams?.get('locale')
    if (tiviLocale) {
      const language = languageKeyToValue[tiviLocale]
      sessionStorage.setItem('language', language)
    }
  }

  const checkLanguage = () => {
    // get language value from browser, Tivi, localStorage, or keycloak token and set in app
    const tiviLanguage = sessionStorage.getItem('language')
    const savedLanguage = localStorage.getItem('language')
    const tokenParsed = KeycloakAdminService?.getTokenParsed()
    if (tiviLanguage) {
      setSavedLanguage(tiviLanguage)
      sessionStorage.removeItem('language')
      seti18nLocale(tiviLanguage)
    } else if (savedLanguage) {
      setSavedLanguage(savedLanguage)
      seti18nLocale(savedLanguage)
    } else if (tokenParsed?.locale) {
      const language = languageKeyToValue[tokenParsed.locale]
      setSavedLanguage(language)
      i18n.changeLanguage(tokenParsed.locale)
    } else if (currentLanguage) {
      setSavedLanguage(currentLanguage)
      seti18nLocale(currentLanguage)
    }
  }
}
