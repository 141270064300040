import { useTranslation } from 'react-i18next'
import CheckMarkIcon from '@nv/images/design/check-mark-icon'
import { ModalWrapper } from '../../ModalWrapper'
import PasswordChangeSuccessModalStyles from './PasswordChangeSuccessModal.module.scss'

export const PasswordChangeSuccessModal = (props) => {
  const { t } = useTranslation()
  const { isOpen } = props

  const btns = [
    {
      text: t('buttons.ok'),
      onClick: props.onRequestClose,
    },
  ]

  return (
    <ModalWrapper
      isOpen={isOpen}
      onRequestClose={props.onRequestClose}
      headerLabel={t('auth.password.change.success')}
      btns={btns}
    >
      <div className={PasswordChangeSuccessModalStyles["container"]}>
        <CheckMarkIcon className={PasswordChangeSuccessModalStyles["icon"]} />
        <span className={PasswordChangeSuccessModalStyles["title"]}>{t('auth.password.change.success')}</span>
      </div>
    </ModalWrapper>
  )
}
