import { useHistory } from 'react-router-dom'
import LanguagesDropdown from '../../languages_dropdown/LanguagesDropdown'
import LoginTopBarStyles from './LoginTopBar.module.scss'

export const LoginTopBar = ({ logo = undefined }) => {
  const history = useHistory()

  return (
    <div className={LoginTopBarStyles['container']}>
      <span
        className={LoginTopBarStyles['logo']}
        onClick={() => history.push('/')}
        onKeyDown={(e) => { if (e.key === 'Enter') history.push('/') }}
        role="button"
        tabIndex={0}
      >
        {logo}
      </span>
      <div className={LoginTopBarStyles['dropdown-container']}>
        <LanguagesDropdown />
      </div>
    </div>
  )
}
