import CircleCheckIcon from '@nv/images/design/circle-check-icon'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from '../../ModalWrapper'
import RegisterSuccesModalStyles from './RegisterSuccesModal.module.scss'

export const RegisterSuccesModal = ({ modalOpen, onClose, onClick }) => {
  const { t } = useTranslation()

  const btns = [
    {
      text: t('buttons.ok'),
      onClick: onClick,
    },
  ]

  return (
    <ModalWrapper
      isOpen={modalOpen}
      onRequestClose={onClose}
      headerLabel={t('registration.successfullyRegistered')}
      btns={btns}
    >
      <div className={RegisterSuccesModalStyles['container']}>
        <CircleCheckIcon style={{ width: "200px", height: "100px" }} />
        <div className={RegisterSuccesModalStyles['description']}>{t('registration.successfullAccountMsg')}</div>
      </div>
    </ModalWrapper>
  )
}
