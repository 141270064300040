import { KeycloakService } from '@nv/auth'
import axios from 'axios'
import Cookies from 'universal-cookie'

const cookies = new Cookies()
const VERIFY_COOKIE = 'verifyToken'

export const VoterAxiosInstance = axios.create({
  withCredentials: true,
  headers: {},
  baseURL: process.env.NX_PUBLIC_PUBLIC_URL,
})

// attach access token
VoterAxiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = KeycloakService.getToken()
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  },
)

// flattens the layer of nested introduced by axios
// the responses look like { data, error }, but axios nests the whole response under 'data'
VoterAxiosInstance.interceptors.response.use(
  (res) => {
    res = res.data
    if (res.accessToken) {
      cookies.set(VERIFY_COOKIE, res.accessToken)
    }
    if (res.link && res.link.includes('login-actions')) {
      window.location.replace(res.link)
    }
    return res
  },
  async (err) => {
    const error = {
      ...err.response?.data?.error,
      ...err,
    }
    return Promise.reject(error)
  },
)
export default VoterAxiosInstance
