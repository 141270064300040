import { useEffect, useState } from 'react'
import { TextField } from '@nv/basic-components'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { ModalWrapper } from '../../ModalWrapper'
import SendTestJobModalStyles from './SendTestJobModal.module.scss'

const initialValues = {
  noOfPages: '',
  chooseBallet: '',
}

export const SendTestJobModal = ({
  modalOpen,
  onRequestClose = undefined,
  printerToEdit = undefined,
  setSentJobProgressModal = undefined,
  setSentJobErrorModal = undefined,
}) => {
  const { t } = useTranslation()

  const [validationSchema, setValidationSchema] = useState(
    Yup.object().shape({
      noOfPages: Yup.number()
        .required(t('uploads.document.validations.noOfPages'))
        .min(1, 'Must be at least 1')
        .max(1000, 'Cannot be more than 1000')
        .typeError('Must be a number'),
      chooseBallet: Yup.string().required(t('uploads.document.validations.chooseBallet')),
    }),
  )

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => { }, 1000)
    },
  })

  const updateSchema = (newSchema) => {
    setValidationSchema(newSchema)
  }

  useEffect(() => {
    if (
      (formik.values['noOfPages'] && formik.values['url'] && !formik.errors['url']) ||
      (formik.values['chooseBallet'] && formik.values['url'] && !formik.errors['url'])
    ) {
      updateSchema(Yup.object().shape({}))
    }
  }, [formik.errors])

  const handleSendClick = async () => {
    if (!formik.isValid) {
      formik.setTouched({
        noOfPages: true,
        chooseBallet: true,
      })
      return
    }

    const { noOfPages, chooseBallet } = formik.values
    const IPAddress = printerToEdit.original.IPAddress
    const finisherSerialNum = printerToEdit.original.finisherSerialNum

    setSentJobProgressModal(true)

    console.log('No of Pages:', noOfPages)
    console.log('Choose Ballet:', chooseBallet)
    console.log('Printer to Edit:', printerToEdit)

    try {
      const response = await fetch(`${process.env.NX_PUBLIC_PUBLIC_URL}/vote/${chooseBallet}?numCopies=${noOfPages}&socketId=${IPAddress}&finisherSerialNum=${finisherSerialNum}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          noOfPages,
          chooseBallet,
          finisherSerialNum: finisherSerialNum,
        }),
      })

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()
      //console.log('Success:', data)
      onRequestClose()
      setSentJobProgressModal(false)
      alert("job sent successfully" + data)

    } catch (error) {
      //console.error('Error:', error)
      onRequestClose()
      setSentJobProgressModal(false)
      setSentJobErrorModal(true)
    }
  }

  const btns = [
    {
      text: t('buttons.cancel'),
      onClick: onRequestClose,
      isCancel: true,
    },
    {
      text: t('buttons.send'),
      onClick: handleSendClick,
    },
  ]

  return (
    <ModalWrapper
      isOpen={modalOpen}
      onRequestClose={onRequestClose}
      headerLabel={t('form.labels.sendTestJob')}
      btns={btns}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className={SendTestJobModalStyles["container"]}>
          <TextField
            formik={formik}
            type="number"
            field={'noOfPages'}
            placeholder={t('form.labels.noOfPages')}
            label={t('form.labels.noOfPages')}
            min="1"
            max="1000"
          />
          <div className={SendTestJobModalStyles['dropdown__container']}>
            <label htmlFor="chooseBallet">{t('form.labels.chooseBallet')}</label>
            <select
              name="chooseBallet"
              onChange={(e) => formik.setFieldValue('chooseBallet', e.target.value)}
              onBlur={formik.handleBlur}
              value={formik.values.chooseBallet}
            >
              <option value="" label={t('form.labels.chooseBallet')} />
              <option value="1" label="1" />
              <option value="2" label="2" />
              <option value="3" label="3" />
              <option value="4" label="4" />
            </select>
            {formik.touched.chooseBallet && formik.errors.chooseBallet ? (
              <div className={SendTestJobModalStyles['error']}>
                {formik.errors.chooseBallet}
              </div>
            ) : null}
          </div>
        </div>
      </form>
    </ModalWrapper>
  )
}
