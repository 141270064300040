import axios from 'axios'

export const jumioInstance = axios.create({
  // withCredentials: true,
  headers: {},
  baseURL: `${process.env.NX_PUBLIC_PUBLIC_URL}/auth/jumio`,
})

// attach access token
jumioInstance.interceptors.request.use(
  async (config) => {
    return config
  },
  (error) => {
    Promise.reject(error)
  },
)

// flattens the layer of nested introduced by axios
// the responses look like { data, error }, but axios nests the whole response under 'data'
jumioInstance.interceptors.response.use(
  (res) => res.data,
  (err) => {
    const error = {
      ...err.response.data.error,
      ...err,
    }

    // console.error(error);
    return Promise.reject(error)
  },
)
