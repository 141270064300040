import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import ReactSelectViewStyles from './StyledReactSelect.module.scss'

const ReactSelectView = (props) => {
  const { t } = useTranslation()
  return (
    <Select
      className={ReactSelectViewStyles['select']}
      styles={props.customStyles}
      noOptionsMessage={() => t('form.select.noOptions')}
      {...props}
    />
  )
}

const CustomDropdown2 = (props) => {
  const { customStyles, label } = props
  return (
    <div className={ReactSelectViewStyles['container']} style={customStyles}>
      <span className={ReactSelectViewStyles['label']}>{label}</span>
      <ReactSelectView {...props} />
    </div>
  )
}

export default CustomDropdown2
