import { useMemo, useRef, useState } from 'react'
import { CustomBtn } from '@nv/basic-components'
import { ListTable, ProgressBar } from '@nv/components'
import FileIcon from '@nv/images/design/file-icon'
import Papa from 'papaparse'
import { useTranslation } from 'react-i18next'
import UploadCSVStyles from './UploadCSV.module.scss'

const NUM_VOTERS_PER_PAGE = 10

export const UploadCSV = ({
  description,
  columns,
  onUpload,
  loadingParsing = undefined,
  setLoadingParsing = undefined,
  processed = undefined
}) => {
  const [tableData, setTableData] = useState([])
  const { t } = useTranslation()
  const [currentPageNum, setCurrentPageNum] = useState(0)
  const [total, setTotal] = useState(0)
  const fileInputRef = useRef(null)

  const handleIconClick = () => fileInputRef.current && fileInputRef.current.click()

  const handleFileChange = (e) => {
    setLoadingParsing(true)
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0]

      Papa.parse(selectedFile, {
        header: true,
        skipEmptyLines: true,
        complete: async (results) => {
          const data = results.data
          setTableData(data)
          setTotal(data.length)
          console.log(selectedFile)
          if (onUpload) {
            setLoadingParsing(false)
            await onUpload(selectedFile, data.length) // Call the parent upload function
          }
        },
      })
    }
  }

  const paginatedData = useMemo(() => {
    const start = currentPageNum * NUM_VOTERS_PER_PAGE
    const end = start + NUM_VOTERS_PER_PAGE
    return tableData.slice(start, end)
  }, [tableData, currentPageNum])

  return (
    <>
      {!loadingParsing ? (
        <>
          {processed > 0 && <ProgressBar total={total} processed={processed} />}
          <ListTable
            data={paginatedData}
            columns={columns}
            currentPageNum={currentPageNum}
            dataLength={tableData.length}
            loading={loadingParsing}
            setCurrentPageNum={setCurrentPageNum}
          />
        </>
      ) : (
        <div
          className={UploadCSVStyles.container}
          onClick={handleIconClick}
          onKeyDown={(e) => { if (e.key === 'Enter') handleIconClick }}
          role="button"
          tabIndex={0}
        >
          <FileIcon />
          <div>{description}</div>
          <div className={UploadCSVStyles.text}>{t('Please upload one')}</div>

          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            ref={fileInputRef}
          />

          <CustomBtn text={t('Upload')} height={50} />
        </div>
      )}
    </>
  )
}

export default UploadCSV
