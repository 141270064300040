import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import AudioIcon from '@nv/images/design/audio-icon'
import DocIcon from '@nv/images/design/doc-icon'
import JpgIcon from '@nv/images/design/jpg-icon'
import PdfIcon from '@nv/images/design/pdf-icon'
import PngIcon from '@nv/images/design/png-icon'
import RemoveIcon from '@nv/images/design/remove-icon'
import VideoIcon from '@nv/images/design/video-icon'
import UploadedDocumentStyles from './UploadedDocument.module.scss'

const UploadedDocument = ({ localFile, onChangeFile }) => {
  const { t } = useTranslation()

  const getFileType = (filename) => {
    const extension = filename.split('.').pop()
    return extension
  }

  useEffect(() => {
    //console.log('localFile', localFile)
  }, [localFile])

  return (
    <>
      <div
        className={UploadedDocumentStyles['container']}
        onClick={() => onChangeFile()}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onChangeFile()
        }}
        role="button"
        tabIndex={0}
      >
        <RemoveIcon />
        <span className={UploadedDocumentStyles['upload__text']}>{t('uploads.changeDocument')}</span>
      </div>
      <div className={UploadedDocumentStyles['upload']}>
        <div className={UploadedDocumentStyles['upload__icon']}>
          {getFileType(localFile.name) === 'pdf' && <PdfIcon />}
          {getFileType(localFile.name) === 'jpg' ||
            (getFileType(localFile.name) === 'jpeg' && <JpgIcon />)}
          {getFileType(localFile.name) === 'png' && <PngIcon />}
          {getFileType(localFile.name) === 'doc' ||
            (getFileType(localFile.name) === 'docx' && <DocIcon />)}
          {['mov', 'mp4'].includes(getFileType(localFile.name)) && <VideoIcon />}
          {['mp3', 'wav'].includes(getFileType(localFile.name)) && <AudioIcon />}
          <span className={UploadedDocumentStyles['upload__text']}>{localFile.name}</span>
        </div>
      </div>
    </>
  )
}

export default UploadedDocument
