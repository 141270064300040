import { SVG } from '../SVG'

const PwEyeSlashIcon = ({ style = {}, ...props }) => (
  <SVG
    width='24' 
    height='24' 
    viewBox='0 0 24 24' 
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{ stroke: 'var(--secondary-color-darker)', ...style }}
    {...props}
  >
    <path d="M4.5 3.75L19.5 20.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.5218 14.7746C13.8337 15.405 12.9333 15.7532 12 15.7496C11.2431 15.7495 10.5039 15.5204 9.87972 15.0924C9.25549 14.6644 8.77539 14.0575 8.50253 13.3515C8.22967 12.6455 8.17683 11.8735 8.35095 11.1369C8.52507 10.4003 8.918 9.73369 9.47809 9.22461" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.9375 6.43164C3.1125 8.36289 1.5 12.0004 1.5 12.0004C1.5 12.0004 4.5 18.7504 12 18.7504C13.7574 18.7647 15.4929 18.3598 17.0625 17.5691" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M19.5562 15.8531C21.6 14.025 22.5 12 22.5 12C22.5 12 19.5 5.25002 12 5.25002C11.3498 5.24874 10.7006 5.30205 10.0593 5.4094" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12.7031 8.31543C13.5006 8.46653 14.2273 8.87293 14.7735 9.47329C15.3198 10.0736 15.6559 10.8354 15.7313 11.6436" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
 </SVG>
)

export default PwEyeSlashIcon
