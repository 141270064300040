import { useTranslation } from 'react-i18next'
import WarningIcon from '@nv/images/design/warning-icon'
import { ModalWrapper } from '../../ModalWrapper'
import ConfirmQrRequestModalStyles from './ConfirmQrRequestModal.module.scss'

export const ConfirmQrRequestModal = (props) => {
  const { t } = useTranslation()

  const btns = [
    {
      text: t('buttons.cancel'),
      onClick: () => {
        props.onRequestClose()
      },
      isCancel: true
    },
    {
      text: t('buttons.ok'),
      onClick: () => {
        props.onClick()
      },
    },
  ]

  return (
    <ModalWrapper
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      headerLabel={t('auth.authApp.form.confirmRequest')}
      btns={btns}
    >
      <div className={ConfirmQrRequestModalStyles['container']}>
        <WarningIcon className={ConfirmQrRequestModalStyles['icon']} />
        <span className={ConfirmQrRequestModalStyles['text']}>
          {t('auth.authApp.form.confirmRequest')}
        </span>
      </div>
    </ModalWrapper>
  )
}
