import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AdminRoleDropdown } from '../../../admin_role_dropdown/AdminRoleDropDown'
import { ModalWrapper } from '../../ModalWrapper'
import EmailSendModalStyles from './EmailSendModal.module.scss'

export const EmailSendModal = ({
  isOpen = false,
  options = '',
  onRequestClose = undefined,
  onSend = undefined,
}) => {
  const { t } = useTranslation()
  const [eventId, setEventId] = useState('')

  const btns = [
    {
      text: t('buttons.cancel'),
      onClick: onRequestClose,
      isCancel: true,
    },
    {
      text: t('admin.sendEmails'),
      onClick: () => onSend(eventId),
    },
  ]

  return (
    <ModalWrapper
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      headerLabel={t('admin.sendEmails')}
      btns={btns}
    >
      <div className={EmailSendModalStyles['container']}>
        <AdminRoleDropdown
          title={t('events.event')}
          isRequired={false}
          options={options}
          value={eventId}
          setValue={setEventId}
        />
      </div>
    </ModalWrapper>
  )
}
