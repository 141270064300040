import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import RenderTBDStyles from './RenderTBD.module.scss'

const RenderTBD = ({ status, size, layout }) => {
  const { t } = useTranslation()

  const isColumn = layout === 'column'

  if (size === 'big') {
    return (
      <div
        className={clsx(RenderTBDStyles['lg-date'], {
          [RenderTBDStyles['lg-date--column']]: isColumn,
        })}
      >
        <span
          className={clsx(RenderTBDStyles['lg-date__label'], {
            [RenderTBDStyles['lg-date__label--column']]: isColumn,
          })}
        >
          {t(`countdown.label.${status}`)}
        </span>

        <span
          className={clsx(RenderTBDStyles['lg-date__label'], {
            [RenderTBDStyles['lg-date__label--column']]: isColumn,
          })}
        >
          {t('countdown.time.TBD')}
        </span>
      </div>
    )
  }

  return (
    <div className={RenderTBDStyles['container']}>
      <div className={RenderTBDStyles['default-label']}>{t(`countdown.label.${status}`)}</div>
      <div className={RenderTBDStyles['default-value']}>{t('countdown.time.TBD')}</div>
    </div>
  )
}
export default RenderTBD
