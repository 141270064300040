import { SVG } from '../SVG'

const ShieldCheckMark = ({ style={}, ...props }) => (
  <SVG
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill="none"
    xmlns='http://www.w3.org/2000/svg'
    style={{ ...style }}
    {...props}
  >
    <path
      d='M5 14.3375V7C5 6.73478 5.10536 6.48043 5.29289 6.29289C5.48043 6.10536 5.73478 6 6 6H26C26.2652 6 26.5196 6.10536 26.7071 6.29289C26.8946 6.48043 27 6.73478 27 7V14.3375C27 24.8375 18.0875 28.3125 16.3125 28.9C16.1109 28.9747 15.8891 28.9747 15.6875 28.9C13.9125 28.3125 5 24.8375 5 14.3375Z'
      stroke='var(--tertiary-color)'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M21.5 13L14.1625 20L10.5 16.5'
      stroke='var(--tertiary-color)'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SVG>
)

export default ShieldCheckMark
