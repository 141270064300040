import { SVG } from '../SVG'

const XCircleIcon = ({ style = {}, ...props }) => (
  <SVG
    width="20"
    height="20"
    viewBox="0 0 65 65"
    xmlns="http://www.w3.org/2000/svg"
    style={{ ...style }}
    {...props}
  >
    <path
      d="M32.5 56.875C45.9619 56.875 56.875 45.9619 56.875 32.5C56.875 19.0381 45.9619 8.125 32.5 8.125C19.0381 8.125 8.125 19.0381 8.125 32.5C8.125 45.9619 19.0381 56.875 32.5 56.875Z"
      fill="none"
      stroke="var(--warning-color)"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.4062 26.4062L37.8967 37.8967"
      stroke="var(--warning-color)"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.8984 26.4062L26.408 37.8967"
      stroke="var(--warning-color)"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
)

export default XCircleIcon
