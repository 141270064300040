import NoAttachmentIcon from '@nv/images/design/no-attachment-icon'
import { useTranslation } from 'react-i18next'
import AttachmentsStyles from './NoAttachmentsMessage.module.scss'

const NoAttachmentsMessage = () => {
  const { t } = useTranslation()

  return (
    <div className={AttachmentsStyles['container']}>
      <NoAttachmentIcon />
      <span className={AttachmentsStyles['text']}>{t('events.noAttachments')}</span>
    </div>
  )
}

export default NoAttachmentsMessage
