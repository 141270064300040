import { useContext, useEffect } from 'react'
import { KeycloakService } from '@nv/auth'
import { KeycloakAdminService } from '@nv/auth'
import { LoginFlowContext } from '@nv/contexts'
import { AdminVoterAuthService } from '@nv/services'
import { VoterAuthService } from '@nv/services'
import { beforeUnload } from '@nv/utils'
import { useHistory } from 'react-router-dom'

const AUTH_CHECK_INTERVAL = 30000

export function useKeycloakSession() {
  const history = useHistory()
  const LOGIN_CONTEXT = useContext(LoginFlowContext)

  const admin = window.location.pathname?.startsWith('/admin') ? true : false
  const KcService = admin ? KeycloakAdminService : KeycloakService

  const onSessionExpired = async () => {
    try {
      window.removeEventListener('beforeunload', beforeUnload)
      if (!admin) {
        await VoterAuthService.logOut(history)
        LOGIN_CONTEXT.setLoginFlow('Login')
      } else {
        await AdminVoterAuthService.logOut(history)
        LOGIN_CONTEXT.setLoginFlow('Admin Login')
      }
    } catch (error) {
      //console.log(error)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      // for unauthenticated change password page
      if (KeycloakAdminService?.isLoggedIn() || KeycloakService?.isLoggedIn())
        KcService.onTokenExpired(onSessionExpired, AUTH_CHECK_INTERVAL / 1000)
    }, AUTH_CHECK_INTERVAL)

    return () => clearInterval(interval)
  }, [])
}
