import { formatDateLocale } from '@nv/utils'
import { useTranslation } from 'react-i18next'
import FormattedDateStyles from './FormattedDate.module.scss'

const FormattedDate = ({ date, size, status }) => {
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language

  if (size === 'big') {
    return (
      <div className={FormattedDateStyles['lg-date']}>
        <span className={FormattedDateStyles['lg-date__label']}>
          {t(`countdown.label.${status}`)}
        </span>
        <span className={FormattedDateStyles['lg-date__value']}>
          {formatDateLocale(date, currentLanguage)}
        </span>
      </div>
    )
  }

  return (
    <div>
      <div className={FormattedDateStyles['default-label']}>{t(`countdown.label.${status}`)}</div>
      <div className={FormattedDateStyles['default-value']}>
        {formatDateLocale(date, currentLanguage)}
      </div>
    </div>
  )
}

export default FormattedDate
