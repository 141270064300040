import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

export const initI18n = () => {
  const storedLanguage = localStorage.getItem('language') || 'en'

  return i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      debug: true,
      fallbackLng: 'en',
      supportedLngs: ['en', 'fr'],
      lng: storedLanguage,
      ns: ['common', 'app'],
      fallbackNS: 'app',
      backend: {
        loadPath: 'locales/{{ns}}/{{lng}}/translation.json',
      },
      detection: {
        order: ['localStorage', 'navigator'],
        caches: ['localStorage'],
      },
    })
}
