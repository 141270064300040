import { createContext, useContext, useEffect, useState } from 'react'
import { useSocket } from '@nv/hooks'

const FinisherDataContext = createContext()

export const DataProvider = ({ children }) => {
  const { updates, data } = useSocket('http://localhost:4000')
  const [socketData, setSocketData] = useState({})
  const [loading, setLoading] = useState(true)

  console.log('socketData:', socketData)

  useEffect(() => {
    if (data) {
      setSocketData(data)
      setLoading(false)
    }
  }, [data])

  useEffect(() => {
    console.log('updates:', updates)

    if (updates.length !== 0) {
      updates.forEach((update) => {
        const updateId = update.documentKey._id

        if (update.operationType === 'update') {
          const updatedFields = update.updateDescription?.updatedFields

          if (updatedFields) {
            setSocketData((prevData) => {
              const updatedColl = prevData[update.ns.coll].map((data) => {
                if (data._id === updateId) {
                  return { ...data, ...updatedFields }
                }
                return data
              })
              return { ...prevData, [update.ns.coll]: updatedColl }
            })
          }
        } else if (update.operationType === 'insert') {
          const newData = update.fullDocument
          if (newData) {
            setSocketData((prevData) => {
              const exists = prevData[update.ns.coll].some((data) => data._id === newData._id)
              if (!exists) return { ...prevData, [update.ns.coll]: [...prevData[update.ns.coll], newData] }
              return prevData
            })
          }
        } else if (update.operationType === 'delete') {
          setSocketData((prevData) => {
            const newData = prevData[update.ns.coll].filter((data) => data._id !== updateId)
            return { ...prevData, [update.ns.coll]: newData }
          })
        }
      })
      setLoading(false)
    }
  }, [data, updates])

  return <FinisherDataContext.Provider value={{ socketData, loading }}>{children}</FinisherDataContext.Provider>
}

export const useData = () => useContext(FinisherDataContext)
