import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

const initialState = {
  user: {},
  _kc: null,
}
const storeName = 'auth-admin-kc'

export const useAuthAdminKc = create(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        set,
      }),
      {
        name: storeName,
        storage: createJSONStorage(() => sessionStorage),
        partialize: (state) =>
          Object.fromEntries(Object.entries(state).filter(([key]) => !['_kc', 'initialized', 'user', 'token'].includes(key))),
        // onRehydrateStorage: () => (state) => {
        //   if (state?._kc?.tokenParsed?.exp && state._kc.tokenParsed.exp <= Date.now() / 1000) {
        //     // Remove the jwt if it's expired
        //     state.set({ user: {} })
        //   }
        // },
      },
    ),
    { store: storeName, name: 'app' },
  ),
)

export const setAuthAdminKc = (args) => {
  useAuthAdminKc.setState(args, false, {
    type: 'setAuthAdminKc',
  })
}

export const clearAuthAdminKcStore = () => {
  useAuthAdminKc.setState({ ...initialState }, true, {
    type: 'clearAuthAdminKcStore',
  })
}

export const setAuthAdminKcUser = (user) => {
  if (user) {
    useAuthAdminKc.setState(
      {
        user: {
          ...useAuthAdminKc.getState().user,
          ...user,
        },
      },
      false,
      { type: 'setAuthAdminKcUser' },
    )
  } else {
    useAuthAdminKc.setState({ user: {} }, false, {
      type: 'setAuthAdminKcUser',
    })
  }
}
