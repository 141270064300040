import { SVG } from '../SVG'

const PdfIcon = ({ style = {}, ...props }) => (
  <SVG
    width="50"
    height="50"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
    style={{ ...style }}
    {...props}
  >
    <path
      d="M12.125 0C10.4062 0 9 1.40625 9 3.125V46.875C9 48.5938 10.4062 50 12.125 50H43.375C45.0938 50 46.5 48.5938 46.5 46.875V12.5L34 0H12.125Z"
      fill="var(--secondary-color)"
      stroke="none"
    />
    <path
      d="M37.5 12.5H46.875L34.375 0V9.375C34.375 11.0938 35.7812 12.5 37.5 12.5Z"
      fill="var(--secondary-color-darker)"
    />
    <path
      d="M46.875 21.875L37.5 12.5H46.875V21.875Z"
      fill="var(--secondary-color-dark)"
    />
    <path
      d="M40.625 40.625C40.625 41.4844 39.9219 42.1875 39.0625 42.1875H4.6875C3.82812 42.1875 3.125 41.4844 3.125 40.625V25C3.125 24.1406 3.82812 23.4375 4.6875 23.4375H39.0625C39.9219 23.4375 40.625 24.1406 40.625 25V40.625Z"
      fill="var(--warning-color)"
    />
    <path
      d="M39.0625 42.1875H9.375V43.75H39.0625C39.9219 43.75 40.625 43.0469 40.625 42.1875V40.625C40.625 41.4844 39.9219 42.1875 39.0625 42.1875Z"
      fill="var(--secondary-color)"
    />
    <path
      d="M14.5294 33.8254H12.1919V32.3511H14.5294C14.8906 32.3511 15.1846 32.2923 15.4114 32.1746C15.6382 32.0528 15.8041 31.8848 15.9091 31.6706C16.0142 31.4564 16.0667 31.2149 16.0667 30.9461C16.0667 30.6731 16.0142 30.4189 15.9091 30.1837C15.8041 29.9485 15.6382 29.7595 15.4114 29.6167C15.1846 29.4739 14.8906 29.4025 14.5294 29.4025H12.8472V37.0953H10.957V27.9219H14.5294C15.2476 27.9219 15.8629 28.0521 16.3754 28.3125C16.892 28.5687 17.2868 28.9236 17.5599 29.3773C17.8329 29.8309 17.9694 30.3496 17.9694 30.9335C17.9694 31.5257 17.8329 32.0381 17.5599 32.4708C17.2868 32.9034 16.892 33.2373 16.3754 33.4725C15.8629 33.7077 15.2476 33.8254 14.5294 33.8254Z"
      fill="var(--color-white)"
    />
    <path
      d="M21.8318 37.0953H19.8346L19.8472 35.621H21.8318C22.3274 35.621 22.7453 35.5097 23.0856 35.287C23.4258 35.0602 23.682 34.7305 23.8542 34.2979C24.0306 33.8653 24.1188 33.3423 24.1188 32.7291V32.2818C24.1188 31.8113 24.0684 31.3976 23.9676 31.0406C23.871 30.6836 23.7261 30.3832 23.5329 30.1396C23.3397 29.896 23.1024 29.7133 22.8209 29.5915C22.5395 29.4655 22.2161 29.4025 21.8507 29.4025H19.7967V27.9219H21.8507C22.4639 27.9219 23.0247 28.0269 23.5329 28.2369C24.0453 28.4427 24.4884 28.7388 24.8623 29.1253C25.2361 29.5117 25.5238 29.9737 25.7254 30.5113C25.9312 31.0448 26.0341 31.6391 26.0341 32.2944V32.7291C26.0341 33.3801 25.9312 33.9745 25.7254 34.5121C25.5238 35.0497 25.2361 35.5118 24.8623 35.8982C24.4926 36.2804 24.0495 36.5765 23.5329 36.7865C23.0205 36.9924 22.4534 37.0953 21.8318 37.0953ZM20.9056 27.9219V37.0953H19.0155V27.9219H20.9056Z"
      fill="var(--color-white)"
    />
    <path
      d="M29.0397 27.9219V37.0953H27.1496V27.9219H29.0397ZM32.6939 31.8407V33.315H28.5231V31.8407H32.6939ZM33.135 27.9219V29.4025H28.5231V27.9219H33.135Z"
      fill="var(--color-white)"
    />
  </SVG>
)

export default PdfIcon
