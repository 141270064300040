import { KeycloakAdminService } from '@nv/auth'

/**
 * Determines whether a user can only view help docs or can also upload and delete
 */
export const isViewOnlyHelpManagement = () => {
  let viewOnly = true // Default to viewOnly mode
  const isAdmin = KeycloakAdminService?.isLoggedIn() // Check if user is logged in as admin

  // Check if user is an admin based on roles
  if (isAdmin && KeycloakAdminService.isSuperAdmin()) {
    viewOnly = false // Set viewOnly to false if user is an admin
  }

  return viewOnly
}

export const isViewOnlyEventHelpManagement = () => {
  let viewOnly = true // Default to viewOnly mode
  const isAdmin = KeycloakAdminService?.isLoggedIn() // Check if user is logged in as admin

  // Check if user is an admin based on roles
  if (isAdmin && (KeycloakAdminService.isSuperAdmin() || KeycloakAdminService.isEventModuleAdmin())) {
    viewOnly = false // Set viewOnly to false if user is an admin
  }

  return viewOnly
}

// Determines whether a user is admin, if they are not, they are a voter
export const isAdmin = () => {
  const isAdmin = KeycloakAdminService?.isLoggedIn() // Check if user is logged in as admin
  return isAdmin
}
