import { SVG } from '../SVG'

const NoAttachmentIcon = ({ style = {}, ...props }) => {
  return (
    <SVG
      width="50" 
      height="50" 
      viewBox="0 0 50 50" 
      fill="none"
      xmlns="http://www.w3.org/2000/svg" 
      style={{"stroke": "var(--tertiary-color)", ...style}}
      strokeWidth="3" 
      strokeLinecap="round" 
      strokeLinejoin="round"
      {...props}
    >
      <path d="M3.3335 47.0941L46.6668 3.76074" />
      <path d="M36.9429 14.1338L42.9866 20.1775" />
      <path d="M11.47 28.5131C5.2135 22.2566 4.51213 12.4494 9.36792 7.59359C14.8972 2.06234 23.0924 0.884757 31.0406 8.83095" />
      <path d="M41.9605 30.5122C46.6529 35.2046 46.3261 41.0367 42.4049 44.958C38.4836 48.8793 31.8305 48.4748 27.1381 43.7824L17.4927 34.1366" />
      <path d="M31.5386 20.0312L42.4542 30.9469" />
      <path d="M17.3695 22.7445C13.6096 18.9846 14.1236 15.8643 15.8611 14.1288L16.3712 13.6187C18.6148 11.3751 21.3545 11.0423 25.1144 14.8022" />
      <path d="M28.5626 33.3404L23.2329 28.0107" />
    </SVG>
  )
}

export default NoAttachmentIcon
