import { SVG } from '../SVG'

const CircleArrowLeftIcon = ({ style = {}, ...props }) => (
  <SVG
    width="40"
    height="40"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    style={{ ...style }}
    {...props}
  >
    <path
      d="M20 35C28.2843 35 35 28.2843 35 20C35 11.7157 28.2843 5 20 5C11.7157 5 5 11.7157 5 20C5 28.2843 11.7157 35 20 35Z"
      fill="var(--secondary-color)"
      stroke="var(--tertiary-color)"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M22.5 14.375L16.25 20L22.5 25.625"
      fill="none"
      stroke="var(--tertiary-color)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
)

export default CircleArrowLeftIcon
