import VoterAxiosInstance from './VoterAxiosInstance'

export const NominatorService = {
  createNomination: async (eventId, qid, nomineeId) => {
    try {
      await VoterAxiosInstance.post(
        `/nominations/events/${eventId}/questions/${qid}/nominees/${nomineeId}`
      )
    } catch (error) {
      /* empty */
    }
  },
  reconsiderNomination: async (nominationId) => {
    try {
      await VoterAxiosInstance.put(`/nominations/${nominationId}/reconsider`)
    } catch (error) {
      /* empty */
    }
  },
  searchEligibleNominees: async (eventId, qid, search) => {
    try {
      // search required
      const eligibleNomineesData: any = await VoterAxiosInstance.get(
        `/nominations/events/${eventId}/questions/${qid}/nominees?search=${search}`
      )
      // returns array of eligibleNominees, and object nominatedByCurrentUser
      const { nominatedByCurrentUser, eligilbeNominees } = eligibleNomineesData
      return { nominatedByCurrentUser, eligilbeNominees }
    } catch (error) {
      /* empty */
    }
  },
}
