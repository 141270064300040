import { useEffect, useMemo, useRef, useState } from 'react'
import { KeycloakService } from '@nv/auth'
import { KeycloakAdminService } from '@nv/auth'
import LanguageIcon from '@nv/images/design/langauge-icon'
import { AdminVoterService } from '@nv/services'
import { VoterAuthService } from '@nv/services'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import LanguagesDropdownStyles from './LanguagesDropdown.module.scss'

const LANGUAGES = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'Français' },
]

const LanguagesDropdown = ({ customStyling = {} }) => {
  const [openDropdown, setOpenDropdown] = useState(false)
  const dropdownOptionsRef = useRef(null)
  const { t, i18n } = useTranslation()

  // Update language function
  const updateLanguage = ({ newLanguage }) => {
    i18n.changeLanguage(newLanguage)
    setOpenDropdown(false)

    if (KeycloakService.isLoggedIn()) {
      VoterAuthService.updateUserLanguage(newLanguage)
    }
    if (KeycloakAdminService.isLoggedIn()) {
      AdminVoterService.updateUserLanguage(newLanguage)
    }

    // Save language preference to localStorage
    localStorage.setItem('language', newLanguage)
  }

  // Set language based on localStorage or default to 'en'
  useEffect(() => {
    const storedLanguage = localStorage.getItem('language') || 'en'  // Default to 'en' if no language in localStorage
    if (storedLanguage !== i18n.language) {
      i18n.changeLanguage(storedLanguage)
    }
  }, [i18n])

  const renderSelect = useMemo(() => {
    return (
      <div
        style={customStyling}
        className={LanguagesDropdownStyles['select']}
        onClick={!openDropdown ? () => setOpenDropdown(true) : null}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !openDropdown) setOpenDropdown(true)
        }}
        role="button"
        tabIndex={0}
      >
        <LanguageIcon className={''} />
        <span className={LanguagesDropdownStyles['select__selected-lang']}>
          {t('main.currentLanguage')}
        </span>
        <div
          className={clsx(LanguagesDropdownStyles['select__chevron'], {
            [LanguagesDropdownStyles['select__chevron--rotate']]: openDropdown,
          })}
        ></div>
      </div>
    )
  }, [customStyling, openDropdown])

  const DropdownOptions = () => (
    <div className={LanguagesDropdownStyles['options']} ref={dropdownOptionsRef}>
      {LANGUAGES.map((topic, index) => (
        <div
          key={index}
          className={LanguagesDropdownStyles['options-item']}
          onClick={() => {
            updateLanguage({ newLanguage: topic.value })
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') updateLanguage({ newLanguage: topic.value })
          }}
          role="button"
          tabIndex={0}
        >
          <span className={LanguagesDropdownStyles['options-item__label']}>{topic.label}</span>
        </div>
      ))}
    </div>
  )

  const handleClickOutside = (event) => {
    if (dropdownOptionsRef.current && !dropdownOptionsRef.current.contains(event.target)) {
      setOpenDropdown(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className={LanguagesDropdownStyles['container']}>
      {renderSelect}
      {openDropdown && <DropdownOptions />}
    </div>
  )
}

export default LanguagesDropdown
