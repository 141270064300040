import { useCountdown } from '@nv/hooks'
import { useTranslation } from 'react-i18next'
import CountdownTimerSmallStyles from './CountdownTimerSmall.module.scss'

const CountdownTimerSmall = ({ countDownDate, status }) => {
  const { t } = useTranslation()
  const { days, hours, minutes, seconds } = useCountdown(countDownDate)

  return (
    <div className={CountdownTimerSmallStyles['container']}>
      <div className={CountdownTimerSmallStyles['title']}>{t(`countdown.label.${status}`)}</div>

      <div className={CountdownTimerSmallStyles['content']}>
        {days}
        <span className={CountdownTimerSmallStyles['content__value']}>
          {t('countdown.time.short.days')}
        </span>
        {hours}
        <span className={CountdownTimerSmallStyles['content__value']}>
          {t('countdown.time.short.hours')}
        </span>
        {minutes}
        <span className={CountdownTimerSmallStyles['content__value']}>
          {t('countdown.time.short.mins')}
        </span>
        {seconds}
        <span className={CountdownTimerSmallStyles['content__value']}>
          {t('countdown.time.short.sec')}
        </span>
      </div>
    </div>
  )
}

export default CountdownTimerSmall
