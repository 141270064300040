import { SVG } from '../SVG'

const CircleCheckIcon = ({ style = {}, ...props }) => (
  <SVG
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ ...style }}
    {...props}
  >
    <path
      d="M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z"
      stroke="var(--success-color)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8125 7.3125L7.68516 11.25L5.625 9.28125"
      stroke="var(--success-color)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
)

export default CircleCheckIcon
