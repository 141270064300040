import AdminAxiosInstance from './AdminAxiosInstance'

export const AdminCandidateService = {
  startNominations: async (eventId) => {
    try {
      await AdminAxiosInstance.put(`/events/${eventId}/nominations/started`)
    } catch (error) {
      /* empty */
    }
  },
  endNominations: async (eventId) => {
    try {
      await AdminAxiosInstance.put(`/events/${eventId}/nominations/ended`)
    } catch (error) {
      /* empty */
    }
  },
  getNomineesPosition: async (eventId, qid, limit = 10, skip = 0, search = '') => {
    try {
      let url = `/events/${eventId}/questions/${qid}/nominees?limit=${limit}&skip=${skip}`
      if (search) {
        url += `&search=${search}`
      }
      const nomineesData: any = await AdminAxiosInstance.get(url)
      return { nominees: nomineesData.users, numNominees: nomineesData.numUsers }
    } catch (error) {
      /* empty */
    }
  },
  getNominee: async (nomineeId) => {
    try {
      const nominee = await AdminAxiosInstance.get(`/candidates/${nomineeId}`)
      return { nominee }
    } catch (error) {
      /* empty */
    }
  },
  approveNomineeAsCandidate: async (nomineeId) => {
    try {
      await AdminAxiosInstance.put(`/candidates/${nomineeId}/approve`)
    } catch (error) {
      /* empty */
    }
  },
  declineNomineeAsCandidate: async (nomineeId) => {
    try {
      await AdminAxiosInstance.put(`/candidates/${nomineeId}/decline`)
    } catch (error) {
      /* empty */
    }
  },
  updateNomineeBio: async (nomineeId, data) => {
    try {
      // data includes bio:string
      await AdminAxiosInstance.put(`/candidates/${nomineeId}/bio`, data)
    } catch (error) {
      /* empty */
    }
  },
}
export default AdminCandidateService
