import { useTranslation } from 'react-i18next'
import { PrinterLgIcon } from '@nv/images/design/printer-lg-icon'
import { ServerIcon } from '@nv/images/design/server-icon'
import { ModalWrapper } from '../../ModalWrapper'
import SendJobProgressModalStyles from './SendJobProgressModal.module.scss'

export const SendJobProgressModal = ({
  modalOpen,
  onRequestClose = undefined,
}) => {
  const { t } = useTranslation()

  return (
    <ModalWrapper
      isOpen={modalOpen}
      onRequestClose={onRequestClose}
      headerLabel={t('form.labels.sendTestJob')}
    >
      <div className={SendJobProgressModalStyles["container"]}>

        <div className={SendJobProgressModalStyles["progress__container"]}>
          <ServerIcon />
          <div className={SendJobProgressModalStyles["connector"]}>
            <div className={SendJobProgressModalStyles["dot"]}></div>
            <div className={SendJobProgressModalStyles["dot"]}></div>
            <div className={SendJobProgressModalStyles["dot"]}></div>
            <div className={SendJobProgressModalStyles["dot"]}></div>
          </div>
          <PrinterLgIcon />
        </div>

        <div className={SendJobProgressModalStyles["message"]}>
          <p>Sending job to the printer.<br />Please do not close this window</p>
        </div>
      </div>
    </ModalWrapper>
  )
}
