import { useTranslation } from 'react-i18next'
import { ModalWrapper } from '../../ModalWrapper'
import DownloadingModalStyles from './DownloadingModal.module.scss'

export const DownloadingModal = (props) => {
  const { t } = useTranslation()
  const { isOpen } = props

  const btns = [
    {
      text: t('buttons.ok'),
      onClick: props.onRequestClose,
    },
  ]

  return (
    <ModalWrapper
      isOpen={isOpen}
      onRequestClose={props.onRequestClose}
      headerLabel={t('main.downloading')}
      btns={btns}
    >
      <div className={DownloadingModalStyles['container']}>
        <span className={DownloadingModalStyles['title']}> {t('main.downloading')}</span>
        <span className={DownloadingModalStyles['title']}>{t('admin.electorsList.downloading')}</span>
      </div>
    </ModalWrapper>
  )
}
