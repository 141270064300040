import { useAuthAdminKc, useAuthKc } from '@nv/zustand'
import { Redirect } from 'react-router-dom'

export const VerifyUserGuard = ({ component: WrappedComponent, redirectURL = '/' }) => {
  const adminUser = useAuthAdminKc().user.token
  const voterUser = useAuthKc().user.token

  if (!adminUser && !voterUser) {
    return <Redirect to={redirectURL} />
  } else {
    return <WrappedComponent />
  }
}
