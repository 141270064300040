import { AdminCandidateService } from '@nv/services'
import { AdminAxiosInstance } from '@nv/services'

// Services only used in this project
const AdminServiceQUT = {
  ...AdminCandidateService,
  createEvent: async (eventData) => {
    try {
      const updatedEvent = await AdminAxiosInstance.post(`/events/`, eventData)
      return { updatedEvent }
    } catch (error) {
      /* empty */
    }
  },
  uploadEligibleNominees: async (eventId, data) => {
    try {
      await AdminAxiosInstance.post(`/events/${eventId}/nominations/nominees`, data)
    } catch (error) {
      /* empty */
    }
  },
  uploadEligibleNominators: async (eventId, data) => {
    try {
      await AdminAxiosInstance.post(`/events/${eventId}/nominations/nominators`, data)
    } catch (error) {
      /* empty */
    }
  },
  getEligibleNominees: async (eventId, limit = 10, skip = 0, search = '') => {
    try {
      let url = `/events/${eventId}/nominations/nominees?limit=${limit}&skip=${skip}`
      if (search) {
        url += `&search=${search}`
      }
      const nomineesData: any = await AdminAxiosInstance.get(url)

      return { nominees: nomineesData.users, numNominees: nomineesData.numUsers }
    } catch (error) {
      /* empty */
    }
  },
  getEligibleNominators: async (eventId, limit = 10, skip = 0, search = '') => {
    try {
      // limit , skip
      let url = `/events/${eventId}/nominations/nominators?limit=${limit}&skip=${skip}`
      if (search) {
        url += `&search=${search}`
      }
      const nominatorsData: any = await AdminAxiosInstance.get(url)
      return { nominators: nominatorsData.users, numNominators: nominatorsData.numUsers }
    } catch (error) {
      /* empty */
    }
  },
}

export default AdminServiceQUT
