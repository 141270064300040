import { useSessionTimer } from '@nv/contexts'
import { useCurrentTime } from '@nv/hooks'
import { useKeycloakSessionTime } from '@nv/hooks'
import { CurrentTime } from '../../../../current_time/CurrentTime'
import LogoutTimer from '../../../../logout_timer/LogoutTimer'
import { ExtendSessionModal } from '../../../../modal_master/modals/extend_session_modal/ExtendSessionModal'

export function SessionTimer({ className = undefined, disableSessionExtension = false }) {
  const adminPage = window.location.pathname?.startsWith('/admin') ? true : false
  const { sessionEnding, setSessionEnding } = useSessionTimer()
  const { doExtendSession, endSession } = useSessionTimer()

  const sessionTimeRemaining = useKeycloakSessionTime()
  const currentTime = useCurrentTime()

  return (
    <>
      {adminPage && !disableSessionExtension && (
        <ExtendSessionModal
          isOpen={sessionEnding}
          onRequestClose={() => {
            setSessionEnding(false)
          }}
          endSession={endSession}
          doExtendSession={doExtendSession}
          sessionTimeRemaining={sessionTimeRemaining}
        />
      )}

      <CurrentTime time={currentTime} />
      <LogoutTimer sessionTimeRemaining={sessionTimeRemaining} className={className} />
    </>
  )
}
