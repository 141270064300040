import { createContext, useContext, useState } from 'react'

export const AdminUserContext = createContext()

export const AdminUserProvider = ({ children }) => {
  const [tiviLink, setTiviLink] = useState('')

  return (
    <AdminUserContext.Provider
      value={{
        tiviLink,
        setTiviLink,
      }}
    >
      {children}
    </AdminUserContext.Provider>
  )
}

export const useAdminUserContext = () => {
  const PROVIDER_ERROR = 'useAdminUserContext must be used within an AdminUserProvider'
  const context = useContext(AdminUserContext)
  if (!context) {
    throw new Error(PROVIDER_ERROR)
  }
  return context
}

export default AdminUserContext
