import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import BottomBarStyles from './BottomBar.module.scss'

export const BottomBar = ({ links }) => {
  const history = useHistory()

  return (
    <div className={BottomBarStyles['container']}>
      {Object.entries(links).map(([tabkey, tabValue]) => {
        const active = window.location.pathname.includes(tabValue.path)

        return (
          <div
            key={tabkey}
            className={clsx(BottomBarStyles['tab'], active && BottomBarStyles['tab-active'])}
            onClick={() => {
              history.push(tabValue.path)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') history.push(tabValue.path)
            }}
            role="button"
            tabIndex={0}
          >
            {tabValue.icon && <tabValue.icon />}
          </div>
        )
      })}
    </div>
  )
}
