import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { PasswordValidationTxt } from '@nv/components'
import { passwordValidateFunction } from './PasswordValidateHelperFunctions'
import PasswordValidateStyles from './PasswordValidate.module.scss'

const passwordReqs = ['minLength', 'upperCase', 'lowerCase', 'digit', 'specialChar']
const possibleErrors = passwordReqs

export const PasswordValidate = ({ view, formik, result }) => {
  const { t } = useTranslation()
  const [errorList, setErrorList] = useState([])

  const MIN_LENGTH_PW = view === 'admin' ? 12 : 8

  useEffect(() => {
    passwordValidateFunction(
      formik.values.password,
      setErrorList,
      MIN_LENGTH_PW,
      errorList,
      passwordReqs
    )
    const valid = errorList.length === 0

    result(valid)
  }, [formik.values.password])

  return (
    <div className={PasswordValidateStyles['container']}>
      <span
        className={clsx(
          PasswordValidateStyles['title'],
          // if there are no errors
          errorList.length === 0
            ? PasswordValidateStyles['title--valid']
            : PasswordValidateStyles['title--invalid']
        )}
      >
        {t('auth.password.passwordReqsDesc')}
      </span>

      {possibleErrors.map((errorKey, i) => {
        return (
          <PasswordValidationTxt
            key={i}
            text={t(`auth.password.passwordReqs.${errorKey}`, { MIN_LENGTH_PW })}
            isValid={!errorList.includes(errorKey)}
          />
        )
      })}
    </div>
  )
}
