import React, { Component } from 'react'
import SearchIcon from '@nv/images/design/search-icon'
import clsx from 'clsx'
import SearchFieldStyles from './SearchField.module.scss'

interface SearchFieldProps {
  field: string
  placeholder: string
  customStyling?: React.CSSProperties
  type: string
  disabled?: boolean
  value?: string
  label?: string
  noLabel?: boolean
  isRequired?: boolean
  role: string
  tabIndex: number
  onKeyDown: (e: any) => void
  onChange: (e: any) => void
}

class SearchField extends Component<SearchFieldProps> {
  render() {
    const { field, placeholder, disabled, customStyling, value, type } = this.props

    return (
      <div className={clsx(SearchFieldStyles['container'])} style={customStyling}>
        <SearchIcon className={SearchFieldStyles['img']} />
        <input
          placeholder={placeholder}
          className={SearchFieldStyles['search']}
          type={type}
          name={field}
          autoComplete='off'
          disabled={disabled}
          onChange={this.props.onChange}
          value={value}
        />
      </div>
    )
  }
}

export default SearchField
