import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import SideBarItemStyles from './SideBarItem.module.scss'

export const SideBarItem = (props) => {
  const history = useHistory()
  const { tabValue, isCollapsed } = props
  const active = window.location.pathname.includes(tabValue.path)

  if (!tabValue.hasPermissionToView) {
    return null
  }

  const handleClick = () => (tabValue.link ? window.open(tabValue.link, '_blank') : history.push(tabValue.path))

  return (
    <button
      className={clsx(SideBarItemStyles['tab'], active && SideBarItemStyles['tab-active'])}
      onClick={handleClick}
    >
      {tabValue.icon && <tabValue.icon inactive={active} />}
      {!isCollapsed && (
        <span className={active ? SideBarItemStyles['tab-active-text'] : SideBarItemStyles['tab-inactive-text']}>
          {tabValue.tabLabel}
        </span>
      )}
    </button>
  )
}

export default SideBarItem
