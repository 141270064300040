import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { PBStep } from './PBStep'
import OnboardProgressBarStyles from './onboardProgressBar.module.scss'

export const OnboardProgressBar = (props) => {
  const { t } = useTranslation()
  const { currentStep } = props

  const steps = {
    1: t('auth.login.validations.memberVerify'),
    2: t('auth.login.validations.authMethod'),
    3: t('auth.login.validations.verification'),
    4: t('auth.password.create.title'),
  }

  return (
    <>
      {/* rendered for mobile */}
      <div className={OnboardProgressBarStyles['mobile-container']}>
        <PBStep step={currentStep} isActive={true} label={steps[currentStep]} />
      </div>

      {/* rendered for desktop */}
      <div className={clsx(OnboardProgressBarStyles['container'])}>
        {Object.entries(steps).map(([step, label], i) => {
          return (
            <PBStep
              key={i}
              step={Number(step)}
              isActive={currentStep === Number(step)}
              label={t(label)}
            />
          )
        })}
      </div>
    </>
  )
}
