import { useContext } from 'react'
import { KeycloakService } from '@nv/auth'
import { KeycloakAdminService } from '@nv/auth'
import { LoginFlowContext } from '@nv/contexts'
import { AdminVoterAuthService } from '@nv/services'
import { VoterAuthService } from '@nv/services'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import LogoutBtnStyles from './LogoutBtn.module.scss'

const LogoutButton = ({ className = undefined }) => {
  const history = useHistory()
  const LOGIN_CONTEXT = useContext(LoginFlowContext)
  const { t } = useTranslation()

  const handleLogout = async () => {
    try {
      if (KeycloakService.isLoggedIn()) {
        LOGIN_CONTEXT.setLoginFlow('Login')
        await VoterAuthService.logOut(history)
      }
      if (KeycloakAdminService.isLoggedIn()) {
        LOGIN_CONTEXT.setLoginFlow('Admin Login')
        await AdminVoterAuthService.logOut(history)
      }
    } catch (error) {
      //console.log(error)
    }
  }

  return (
    <button
      className={clsx(LogoutBtnStyles['logout'], className)}
      onClick={() => handleLogout()}
    >
      {t('main.logout')}
    </button>
  )
}

export default LogoutButton
