import { SVG } from '../SVG'

const RightArrowIcon = ({ style={}, ...props }) => (
  <SVG
    width="46"
    height="27"
    viewBox="0 0 46 27"
    xmlns="http://www.w3.org/2000/svg"
    style={{ fill: "var(--secondary-color)", ...style }}
    {...props}
  >
    <path 
      d="M44.9633 12.4354L44.9561 12.4209L44.9172 12.3602L44.9129 12.3515L44.8913 12.3182C44.8108 12.1966 44.7173 12.0838 44.6137 11.981L44.5418 11.9101L33.7667 1.07022C33.3812 0.680961 32.8677 0.466797 32.3225 0.466797C31.7774 0.466797 31.2638 0.68096 30.8769 1.06877C30.49 1.45658 30.2771 1.97173 30.2771 2.52017C30.2771 3.07005 30.49 3.58665 30.8769 3.97591L38.2531 11.395H2.04398C0.917706 11.395 0 12.3167 0 13.4512C0 14.5857 0.916268 15.5075 2.04398 15.5075H38.2545L30.8783 22.928C30.4914 23.3158 30.2785 23.831 30.2785 24.3794C30.2785 24.9293 30.4914 25.4459 30.8769 25.8351C31.2624 26.2244 31.7759 26.4386 32.3211 26.4386C32.8662 26.4386 33.3797 26.2244 33.7667 25.8351L44.6137 14.9229C44.7115 14.8274 44.8036 14.716 44.8899 14.5901L44.9143 14.5524L44.9589 14.4801L44.9633 14.47C45.1387 14.1603 45.2308 13.8087 45.2308 13.4512C45.2308 13.0938 45.1387 12.7422 44.9633 12.4325V12.4354Z" 
    />
  </SVG>
)

export default RightArrowIcon
