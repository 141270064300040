import { BackBtn } from '@nv/basic-components'
import PDFViewer from './PdfViewer'
import PdfViewerPageStyles from './PdfViewerPage.module.scss'

const PDFViewerPage = ({ pdfURL = null, onClose }) => {
  return (
    <div className={PdfViewerPageStyles['container']}>
      <BackBtn onClick={() => onClose()} />

      {pdfURL && (
        <PDFViewer pdfViewURL={pdfURL} />
      )}
    </div>
  )
}

export default PDFViewerPage
