import ThumbsUpIcon from '@nv/images/design/thumbs-up-icon'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from '../../ModalWrapper'
import ConfirmNominationModalStyles from './ConfirmNominationModal.module.scss'

export const ConfirmNominationModal = ({ modalOpen, onClose, confirm }) => {
  const { t } = useTranslation()

  const btns = [
    {
      text: t('buttons.cancel'),
      onClick: onClose,
      isCancel: true,
    },
    {
      text: t('buttons.ok'),
      onClick: confirm,
    },
  ]

  return (
    <ModalWrapper
      isOpen={modalOpen}
      onRequestClose={onClose}
      headerLabel={t('main.supportCandidate')}
      btns={btns}
    >
      <div className={ConfirmNominationModalStyles['container']}>
        <ThumbsUpIcon />
        <span className={ConfirmNominationModalStyles['title']}>
          {t('main.youAreNominating')}
        </span>
      </div>
    </ModalWrapper>
  )
}
