import { SVG } from '../SVG'

const ThumbsUpIcon = ({ style = {}, ...props }) => (
  <SVG
    width="76"
    height="76"
    viewBox="0 0 76 76"
    fill="none"
    strokeWidth="4"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
    style={{ stroke: 'var(--tertiary-color)', ...style }}
    {...props}
  >
    <path d="M9.5 30.875H23.75V61.75H9.5C8.87011 61.75 8.26602 61.4998 7.82062 61.0544C7.37522 60.609 7.125 60.0049 7.125 59.375V33.25C7.125 32.6201 7.37522 32.016 7.82062 31.5706C8.26602 31.1252 8.87011 30.875 9.5 30.875V30.875Z" />
    <path d="M23.75 30.875L35.625 7.125C38.1446 7.125 40.5609 8.12589 42.3425 9.90749C44.1241 11.6891 45.125 14.1054 45.125 16.625V23.75H63.5016C64.1751 23.7483 64.8412 23.8908 65.455 24.1679C66.0689 24.445 66.6164 24.8502 67.0607 25.3564C67.5051 25.8626 67.8359 26.458 68.0311 27.1026C68.2262 27.7472 68.2811 28.4261 68.1922 29.0938L64.6297 57.5938C64.4856 58.7379 63.9302 59.7905 63.0671 60.5552C62.204 61.32 61.0922 61.7446 59.9391 61.75H23.75" />
  </SVG>
)

export default ThumbsUpIcon
