import { setAuthAdminKc, setAuthAdminKcUser, useAuthAdminKc } from '@nv/zustand'
import Keycloak from 'keycloak-js'

const initKeycloak = (keycloakConfig) => {
  const _kc = new Keycloak(keycloakConfig)
  setAuthAdminKc({ _kc })

  const timerId = setTimeout(() => {
    setAuthAdminKc({ initialized: true })
  }, 5000)
  _kc
    .init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
    })
    .then((authenticated) => {
      // console.log(authenticated, _kc)
      if (!authenticated) {
        //console.log('user is not authenticated..!')
      } else {
        //console.log('user is authenticated.')
      }
      setAuthAdminKc({ initialized: true })
      clearTimeout(timerId)
    })
  _kc.onAuthSuccess = () => {
    //console.log("User successfully authenticated")
    setAuthAdminKcUser({ token: _kc.token, tokenParsed: _kc.tokenParsed })
  }
}

const doLogin = () => useAuthAdminKc.getState()._kc.login()
const doLogout = () => {
  setAuthAdminKcUser()
  useAuthAdminKc.getState()._kc.logout()
}
const updateToken = (timeToExpiry) => useAuthAdminKc.getState()._kc.updateToken(timeToExpiry)
const onTokenExpired = async (onSessionExpired, timeToExpiry) => {
  try {
    await useAuthAdminKc.getState()._kc.updateToken(timeToExpiry)
  } catch (error) {
    onSessionExpired()
  }
}

// move to zustand
const getToken = () => useAuthAdminKc.getState()._kc?.token
const isLoggedIn = () => !!useAuthAdminKc.getState()._kc?.token
const getUsername = () => useAuthAdminKc.getState()._kc?.tokenParsed?.preferred_username
const getTokenParsed = () => useAuthAdminKc.getState()._kc?.tokenParsed
const hasRole = (role) => useAuthAdminKc.getState()._kc?.hasRealmRole(role)
const getSessionStartTime = () => useAuthAdminKc.getState()._kc?.tokenParsed.auth_time

//admin functions
const isSuperAdmin = () => useAuthAdminKc.getState().user.realm_access?.roles?.includes('tivi-root-admin')
const isContactInfoRoleAdmin = () => useAuthAdminKc.getState().user.realm_access?.roles?.includes('tivi-contactinfo')
const isEventModuleAdmin = () => useAuthAdminKc.getState().user.realm_access?.roles?.includes('tivi-event-admin')
const isMonitoringAdmin = () => useAuthAdminKc.getState().user.realm_access?.roles?.includes('tivi-auditor')

export const KeycloakAdminService = {
  initKeycloak,
  doLogin,
  doLogout,
  getToken,
  isLoggedIn,
  updateToken,
  getUsername,
  hasRole,
  getTokenParsed,
  onTokenExpired,
  getSessionStartTime,
  isSuperAdmin,
  isContactInfoRoleAdmin,
  isEventModuleAdmin,
  isMonitoringAdmin,
}
