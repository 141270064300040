import { SVG } from '../SVG'

export const Elections = ({ inactive = false, style = {}, ...props }) => {
  return (
    <SVG
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style }}
      {...props}
    >
      <path
        d="M8.5 9.22222V2H15V9.77778M8.5 6.58333H4.13333L3 13.875M15.0889 6.58333H19.4889L21 13.875M7.53333 10.125H16.0333M3 13.875V22H21V13.875M3 13.875H21"
        fill="none"
        stroke={inactive ? 'var(--tertiary-color)' : 'var(--secondary-color-darker)'}
        strokeWidth={1}
        strokeLinecap="round"
      />
    </SVG>
  )
}
