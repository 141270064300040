import { setAuthKc, setAuthKcUser, useAuthKc } from '@nv/zustand'
import Keycloak from 'keycloak-js'

const initKeycloak = (keycloakConfig) => {
  const _kc = new Keycloak(keycloakConfig)
  setAuthKc({ _kc })

  const timerId = setTimeout(() => {
    setAuthKc({ initialized: true })
  }, 5000)
  _kc
    .init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
    })
    .then((authenticated) => {
      setAuthKc({ initialized: true })
      clearTimeout(timerId)

      if (authenticated) {
        setAuthKcUser({ token: _kc.token, tokenParsed: _kc.tokenParsed })
        console.log('User authenticated')
      } else {
        console.log('User not authenticated')
      }
    })
    .catch((error) => {
      console.error('Error during Keycloak init:', error)
    })
  _kc.onAuthSuccess = () => {
    console.log('User successfully authenticated')
    setAuthKcUser({ token: _kc.token, tokenParsed: _kc.tokenParsed })
  }
}

const doLogin = () => useAuthKc.getState()._kc.login()
const doLogout = () => {
  setAuthKcUser()
  useAuthKc.getState()._kc.logout()
}
const updateToken = (timeToExpiry) => {
  useAuthKc.getState()._kc.updateToken(timeToExpiry)
}
const onTokenExpired = async (onSessionExpired, timeToExpiry) => {
  try {
    await useAuthKc.getState()._kc.updateToken(timeToExpiry)
  } catch (error) {
    onSessionExpired()
  }
}

// move to zustand
const getToken = () => useAuthKc.getState()._kc?.token
const isLoggedIn = () => !!useAuthKc.getState()._kc?.token
const getUsername = () => useAuthKc.getState()._kc?.tokenParsed?.preferred_username
const getTokenParsed = () => useAuthKc.getState()._kc?.tokenParsed
const hasRole = (role) => useAuthKc.getState()._kc?.hasRealmRole(role)
const getSessionStartTime = () => useAuthKc.getState()._kc?.tokenParsed.auth_time

export const KeycloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  getToken,
  isLoggedIn,
  updateToken,
  getUsername,
  hasRole,
  getTokenParsed,
  onTokenExpired,
  getSessionStartTime,
}
