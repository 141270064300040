import { useTranslation } from 'react-i18next'
import LoaderSpinner from '../../../../../../basic-components/src/lib/loader-spinner/LoaderSpinner'
import { ModalWrapper } from '../../ModalWrapper'
import ReportsDlLoadingModalStyles from './ReportsDlLoadingModal.module.scss'

export const ReportsDlLoadingModal = ({
  isOpen = false,
  onRequestClose = undefined,
}) => {
  const { t } = useTranslation()

  const btns = [
    {
      text: t('buttons.ok'),
      onClick: onRequestClose,
    },
  ]

  return (
    <ModalWrapper
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      headerLabel={t('admin.reports.generateReport')}
      btns={btns}
    >
      <div className={ReportsDlLoadingModalStyles["container"]}>
        <LoaderSpinner className={ReportsDlLoadingModalStyles["icon"]} />
      </div>
    </ModalWrapper>
  )
}
