import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

const useUUID = () => {
  const [id] = useState(uuidv4)
  return id
}

export const SVG = ({ children, alt, ...props }) => {
  const id = useUUID()
  return (
    <svg {...(alt ? { 'aria-labelledby': id } : {})} {...props}>
      {alt ? <title id={id}>{alt}</title> : null}
      {children}
    </svg>
  )
}