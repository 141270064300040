import { CheckBox } from '@nv/basic-components'
import WithdrawIcon from '@nv/images/design/withdraw-icon'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { ModalWrapper } from '../../ModalWrapper'
import WithdrawCandicacyModalStyles from './WithdrawCandicacyModal.module.scss'

export const WithdrawCandicacyModal = ({ modalOpen, onClose, confirm }) => {
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      consent: false,
    },
    validationSchema: Yup.object({
      consent: Yup.boolean().oneOf([true], t('You must agree to the terms')),
    }),
    onSubmit: () => {
      confirm()
    },
  })

  const btns = [
    {
      text: t('buttons.cancel'),
      onClick: onClose,
      isCancel: true,
    },
    {
      text: t('buttons.confirm'),
      onClick: formik.handleSubmit,
    },
  ]

  return (
    <ModalWrapper
      isOpen={modalOpen}
      onRequestClose={onClose}
      headerLabel={t('main.Withdraw candidacy')}
      btns={btns}
    >
      <div className={WithdrawCandicacyModalStyles['container']}>
        <WithdrawIcon />
        <span className={WithdrawCandicacyModalStyles['title']}>
          {t('Youre about to withdraw your candidacy for [position title] This action is irreversible.')}
        </span>
        <div className={WithdrawCandicacyModalStyles['consent']}>
          <CheckBox
            checked={formik.values.consent}
            onChange={() => formik.setFieldValue('consent', !formik.values.consent)}
            formik={formik}
            field="consent"
            label={t('By selecting this option, I confirm that I have read and agree to the Terms and Conditions, and I accept all the stated terms. I understand that withdrawing my candidacy is final and cannot be undone.')}
          />
        </div>
      </div>
    </ModalWrapper>
  )
}
