import { useEffect, useState } from 'react'
import { Loader } from '@nv/basic-components'
import { AdminVoterService } from '@nv/services'
import { useTranslation } from 'react-i18next'
import StyledReactSelect from '../../../custom_dropdown/StyledReactSelect'
import { ModalWrapper } from '../../ModalWrapper'
import UserFilterModalStyles from './UserFilterModal.module.scss'

const USER_FILTER_TYPES = [
  'Group',
  'Subgroup',
  'Region',
  'Branch',
  'Classification',
  'Subclassification',
  'Employer',
  'Department',
  'Chapter',
]
const USER_TYPES = ['REG', 'RAND', 'RTIR', 'CANC']

export const UserFiltersModal = ({
  isOpen,
  appliedFilters,
  setAppliedFilters,
  onRequestClose,
}) => {
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  const [options, setOptions] = useState(null)
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState(appliedFilters || {})

  const getParentFilters = async () => {
    const optionsUserType = USER_TYPES.map((u) => ({
      label: u,
      value: u,
      accountId: u,
    }))
    let localOptions = { userType: optionsUserType }
    for (let i = 0; i < USER_FILTER_TYPES.length; i++) {
      if (i % 2 === 0) {
        const optionsFilter = await AdminVoterService.getParentFilters(
          USER_FILTER_TYPES[i],
        )
        localOptions = { ...localOptions, [USER_FILTER_TYPES[i]]: optionsFilter || [] }
      }
    }
    setOptions(localOptions)
    setLoading(false)
  }

  const getChildFilters = async (filters, filterType, parentFilterIndex) => {
    const groupedChildFilters = []
    for (const filter of filters) {
      const childFilters = await AdminVoterService.getChildFilters(filter.accountId)
      groupedChildFilters.push({
        label: filter.title[currentLanguage],
        options: childFilters,
      })
    }
    setOptions({ ...options, [USER_FILTER_TYPES[parentFilterIndex + 1]]: groupedChildFilters })
  }

  const onSelectFilter = (filter, action, filterType) => {
    setFilters({ ...filters, [`accountId${filterType}`]: filter })
    const filterIndex = USER_FILTER_TYPES.findIndex((f) => f === filterType)
    if (filterIndex % 2 === 0) {
      getChildFilters(filter, filterType, filterIndex)
      // Reset selected children if clear all parent filters or remove one parent filter
      if (
        (action.action === 'clear' || action.action === 'remove-value') &&
        filterIndex < USER_FILTER_TYPES.length - 1
      ) {
        setFilters({
          ...filters,
          [`accountId${USER_FILTER_TYPES[filterIndex + 1]}`]: [],
          [`accountId${filterType}`]: filter,
        })
      }
    }
  }

  const onSelectUserType = (filter) => {
    setFilters({ ...filters, userType: filter })
  }

  const onCancel = () => {
    setFilters({})
    getParentFilters()
    onRequestClose()
  }

  const onApplyFilters = () => {
    setAppliedFilters(filters)
    onRequestClose()
  }

  useEffect(() => {
    getParentFilters()
  }, [currentLanguage])

  const btns = [
    {
      text: t('buttons.cancel'),
      onClick: onCancel,
      isCancel: true,
    },
    {
      text: t('buttons.apply'),
      onClick: onApplyFilters,
    },
  ]

  return (
    <ModalWrapper
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      headerLabel={t('filters.filterBy')}
      btns={btns}
    >
      {
        loading ? <Loader />
          : (
            <div className={UserFilterModalStyles['container']}>
              {USER_FILTER_TYPES.map((filterType, i) => (
                <div key={i} className={UserFilterModalStyles['dropdown__container']}>
                  <StyledReactSelect
                    options={options[filterType] || []}
                    customStyles={{ width: 250 }}
                    label={t(`filters.userFilters.${filterType.toLowerCase()}`)}
                    onChange={(e, action) => onSelectFilter(e, action, filterType)}
                    value={filters[`accountId${filterType}`] || []}
                    isClearable
                    isMulti
                    placeholder={t('form.select.select')}
                  />
                </div>
              ))}
              <div className={UserFilterModalStyles['dropdown__container']}>
                <StyledReactSelect
                  options={options?.userType || []}
                  customStyles={{ width: 250 }}
                  label={t('filters.userFilters.userType')}
                  onChange={(e) => onSelectUserType(e)}
                  value={filters.userType || []}
                  isClearable
                  isMulti
                  placeholder={t('form.select.select')}
                />
              </div>
            </div>
          )}
    </ModalWrapper>
  )
}
