import { useEffect, useState } from 'react'
import { convertToLuxonWithTimeZone } from '@nv/utils'
import { DateTime } from 'luxon'

export const useCountdown = (countdownToDate) => {
  const countdownDate = convertToLuxonWithTimeZone(countdownToDate)
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })

  const getCountdown = () => {
    let now = DateTime.now()
    const timeZone = process.env.NX_PUBLIC_TIME_ZONE_DATE_STRING
    if (timeZone) {
      now = now.setZone(timeZone)
    }
    const diff = countdownDate.diff(now, ['days', 'hours', 'minutes', 'seconds', 'milliseconds']).toObject()
    setCountdown(diff)
  }

  useEffect(() => {
    const interval = setInterval(getCountdown, 1000)
    return () => clearInterval(interval)
  }, [])

  return countdown
}
