import { DateTime } from 'luxon'

/**
 * Converts a date to a Date object and adjusts to the specified time zone if provided.
 * @param {string|Date} date - The date to convert.
 * @returns {DateTime} The converted Date object.
 */
export const convertToLuxonWithTimeZone = (date) => {
  const timeZone = process.env.NX_PUBLIC_TIME_ZONE_DATE_STRING

  // Create a DateTime object from the input date
  const dt = DateTime.fromJSDate(new Date(date))

  if (timeZone) {
    // Convert the DateTime object to the specified time zone
    const zonedDateTime = dt.setZone(timeZone)

    // Convert back to a native Date object
    return zonedDateTime
  }

  return dt
}
