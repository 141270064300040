import { v4 as uuidv4 } from 'uuid'
import ToggleStyles from './Toggle.module.scss'

export const Toggle = ({
  label = undefined,
  onChange = undefined,
  checked = false,
}) => {
  const uniqueId = uuidv4()

  return (
    <div className={ToggleStyles['container']}>
      <label htmlFor={uniqueId} className={ToggleStyles['label']}>{label}</label>
      <input
        id={uniqueId}
        type="checkbox"
        className={ToggleStyles['input']}
        onChange={onChange}
        checked={checked}
      />
      <label htmlFor={uniqueId} className={ToggleStyles['slider']}>
        <span className={ToggleStyles['visuallyHidden']}>{ }</span>
      </label>
    </div>
  )
}
