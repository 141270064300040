import { useTranslation } from 'react-i18next'
import LogoutTimerStyles from './LogoutTimer.module.scss'

const LogoutTimer = ({ sessionTimeRemaining }) => {
  const { t } = useTranslation()

  if (!sessionTimeRemaining || sessionTimeRemaining.includes('NaN')) {
    return null
  }

  const minutes =
    sessionTimeRemaining.substring(0, 1) !== '0'
      ? sessionTimeRemaining.substring(0, 2)
      : sessionTimeRemaining.substring(1, 2)

  const minText = sessionTimeRemaining.substring(2, 6)
  const seconds = sessionTimeRemaining.substring(6, 9)
  const secText = sessionTimeRemaining.substring(9, 13)

  return (
    <span className={LogoutTimerStyles['container']}>
      <span className={LogoutTimerStyles['title']}>{t('main.sessionTime')}</span>

      <div className={LogoutTimerStyles['time']}>
        <span className={LogoutTimerStyles['time__minutes-value']}>{minutes}</span>
        <span className={LogoutTimerStyles['time__minutes-label']}>{minText}</span>
        <span className={LogoutTimerStyles['time__seconds-value']}>{seconds}</span>
        <span className={LogoutTimerStyles['time__seconds-label']}>{secText}</span>
      </div>
    </span>
  )
}

export default LogoutTimer
