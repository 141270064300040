import { SVG } from '../SVG'

const DownloadFileIcon = ({ color='var(--primary-color)', style = {}, ...props }) => (
  <SVG
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
    style={{ stroke: color, ...style }}
    {...props}
  >
    <path d="M25 28H7C6.73478 28 6.48043 27.8946 6.29289 27.7071C6.10536 27.5196 6 27.2652 6 27V5C6 4.73478 6.10536 4.48043 6.29289 4.29289C6.48043 4.10536 6.73478 4 7 4H19L26 11V27C26 27.2652 25.8946 27.5196 25.7071 27.7071C25.5196 27.8946 25.2652 28 25 28Z" />
    <path d="M19 4V11H26" />
    <path d="M12.5 19.5L16 23L19.5 19.5" />
    <path d="M16 15V23" />
  </SVG>
)

export default DownloadFileIcon
