import clsx from 'clsx'
import LoaderStyles from './Loader.module.scss'

export const Loader = () => {
  return (
    <div className={LoaderStyles['container']}>
      <div className={LoaderStyles['loading']}>
        <div className={clsx(LoaderStyles['square'], LoaderStyles['square--1'])}></div>
        <div className={clsx(LoaderStyles['square'], LoaderStyles['square--2'])}></div>
        <div className={clsx(LoaderStyles['square'], LoaderStyles['square--3'])}></div>
        <div className={clsx(LoaderStyles['square'], LoaderStyles['square--4'])}></div>
        <div className={clsx(LoaderStyles['square'], LoaderStyles['square--5'])}></div>
      </div>
    </div>
  )
}
