import { SVG } from '../SVG'

const ThumbsUpXIcon = ({ style = {}, ...props }) => (
  <SVG
    width="74"
    height="83"
    viewBox="0 0 74 83"
    fill="none"
    strokeWidth="4"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
    style={{ stroke: 'var(--tertiary-color)', ...style }}
    {...props}
  >
    <path d="M51.3667 65H24.2267C21.85 65 19.9333 63.0655 19.9333 60.6667V26.0774C19.9333 24.1429 20.6233 22.2083 21.7733 20.6607L37.49 0.851191C37.8733 0.309524 38.4867 0 39.1767 0C40.8633 0 42.4733 0.773809 43.5467 2.0119C44.6967 3.25 45.2333 5.02976 45.08 6.73214L43.8533 19.3452C43.7 20.5833 44.6967 21.6667 46 21.6667H62.6367C66.24 21.6667 69.0767 24.6071 69 28.244C69 32.8095 68.0033 37.375 66.0867 41.4762L57.1167 61.2083C56.12 63.5298 53.82 65 51.3667 65ZM10.9633 65H4.37C1.99333 65 0 63.0655 0 60.6667V26C0 23.6012 1.99333 21.6667 4.37 21.6667H10.9633C13.4167 21.6667 15.3333 23.6012 15.3333 26V60.6667C15.3333 63.0655 13.34 65 10.9633 65Z" fill="#1162A5"/>
    <circle cx="56" cy="65" r="16.5" fill="var(--color-white)" stroke="var(--tertiary-color)" />
    <path d="M50 59L62 71" stroke="var(--warning-color)" />
    <path d="M62 59L50 71" stroke="var(--warning-color)" />
  </SVG>
)

export default ThumbsUpXIcon
