import gif from './Spinning_neuvote.gif'

const LoaderSpinner = ({ alt = 'GIF description', style = {}, ...props }) => {
  return (
    <img
      src={gif}
      alt={alt}
      style={{ ...style, height: 'auto' }}
      {...props}
    />
  )
}

export default LoaderSpinner
