import DocCard from '../help_docs/DocCard'
import NoAttachmentsMessage from './no_attachments_message/NoAttachmentsMessage'
import AttachmentsStyles from './Attachments.module.scss'

export const Attachments = ({ listOfDocs, getAttachments, viewOnly }) => {
  const eventHasAttachments = listOfDocs.length > 0

  return (
    <div className={AttachmentsStyles['container']}>
      {eventHasAttachments ? (
        listOfDocs.map((doc, index) => (
          <DocCard
            doc={doc}
            index={index}
            key={doc.id}
            getDocs={getAttachments}
            viewOnly={viewOnly}
          />
        ))
      ) : (
        <NoAttachmentsMessage />
      )}
    </div>
  )
}
