import ProgressBarStyles from './ProgressBar.module.scss'

export const ProgressBar = ({ total, processed }: { total: number; processed: number }) => {
  const percentage = total > 0 ? Math.min((processed / total) * 100, 100) : 0

  return (
    <div className={ProgressBarStyles["progress"]}>
      <div
        className={ProgressBarStyles["progress__bar"]}
        style={{ width: `${percentage}%` }}
        aria-valuenow={percentage}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        <span className={ProgressBarStyles["progress__percentage"]}>{Math.round(percentage)}%</span>
      </div>
    </div>
  )
}
