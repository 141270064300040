import ShieldCheckMark from '@nv/images/design/shield-check-mark'
import HamiltonLogo from '@nv/images/logos/hamilton/hamilton-logo'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from '../../ModalWrapper'
import SecurityRegistrationModalStyles from './SecurityRegistrationModal.module.scss'

export const SecurityRegistrationModal = (props) => {
  const { t } = useTranslation()
  const { isOpen } = props

  const btns = [
    {
      text: t('buttons.ok'),
      onClick: props.onRequestClose,
    },
  ]

  return (
    <ModalWrapper
      isOpen={isOpen}
      onRequestClose={props.onRequestClose}
      headerLabel={<HamiltonLogo />}
      btns={btns}
    >
      <div className={SecurityRegistrationModalStyles['container']}>
        <span className={SecurityRegistrationModalStyles['title']}><ShieldCheckMark style={{ width: "200px", height: "100px" }} /></span>
        <span className={SecurityRegistrationModalStyles['title']}>{t('The security of the voter registration and overall voting process under the controlled by Ignace willingness engagement team')}</span>
      </div>
    </ModalWrapper>
  )
}
