import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

const initialState = {
  user: {},
  _kc: null,
}
const storeName = 'auth-kc'

export const useAuthKc = create(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        set,
      }),
      {
        name: storeName,
        storage: createJSONStorage(() => sessionStorage),
        partialize: (state) =>
          Object.fromEntries(Object.entries(state).filter(([key]) => !['_kc', 'initialized', 'user', 'token'].includes(key))),
        // onRehydrateStorage: () => (state) => {
        //   if (state?.user?.tokenParsed?.exp && state.user.tokenParsed.exp <= Date.now() / 1000) {
        //     // Remove the jwt if it's expired
        //     state.set({ user: {} })
        //   }
        // },
      },
    ),
    { store: storeName, name: 'app' },
  ),
)

export const setAuthKc = (args) => {
  useAuthKc.setState(args, false, {
    type: 'setAuthKc',
  })
}

export const clearAuthKcStore = () => {
  useAuthKc.setState({ ...initialState }, true, {
    type: 'clearAuthKcStore',
  })
}

export const setAuthKcUser = (user) => {
  if (user) {
    useAuthKc.setState(
      {
        user: {
          ...useAuthKc.getState().user,
          ...user,
        },
      },
      false,
      { type: 'setAuthKcUser' },
    )
  } else {
    useAuthKc.setState({ user: {} }, false, {
      type: 'setAuthKcUser',
    })
  }
}
