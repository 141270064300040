import { SVG } from '../SVG'

const XCalanderIcon = ({ style={}, ...props }) => (
  <SVG
    width="70"
    height="70"
    viewBox="0 0 70 70"
    xmlns="http://www.w3.org/2000/svg"
    style={{"fill": "none", "stroke": "var(--tertiary-color)", ...style}}
    {...props}
  >
    <path 
      d="M56.6447 10.9375H13.0713C11.868 10.9375 10.8926 11.9169 10.8926 13.125V56.875C10.8926 58.0831 11.868 59.0625 13.0713 59.0625H56.6447C57.848 59.0625 58.8234 58.0831 58.8234 56.875V13.125C58.8234 11.9169 57.848 10.9375 56.6447 10.9375Z" 
      strokeWidth="3" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
    <path 
      d="M47.9316 6.5625V15.3125" 
      strokeWidth="3" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
    <path 
      d="M21.7871 6.5625V15.3125" 
      strokeWidth="3" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
    <path 
      d="M10.8926 24.0625H58.8234" 
      strokeWidth="3" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
    <path 
      d="M41.3943 35L28.3223 48.125" 
      strokeWidth="3" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
    <path 
      d="M41.3943 48.125L28.3223 35" 
      strokeWidth="3" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
  </SVG>
)

export default XCalanderIcon
