import axios from 'axios'

const { NX_PUBLIC_PUBLIC_URL } = process.env

const VoterRegistrationService = {
  checkEligible: async (data, recaptchaToken) => {
    try {
      await axios.post(`${NX_PUBLIC_PUBLIC_URL}/registration/checkeligible`, {
        ...data,
        recaptchaToken,
      })
      return { success: true }
    } catch (error) {
      return { error: error.response?.data?.error }
    }
  },
  sendSMSCode: async (phone, recaptchaToken) => {
    try {
      await axios.post(`${NX_PUBLIC_PUBLIC_URL}/registration/mfa/initiate/sms`, {
        phone,
        recaptchaToken,
      })
      return { success: true }
    } catch (error) {
      //console.log(error)
      return { error: error.response?.data?.error }
    }
  },
  sendEmailCode: async (email, recaptchaToken) => {
    try {
      await axios.post(`${NX_PUBLIC_PUBLIC_URL}/registration/mfa/initiate/email`, { email, recaptchaToken })
      return { success: true }
    } catch (error) {
      //console.log(error)
      return { error: error.response?.data?.error }
    }
  },
  // full registration call
  verifySMSCode: async (input) => {
    try {
      const result = await axios.post(`${NX_PUBLIC_PUBLIC_URL}/registration/mfa/verify/sms`, input)
      const data = result.data?.data
      return {
        success: true,
        data,
        userData: data,
        applicationCode: data?.applicationCode,
        status: data?.status,
      }
    } catch (error) {
      //console.log(error)
      return { error: error.response?.data?.error }
    }
  },
  verifyEmailCode: async (input) => {
    try {
      const result = await axios.post(`${NX_PUBLIC_PUBLIC_URL}/registration/mfa/verify/email`, input)
      const data = result.data?.data
      return {
        success: true,
        data,
        userData: data,
        applicationCode: data?.applicationCode,
        status: data?.status,
      }
    } catch (error) {
      //console.log(error)
      return { error: error.response?.data?.error }
    }
  },

  answerSurvey: async (payload) => {
    try {
      await axios.put(`${NX_PUBLIC_PUBLIC_URL}/registration/${payload.id}`, payload)
      return { success: true }
    } catch (error) {
      //console.log(error)
      return { error: error.response?.data?.error }
    }
  },
  verifyStatus: async ({ confirmCode }) => {
    try {
      const result = await axios.get(`${NX_PUBLIC_PUBLIC_URL}/application-status/${confirmCode}`)

      return result
    } catch (error) {
      //console.log(error)
    }
  },
}

export default VoterRegistrationService
