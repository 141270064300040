import { SVG } from '../SVG'

const ViewIcon = ({ color='var(--primary-color)', style = {}, ...props }) => (
  <SVG
    width="32" 
    height="32" 
    viewBox="0 0 32 32" 
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ stroke: color, ...style }}
    {...props}
  >
    <path d="M16 7C6 7 2 16 2 16C2 16 6 25 16 25C26 25 30 16 30 16C30 16 26 7 16 7Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </SVG>
)

export default ViewIcon
