import { SVG } from '../SVG'

const LanguageIcon = ({ style={}, ...props }) => (
  <SVG
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{"fill": "var(--primary-color)", ...style}}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.56 8.75097C14.65 9.10097 14.79 9.44097 14.97 9.75097C15.12 10.011 15.3 10.251 15.5 10.471C15.69 10.261 15.86 10.021 15.99 9.76097C16.16 9.45097 16.29 9.11097 16.36 8.76097L14.56 8.75097ZM19 2.00097H9.36L9 0.700974C8.86 0.270974 8.45 -0.0190265 8 0.000973525H1C0.45 0.000973525 0 0.450974 0 1.00097V17.001C0 17.551 0.45 18.001 1 18.001H11.44L11.8 19.261C11.92 19.711 12.34 20.021 12.8 20.001H19C19.55 20.001 20 19.551 20 19.001V3.00097C20 2.45097 19.55 2.00097 19 2.00097ZM2 16.001V2.00097H7L10.89 16.001H2ZM18 13.001C17.12 12.751 16.29 12.321 15.57 11.751C14.8 12.331 13.93 12.761 13 13.001C12.89 12.641 12.71 12.301 12.48 12.001C13.31 11.831 14.1 11.491 14.79 11.001C14.49 10.701 14.23 10.361 14 10.001C13.79 9.62097 13.63 9.22097 13.52 8.80097H12.56V7.80097C12.85 7.82097 13.13 7.82097 13.42 7.80097H14.9V7.43097C14.92 7.29097 14.92 7.14097 14.9 7.00097H16C15.99 7.14097 15.99 7.29097 16 7.43097V7.84097H17.58C17.87 7.86097 18.15 7.86097 18.44 7.84097V8.84097H17.4C17.32 9.24097 17.18 9.63097 17 10.001C16.81 10.381 16.57 10.741 16.29 11.061C16.95 11.531 17.7 11.851 18.5 12.001C18.28 12.301 18.11 12.641 18 13.001ZM5.28 7.00097L3 13.001H4.6L5 11.881H7L7.39 13.001H9L6.7 7.00097H5.28ZM5.28 10.761L6 8.68097L6.67 10.761H5.28Z"
    />
  </SVG>
)

export default LanguageIcon
