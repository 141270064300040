import { useEffect, useState } from 'react'
import { TextField } from '@nv/basic-components'
import WarningIcon from '@nv/images/design/warning-icon'
import { AdminVoterService } from '@nv/services'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { ModalWrapper } from '../../ModalWrapper'
import EditUserModalStyles from './EditUserModal.module.scss'

const initialValues = {
  email: '',
  phone: '',
}

export const EditUserModal = (props) => {
  const { t } = useTranslation()
  const { userToEdit, modalView, isOpen, onRequestClose } = props
  const [eventId, setEventId] = useState('')
  const [roleErr, setRoleErr] = useState(false)
  const [updateErr, setUpdateErr] = useState(false)
  const [loading, setLoading] = useState(false)

  const schema = Yup.object().shape({
    email: Yup.string().required(t('form.validations.email')),
    phone: modalView === 'Edit' ? Yup.string().required(t('form.validations.phone')) : null,
  })

  const formik = useFormik({
    initialValues,
    validationSchema: schema,

    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      let response
      const { voterId, keycloakId } = userToEdit
      if (modalView === 'Reset password') {
        response = await AdminVoterService.resetVoterPassword({ keycloakId })
      } else {
        response = await AdminVoterService.updateVoter({
          voterId,
          email: values.email,
          phone: values.phone,
          updateType: modalView === 'Edit' ? 'edit' : 'email',
        })
      }
      setLoading(false)

      if (response.success) {
        props.onRequestClose()
      } else {
        setUpdateErr(true)
      }
      resetComponent()
    },
  })

  const resetComponent = async () => {
    formik.setValues({
      email: userToEdit.email,
      phone: userToEdit.phone,
    })
    setEventId('')
    setRoleErr(false)
    setUpdateErr(false)
    setLoading(false)
  }

  useEffect(() => {
    resetComponent()
  }, [props.isOpen])

  useEffect(() => {
    if (roleErr && eventId) {
      setRoleErr(false)
    }
    setUpdateErr(false)
  }, [formik.values.email, formik.values.phone, eventId])

  if (userToEdit.votingChannel === 'inPerson') {
    return (
      <ModalWrapper
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        headerLabel={t('admin.electorsDetails.editForbid')}
        btns={[
          {
            text: t('buttons.ok'),
            onClick: onRequestClose,
            className: EditUserModalStyles['primary-btn'],
          },
        ]}
      >
        <WarningIcon color={'var(--warning-color)'} className={EditUserModalStyles['error__icon']} />
        <span className={EditUserModalStyles['error__text']}>
          {t('admin.electorsDetails.editForbid')}
        </span>
      </ModalWrapper>
    )
  }

  const btns = [
    {
      text: t('buttons.cancel'),
      onClick: onRequestClose,
      isCancel: true,
    },
    {
      text:
        modalView === 'Edit'
          ? t('admin.users.actions.edit')
          : modalView === 'Reset password'
            ? t('admin.users.actions.resetPassword')
            : t('admin.users.actions.emailAccess'),
      onClick: () => formik.submitForm(),
      disabled: loading,
    },
  ]

  return (
    <ModalWrapper
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      headerLabel={
        modalView === 'Edit'
          ? t('admin.electorsDetails.edit', { name: userToEdit.username })
          : t('admin.electorsDetails.resendEmail', { name: userToEdit.username })
      }
      btns={btns}
    >
      <div className={EditUserModalStyles['container']}>
        <TextField
          formik={formik}
          type='email'
          field='email'
          placeholder={t('form.labels.email')}
          label={t('form.labels.email')}
          isRequired={true}
          disabled={modalView !== 'Edit'}
        />
        <TextField
          formik={formik}
          type='text'
          field='phone'
          placeholder={t('form.labels.phone')}
          label={t('form.labels.phone')}
          isRequired={modalView === 'Edit'}
          disabled={modalView !== 'Edit'}
        />

        {updateErr && (
          <span className={EditUserModalStyles['error__text']} role='alert'>
            {t('admin.electorsDetails.editError')}
          </span>
        )}
      </div>
    </ModalWrapper>
  )
}
