import { useTranslation } from 'react-i18next'
import WarningIcon from '@nv/images/design/warning-icon'
import { ModalWrapper } from '../../ModalWrapper'
import DeleteFileModalStyles from './DeleteFileModal.module.scss'

export const DeleteFileModal = ({ modalOpen, onClose, onDelete }) => {
  const { t } = useTranslation()

  const btns = [
    {
      text: t('buttons.cancel'),
      onClick: onClose,
      isCancel: true,
    },
    {
      text: t('buttons.delete'),
      onClick: onDelete,
    },
  ]

  return (
    <ModalWrapper
      isOpen={modalOpen}
      onRequestClose={onClose}
      headerLabel={t('buttons.deleteFile')}
      btns={btns}
    >
      <div className={DeleteFileModalStyles['container']}>
        <WarningIcon
          className={DeleteFileModalStyles['warning-icon']}
          color={'var(--warning-color)'}
        />
        <span className={DeleteFileModalStyles['title']}>
          {t('buttons.deleteFile')}
        </span>
      </div>
    </ModalWrapper>
  )
}
