import { ReactElement } from 'react'
import { STATE_STATUSES } from '@nv/utils'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import EventStateStatusStyles from './EventStateStatus.module.scss'

interface EventStateStatusProps {
  eventStatus: string
}

export const EventStateStatus = ({ eventStatus }: EventStateStatusProps): ReactElement => {
  const { t } = useTranslation()

  const getColorClassTag = ({ eventStatus }) => {
    if (eventStatus === STATE_STATUSES.active || eventStatus === STATE_STATUSES.nominationStarted) {
      return '--success'
    } else if (
      eventStatus === STATE_STATUSES.upcoming ||
      eventStatus === STATE_STATUSES.nominationUpcoming
    ) {
      return '--warning'
    } else {
      return '--error'
    }
  }

  return (
    <span
      className={clsx(
        EventStateStatusStyles['text'],
        EventStateStatusStyles[`text${getColorClassTag({ eventStatus })}`]
      )}
    >
      {t(`events.statusLabel.${eventStatus}`)}
    </span>
  )
}
