import { useEffect, useState } from 'react'
import { io } from 'socket.io-client'

export const useSocket = (url) => {
  const [updates, setUpdates] = useState([])
  const [data, setData] = useState(null)

  useEffect(() => {
    const socket = io(url)

    socket.on('update', (data) => {
      //console.log('Received change:', data)
      setUpdates((prevUpdates) => [...prevUpdates, data])
    })

    socket.on("data", (data) => {
      //console.log("received data", data)
      setData(data)
    })

    return () => {
      socket.disconnect()
    }
  }, [url])

  return { updates, data }
}

export default useSocket
