import { useEffect, useRef, useState } from 'react'
import { DateTime } from 'luxon'

// check if the event state has changed to started
// or if the event end date has passed
// show & hide vote now button accordingly
// returns boolean of event open
export const useCheckVotingOpenStateChange = ({ event, fetchEvent = null, closeDate }) => {
  const [loadingEvent, setLoadingEvent] = useState(true)
  const [votingOpen, setVotingOpen] = useState(false)
  const eventRef = useRef(event)
  eventRef.current = event

  // check if event state has changed to started at scheduled event open time
  const checkVoteStarted = async () => {
    if (!fetchEvent) {
      return
    }
    if (eventRef.current.state === 'finalized') {
      const dateTime = DateTime.fromISO(eventRef.current.openDate)
      const diff = dateTime.diffNow('seconds').get('seconds')
      // 9 seconds seems to be how long it takes for the api to update the event state
      if (!votingOpen && diff <= -9 && !loadingEvent) {
        setLoadingEvent(true)
        await fetchEvent()
        await new Promise((resolve) => setTimeout(resolve, 3000))
        setLoadingEvent(false)
      }
    }
  }

  const checkEventState = () => {
    const dateTime = DateTime.fromISO(closeDate)
    const totalSecsLeftInEvent = dateTime.diffNow('seconds').get('seconds')
    // event has ended, voting is now closed,
    // event state may not be updated yet, but vote now button should be hidden
    if (totalSecsLeftInEvent < 0 && votingOpen) {
      setVotingOpen(false)
      // the vote now button is not visible and the event has started and their is time left in the event
      // show the vote now button, set voting to open
    } else if (eventRef.current.state === 'started' && !votingOpen && totalSecsLeftInEvent > 0) {
      setVotingOpen(true)
      // if the event state is not started, set voting to not open, hide vote now button
    } else if (eventRef.current.state !== 'started' && votingOpen) {
      setVotingOpen(false)
    }
    checkVoteStarted()
  }

  useEffect(() => {
    const interval = setInterval(checkEventState, 1000)
    return () => clearInterval(interval)
  }, [loadingEvent, votingOpen])

  return { votingOpen }
}
