import XIcon from '@nv/images/design/x-icon'
import ModalHeaderStyles from './ModalHeader.module.scss'

export const ModalHeader = ({
  label = '',
  onClose,
  customStyles = {}
}) => {
  return (
    <div className={ModalHeaderStyles['container']} style={customStyles}>
      <span className={ModalHeaderStyles['label']}>{label}</span>
      <XIcon className={ModalHeaderStyles['button']} onClick={onClose} />
    </div>
  )
}
