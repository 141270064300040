import { SVG } from '../../SVG'

const QueenslandLogo = ({ style={}, ...props }) => (
  <SVG
    width="145"
    height="45"
    viewBox="0 0 165 55"
    fill="none"
    xmlns='http://www.w3.org/2000/svg'
    style={{ "fill": "var(--tertiary-color)", ...style }}
    {...props}
  >
    <path d="M69.8973 18.607L68.2422 17.0995C67.4728 17.5424 66.5044 17.7884 65.4062 17.7884C61.7829 17.7884 59.6537 15.0843 59.6537 11.7071C59.6537 8.3298 61.7852 5.52734 65.4062 5.52734C69.0273 5.52734 71.1588 8.23139 71.1588 11.7071C71.1588 13.3957 70.6175 14.9032 69.6334 16.017L71.0917 17.3455L69.8951 18.607H69.8973ZM66.5379 13.181L67.8665 14.3932C68.2937 13.8028 68.5867 12.9171 68.5867 11.7048C68.5867 9.65611 67.7189 7.6879 65.4062 7.6879C63.0936 7.6879 62.2258 9.65387 62.2258 11.7048C62.2258 13.7558 63.0936 15.6234 65.4062 15.6234C65.7842 15.6234 66.2584 15.6234 66.4395 15.4601L65.3414 14.4603L66.5379 13.181Z" fill="#003865"/>
    <path d="M80.6173 17.508H78.4053V16.327H78.3561C77.7657 17.2776 76.7503 17.7361 75.7661 17.7361C73.2902 17.7361 72.6685 16.3427 72.6685 14.2448V9.03125H74.9968V13.8176C74.9968 15.211 75.4061 15.8999 76.4886 15.8999C77.75 15.8999 78.2913 15.1953 78.2913 13.4731V9.03125H80.6196V17.5057L80.6173 17.508Z" fill="#003865"/>
    <path d="M84.3055 13.837C84.3703 15.3132 85.0928 15.9842 86.3877 15.9842C87.3226 15.9842 88.0764 15.4116 88.224 14.886H90.2727C89.6174 16.8855 88.224 17.7377 86.305 17.7377C83.6323 17.7377 81.9772 15.9014 81.9772 13.2801C81.9772 10.6588 83.7307 8.80469 86.305 8.80469C89.1902 8.80469 90.5836 11.2314 90.4203 13.837H84.3055ZM88.092 12.3609C87.8796 11.18 87.3719 10.5582 86.2401 10.5582C84.764 10.5582 84.339 11.7056 84.3055 12.3609H88.092Z" fill="#003865"/>
    <path d="M93.7149 13.837C93.7798 15.3132 94.5022 15.9842 95.7972 15.9842C96.7321 15.9842 97.4858 15.4116 97.6334 14.886H99.6821C99.0268 16.8855 97.6334 17.7377 95.7144 17.7377C93.0417 17.7377 91.3866 15.9014 91.3866 13.2801C91.3866 10.6588 93.1401 8.80469 95.7144 8.80469C98.5996 8.80469 99.993 11.2314 99.8275 13.837H93.7127H93.7149ZM97.5015 12.3609C97.289 11.18 96.7813 10.5582 95.6496 10.5582C94.1734 10.5582 93.7484 11.7056 93.7149 12.3609H97.5015Z" fill="#003865"/>
    <path d="M101.207 9.03282H103.419V10.2137H103.469C104.059 9.26319 105.075 8.80469 106.059 8.80469C108.535 8.80469 109.156 10.1981 109.156 12.296V17.5095H106.828V12.7232C106.828 11.3298 106.419 10.6409 105.336 10.6409C104.075 10.6409 103.534 11.3455 103.534 13.0676V17.5095H101.205V9.03506L101.207 9.03282Z" fill="#003865"/>
    <path d="M112.73 14.754C112.746 15.7695 113.598 16.181 114.517 16.181C115.188 16.181 116.043 15.9193 116.043 15.0985C116.043 14.394 115.077 14.1479 113.404 13.7878C112.059 13.4926 110.715 13.0184 110.715 11.5266C110.715 9.36384 112.583 8.80469 114.403 8.80469C116.224 8.80469 117.96 9.4287 118.141 11.5087H115.929C115.864 10.6074 115.175 10.3614 114.339 10.3614C113.815 10.3614 113.044 10.4598 113.044 11.1486C113.044 11.9851 114.354 12.0992 115.683 12.4101C117.043 12.721 118.371 13.213 118.371 14.7876C118.371 17.0175 116.436 17.7377 114.502 17.7377C112.567 17.7377 110.617 16.9996 110.518 14.754H112.73Z" fill="#003865"/>
    <path d="M119.794 5.80469H122.122V17.5088H119.794V5.80469Z" fill="#003865"/>
    <path d="M123.86 11.6407C123.992 9.46001 125.942 8.80469 127.843 8.80469C129.532 8.80469 131.565 9.18267 131.565 11.2135V15.6218C131.565 16.3912 131.648 17.1629 131.86 17.5073H129.5C129.418 17.2456 129.353 16.966 129.337 16.6887C128.599 17.4581 127.519 17.7377 126.486 17.7377C124.88 17.7377 123.6 16.9347 123.6 15.1969C123.6 13.2801 125.043 12.8194 126.486 12.6226C127.913 12.4101 129.239 12.4593 129.239 11.5087C129.239 10.509 128.55 10.3614 127.731 10.3614C126.846 10.3614 126.273 10.7215 126.19 11.6407H123.862H123.86ZM129.237 13.3606C128.843 13.7051 128.024 13.7207 127.302 13.8527C126.582 14.0003 125.924 14.2463 125.924 15.0985C125.924 15.9506 126.595 16.181 127.351 16.181C129.169 16.181 129.237 14.7384 129.237 14.2307V13.3629V13.3606Z" fill="#003865"/>
    <path d="M133.368 9.03282H135.58V10.2137H135.629C136.219 9.26319 137.235 8.80469 138.219 8.80469C140.695 8.80469 141.316 10.1981 141.316 12.296V17.5095H138.988V12.7232C138.988 11.3298 138.579 10.6409 137.496 10.6409C136.235 10.6409 135.694 11.3455 135.694 13.0676V17.5095H133.365V9.03506L133.368 9.03282Z" fill="#003865"/>
    <path d="M149.136 16.4263H149.102C148.561 17.3433 147.61 17.737 146.528 17.737C143.971 17.737 142.725 15.5406 142.725 13.1967C142.725 10.8527 143.987 8.80397 146.478 8.80397C147.478 8.80397 148.462 9.23116 148.986 10.0654H149.019V5.80469H151.348V17.5088H149.136V16.4263ZM147.102 10.5575C145.577 10.5575 145.054 11.8681 145.054 13.2615C145.054 14.6549 145.66 15.9835 147.102 15.9835C148.644 15.9835 149.102 14.6393 149.102 13.2459C149.102 11.8525 148.61 10.5575 147.102 10.5575Z" fill="#003865"/>
    <path d="M70.0941 29.3756C70.0941 32.5381 68.2266 34.0791 65.0953 34.0791C61.9641 34.0791 60.1122 32.5538 60.1122 29.3756V22.0977H62.6865V29.3756C62.6865 30.6549 63.0153 31.9163 65.0953 31.9163C66.9316 31.9163 67.522 31.1134 67.522 29.3756V22.0977H70.0964V29.3756H70.0941Z" fill="#003865"/>
    <path d="M72.0623 25.3297H74.2743V26.5106H74.3235C74.914 25.5601 75.9294 25.1016 76.9135 25.1016C79.3894 25.1016 80.0112 26.495 80.0112 28.5929V33.8064H77.6829V29.0201C77.6829 27.6267 77.2736 26.9378 76.1911 26.9378C74.9296 26.9378 74.3884 27.6423 74.3884 29.3645V33.8064H72.0601V25.3319L72.0623 25.3297Z" fill="#003865"/>
    <path d="M84.1757 24.0183H81.8474V22.1016H84.1757V24.0183ZM81.8474 25.329H84.1757V33.8035H81.8474V25.329Z" fill="#003865"/>
    <path d="M90.7 33.8026H88.11L85.2091 25.3281H87.6515L89.4385 31.1142H89.4721L91.2591 25.3281H93.5695L90.7 33.8026Z" fill="#003865"/>
    <path d="M96.4524 30.13C96.5173 31.6062 97.2397 32.2771 98.5347 32.2771C99.4696 32.2771 100.223 31.7046 100.371 31.179H102.42C101.764 33.1785 100.371 34.0306 98.452 34.0306C95.7792 34.0306 94.1241 32.1944 94.1241 29.5731C94.1241 26.9518 95.8776 25.0977 98.452 25.0977C101.337 25.0977 102.731 27.5244 102.567 30.13H96.4524ZM100.239 28.6561C100.027 27.4752 99.5188 26.8534 98.3871 26.8534C96.9109 26.8534 96.486 28.0008 96.4524 28.6561H100.239Z" fill="#003865"/>
    <path d="M103.943 25.3297H106.155V26.9043H106.188C106.616 25.8396 107.763 25.1016 108.892 25.1016C109.056 25.1016 109.252 25.1351 109.4 25.1843V27.3471C109.188 27.2979 108.843 27.2644 108.564 27.2644C106.859 27.2644 106.269 28.4945 106.269 29.9863V33.8064H103.941V25.3319L103.943 25.3297Z" fill="#003865"/>
    <path d="M112.122 31.0509C112.138 32.0663 112.99 32.4779 113.909 32.4779C114.58 32.4779 115.435 32.2162 115.435 31.3954C115.435 30.6908 114.468 30.4448 112.795 30.0847C111.451 29.7895 110.107 29.3153 110.107 27.8235C110.107 25.6607 111.975 25.1016 113.795 25.1016C115.616 25.1016 117.351 25.7256 117.532 27.8056H115.32C115.256 26.9043 114.567 26.6582 113.73 26.6582C113.207 26.6582 112.435 26.7566 112.435 27.4455C112.435 28.282 113.746 28.3961 115.074 28.707C116.434 29.0179 117.763 29.5099 117.763 31.0845C117.763 33.3144 115.828 34.0345 113.894 34.0345C111.959 34.0345 110.009 33.2965 109.91 31.0509H112.122Z" fill="#003865"/>
    <path d="M121.516 24.0183H119.188V22.1016H121.516V24.0183ZM119.188 25.329H121.516V33.8035H119.188V25.329Z" fill="#003865"/>
    <path d="M126.302 25.3276H128.006V26.8843H126.302V31.0801C126.302 31.8674 126.499 32.0642 127.286 32.0642C127.532 32.0642 127.76 32.0486 128.006 31.9994V33.8177C127.613 33.8826 127.105 33.9005 126.647 33.9005C125.22 33.9005 123.974 33.5717 123.974 31.8853V26.8865H122.565V25.3298H123.974V22.7891H126.302V25.3298V25.3276Z" fill="#003865"/>
    <path d="M133.3 34.8672C132.793 36.2271 131.99 36.784 130.384 36.784C129.91 36.784 129.433 36.7504 128.957 36.7012V34.7845C129.4 34.818 129.858 34.8829 130.317 34.8672C131.12 34.7845 131.381 33.9502 131.12 33.277L128.136 25.3281H130.628L132.544 31.1299H132.578L134.43 25.3281H136.839L133.298 34.8672H133.3Z" fill="#003865"/>
    <path d="M64.0307 41.3945C66.7035 41.3945 68.4234 43.1659 68.4234 45.87C68.4234 48.574 66.7012 50.3275 64.0307 50.3275C61.3602 50.3275 59.6537 48.5561 59.6537 45.87C59.6537 43.1838 61.3736 41.3945 64.0307 41.3945ZM64.0307 48.574C65.6209 48.574 66.0951 47.2142 66.0951 45.87C66.0951 44.5258 65.6209 43.148 64.0307 43.148C62.4405 43.148 61.982 44.5079 61.982 45.87C61.982 47.2321 62.4584 48.574 64.0307 48.574Z" fill="#003865"/>
    <path d="M70.4386 43.1809H69.0452V41.6242H70.4386V40.9689C70.4386 39.477 71.3735 38.3945 73.2746 38.3945C73.6839 38.3945 74.1111 38.4437 74.5047 38.4594V40.1972C74.2251 40.1637 73.9478 40.148 73.6526 40.148C73.0308 40.148 72.7669 40.4097 72.7669 41.1142V41.6219H74.3727V43.1786H72.7669V50.0964H70.4386V43.1786V43.1809Z" fill="#003865"/>
    <path d="M82.7824 40.5573H79.2754V38.3945H88.8637V40.5573H85.3567V50.0964H82.7824V40.5573Z" fill="#003865"/>
    <path d="M90.2884 46.4269C90.3532 47.903 91.0757 48.574 92.3707 48.574C93.3056 48.574 94.0593 48.0014 94.2069 47.4758H96.2556C95.6003 49.4754 94.2069 50.3275 92.2879 50.3275C89.6152 50.3275 87.9601 48.4913 87.9601 45.87C87.9601 43.2487 89.7136 41.3945 92.2879 41.3945C95.1731 41.3945 96.5665 43.8212 96.4032 46.4269H90.2884ZM94.0749 44.9507C93.8625 43.7698 93.3548 43.148 92.223 43.148C90.7469 43.148 90.3219 44.2954 90.2884 44.9507H94.0749Z" fill="#003865"/>
    <path d="M103.78 44.6063C103.632 43.6557 103.026 43.148 102.057 43.148C100.566 43.148 100.074 44.6555 100.074 45.9013C100.074 47.1471 100.55 48.574 102.008 48.574C103.091 48.574 103.712 47.8851 103.86 46.8518H106.106C105.81 49.0974 104.254 50.3275 102.024 50.3275C99.4674 50.3275 97.7452 48.5248 97.7452 45.984C97.7452 43.4433 99.3198 41.3945 102.073 41.3945C104.073 41.3945 105.909 42.4435 106.056 44.6063H103.777H103.78Z" fill="#003865"/>
    <path d="M107.649 38.3945H109.977V42.8029H110.026C110.617 41.8523 111.632 41.3938 112.502 41.3938C114.978 41.3938 115.6 42.7872 115.6 44.8851V50.0987H113.272V45.3123C113.272 43.9189 112.862 43.2301 111.78 43.2301C110.518 43.2301 109.977 43.9346 109.977 45.6568V50.0987H107.649V38.3945Z" fill="#003865"/>
    <path d="M117.597 41.6227H119.809V42.8036H119.859C120.449 41.853 121.464 41.3945 122.449 41.3945C124.924 41.3945 125.546 42.7879 125.546 44.8859V50.0994H123.218V45.3131C123.218 43.9197 122.809 43.2308 121.726 43.2308C120.465 43.2308 119.923 43.9353 119.923 45.6575V50.0994H117.595V41.6249L117.597 41.6227Z" fill="#003865"/>
    <path d="M131.661 41.3945C134.334 41.3945 136.054 43.1659 136.054 45.87C136.054 48.574 134.331 50.3275 131.661 50.3275C128.99 50.3275 127.284 48.5561 127.284 45.87C127.284 43.1838 129.006 41.3945 131.661 41.3945ZM131.661 48.574C133.251 48.574 133.725 47.2142 133.725 45.87C133.725 44.5258 133.251 43.148 131.661 43.148C130.071 43.148 129.612 44.5079 129.612 45.87C129.612 47.2321 130.089 48.574 131.661 48.574Z" fill="#003865"/>
    <path d="M137.859 38.3945H140.187V50.0987H137.859V38.3945Z" fill="#003865"/>
    <path d="M146.364 41.3945C149.037 41.3945 150.757 43.1659 150.757 45.87C150.757 48.574 149.035 50.3275 146.364 50.3275C143.694 50.3275 141.987 48.5561 141.987 45.87C141.987 43.1838 143.71 41.3945 146.364 41.3945ZM146.364 48.574C147.955 48.574 148.429 47.2142 148.429 45.87C148.429 44.5258 147.955 43.148 146.364 43.148C144.774 43.148 144.316 44.5079 144.316 45.87C144.316 47.2321 144.792 48.574 146.364 48.574Z" fill="#003865"/>
    <path d="M160.643 49.5565C160.643 50.9499 160.151 53.3095 156.25 53.3095C154.577 53.3095 152.627 52.5222 152.513 50.5875H154.823C155.036 51.4553 155.74 51.7506 156.561 51.7506C157.856 51.7506 158.447 50.8649 158.431 49.6526V48.5388H158.397C157.89 49.4245 156.872 49.8494 155.857 49.8494C153.316 49.8494 152.233 47.9148 152.233 45.5865C152.233 43.3901 153.495 41.3906 155.872 41.3906C156.986 41.3906 157.838 41.7686 158.397 42.7505H158.431V41.6188H160.643V49.552V49.5565ZM158.429 45.7542C158.429 44.3765 157.952 43.1486 156.445 43.1486C155.134 43.1486 154.559 44.296 154.559 45.5574C154.559 46.8189 155.018 48.0982 156.445 48.0982C157.773 48.0982 158.429 46.9665 158.429 45.7542Z" fill="#003865"/>
    <path d="M166.921 51.1641C166.413 52.524 165.61 53.0809 164.005 53.0809C163.53 53.0809 163.054 53.0473 162.578 52.9981V51.0813C163.02 51.1149 163.479 51.1798 163.937 51.1641C164.74 51.0813 165.002 50.2471 164.74 49.5739L161.757 41.625H164.248L166.165 47.4267H166.199L168.051 41.625H170.459L166.919 51.1641H166.921Z" fill="#003865"/>
    <path d="M12.7701 10.7266C9.75739 10.7266 7.31726 13.1689 7.31726 16.1794C7.31726 19.1899 9.75963 21.6322 12.7701 21.6322C13.5686 21.6322 14.3245 21.46 15.0089 21.1536L10.3456 16.3516H16.2323L17.8897 18.0581C18.1044 17.4722 18.2229 16.8392 18.2229 16.1794C18.2229 13.1667 15.7806 10.7266 12.7701 10.7266Z" fill="#003865"/>
    <path d="M0.307739 0.46875V55.7732H55.6122V0.46875H0.307739ZM20.2873 26.5922L18.6859 24.9416C16.9973 26.0823 14.9619 26.751 12.7723 26.751C6.93479 26.751 2.20438 22.0184 2.20438 16.1831C2.20438 10.3478 6.93479 5.61293 12.7701 5.61293C18.6054 5.61293 23.338 10.3456 23.338 16.1809C23.338 18.2989 22.714 20.2716 21.6427 21.9245L26.174 26.59H20.2873V26.5922ZM39.8799 21.5577C39.8799 21.5577 39.0121 25.805 34.6194 26.335C34.6194 26.335 30.4683 27.252 27.8157 25.6104C27.8157 25.6104 24.5346 24.2595 24.3892 21.2669V6.11617H29.4081V17.8427L29.5043 19.869C29.5043 19.869 28.925 21.9177 32.1346 22.0989C32.1346 22.0989 34.5725 22.1369 34.7156 20.5914L34.8118 7.17631L39.8799 12.533V21.5577ZM52.8634 11.2313H48.8107V26.3373H43.7425V11.0859H40.2199L35.5879 6.11617H52.8656V11.2313H52.8634Z" fill="#003865"/>
    <path d="M40.2177 11.0869H43.7403V26.3361H48.8085V11.2323H52.8634V6.11719H35.5857L40.2177 11.0869Z" fill="white"/>
    <path d="M34.7179 20.5947C34.5725 22.138 32.1368 22.1022 32.1368 22.1022C28.9273 21.921 29.5066 19.8723 29.5066 19.8723L29.4104 17.8459V6.11719H24.3915V21.2702C24.5369 24.2627 27.818 25.6136 27.818 25.6136C30.4728 27.2553 34.6217 26.3383 34.6217 26.3383C39.0144 25.8082 39.8822 21.5609 39.8822 21.5609V12.5362L34.814 7.17957L34.7179 20.5947Z" fill="white"/>
    <path d="M23.3403 16.1812C23.3403 10.3437 18.6076 5.61328 12.7723 5.61328C6.93703 5.61328 2.20215 10.3437 2.20215 16.1812C2.20215 22.0187 6.93479 26.7492 12.7701 26.7492C14.962 26.7492 16.9973 26.0827 18.6837 24.9397L20.2851 26.5904H26.1718L21.6404 21.9248C22.714 20.272 23.3358 18.2993 23.3358 16.1812H23.3403ZM17.8919 18.06L16.2346 16.3534H10.3478L15.0112 21.1554C14.329 21.4641 13.5708 21.6341 12.7723 21.6341C9.75962 21.6341 7.31949 19.1917 7.31949 16.1812C7.31949 13.1708 9.76186 10.7284 12.7723 10.7284C15.7828 10.7284 18.2252 13.1708 18.2252 16.1812C18.2252 16.841 18.1066 17.474 17.8919 18.06Z" fill="white"/>
  </SVG>
)

export default QueenslandLogo
