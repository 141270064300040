import { useTranslation } from 'react-i18next'
import UnsupportedFileStyles from './UnsupportedFile.module.scss'

const UnsupportedFile = () => {
  const { t } = useTranslation()
  return (
    <div className={UnsupportedFileStyles['unsupported-file']}>
      <p className={UnsupportedFileStyles['unsupported-file__txt']}>{t('uploads.unsupportedFileView')}</p>
    </div>
  )
}

export default UnsupportedFile
