import { useTranslation } from 'react-i18next'
import CurrentTimeStyles from './CurrentTime.module.scss'

export const CurrentTime = ({ time }) => {
  const { t } = useTranslation()

  return (
    <div className={CurrentTimeStyles['container']}>
      <span className={CurrentTimeStyles['label']}>
        {t("main.currentTime")}
      </span>
      <span className={CurrentTimeStyles['time']}> {time}</span>
    </div>
  )
}
