import { useEffect } from 'react'
import { KeycloakService } from '@nv/auth'
import { VoterAuthService } from '@nv/services'
import { beforeUnload } from '@nv/utils'
import { languageKeyToValue } from '@nv/utils'
import { setAuthKcUser, useAuthKc } from '@nv/zustand'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

export const useVoterKeyCloakAuth = () => {
  // so admin view means loggedin in keycloak for voter
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  const { initialized, user: { gotUserData } } = useAuthKc()

  const { NX_PUBLIC_KEYCLOAK_URL } = process.env
  const keycloakConfig = {
    url: NX_PUBLIC_KEYCLOAK_URL,
    clientId: 'voter-registration-react',
    realm: 'voter-registration'
  }

  // console.log('Keycloak Initialized:', useAuthKc.getState().initialized)
  // console.log('User Token:', useAuthKc.getState().user?.token)
  // console.log('Is Logged In:', KeycloakService?.isLoggedIn())

  useEffect(() => {
    const l = localStorage.getItem('language')
    if (l) localStorage.setItem('language', currentLanguage)
    KeycloakService.initKeycloak(keycloakConfig)
  }, [])

  const history = useHistory()

  const clearLocalSession = () => {
    window.removeEventListener('beforeunload', beforeUnload)
    setAuthKcUser()
    if (
      !window.location.pathname.includes('/voting/voter-login/') &&
      !window.location.pathname.includes('/voting/voter/login') &&
      !window.location.pathname.startsWith('/voting/voter-onboarding/phone-verify') &&
      !window.location.pathname.startsWith('/voting/voter-verify/verify') &&
      !window.location.pathname.startsWith('/voting/voter-auth/create-password') &&
      !window.location.pathname.includes('/voting/voter-auth/reset-password') &&
      !window.location.pathname.includes('/voting/voter/single-sign-on') &&
      !window.location.pathname.includes('/registration')
    ) {
      history.push('/')
    }
  }

  const checkAuth = async () => {
    if (!gotUserData && KeycloakService?.isLoggedIn()) {
      const tokenParsed = KeycloakService?.getTokenParsed()
      if (tokenParsed) {
        const user: any = await VoterAuthService.getUserInfo()
        if (user) {
          const votingChannel = user.votingChannel ?? tokenParsed.votingChannel
          setAuthKcUser({
            ...tokenParsed,
            ...user,
            votingChannel: votingChannel,
            username: tokenParsed.preferred_username,
            adminView: votingChannel === 'online',
            gotUserData: true
          })

          if (votingChannel === 'online') {
            const receipt = sessionStorage.getItem('receipt')
            if (receipt) {
              const eventId = sessionStorage.getItem('eventId')
              const event = await VoterAuthService.getEventById(eventId)
              if (event) {
                const hasVoted = user?.votedInEvents?.includes(eventId)
                history.push('/election', { event, hasVoted, receipt })
              } else {
                history.push('/voting/voter/dashboard')
              }
            } else {
              history.push('/voting/voter/dashboard')
            }
            window.addEventListener('beforeunload', beforeUnload)
          }
        }
      }
      // recheck language once all auth redirects are complete
      checkLanguage()
    }
    if (initialized && !KeycloakService?.isLoggedIn()) {
      // console.log("clear session called")
      clearLocalSession()
    }
    if (initialized && KeycloakService?.isLoggedIn() && useAuthKc.getState().user?.votingChannel === 'inPerson') {
      window.removeEventListener('beforeunload', beforeUnload)
      setAuthKcUser({ adminView: false })
      //history.push('/')
    }
  }

  useEffect(() => {
    checkAuth()
  }, [gotUserData, initialized, KeycloakService?.isLoggedIn()])

  useEffect(() => {
    checkTiviLanguage()
    checkLanguage()
  }, [])

  // set language saved in app
  const setSavedLanguage = (language) => {
    localStorage.setItem('language', language)
    if (KeycloakService.isLoggedIn()) {
      VoterAuthService.updateUserLanguage(language)
    }
  }

  const checkTiviLanguage = () => {
    // save local url param in session storage so it will persist redirects
    // remove from session storage once updated in app
    var url = new URL(window.location.href)
    const tiviLocale = url.searchParams?.get('locale')
    if (tiviLocale) {
      const language = languageKeyToValue[tiviLocale]
      sessionStorage.setItem('language', language)
    }
  }

  const seti18nLocale = (language) => {
    i18n.changeLanguage(language)
  }

  const checkLanguage = () => {
    // get language value from browser, Tivi, localStorage, or keycloak token and set in app
    const tiviLanguage = sessionStorage.getItem('language')
    const savedLanguage = localStorage.getItem('language')
    const tokenParsed = KeycloakService?.getTokenParsed()

    if (tiviLanguage) {
      setSavedLanguage(tiviLanguage)
      sessionStorage.removeItem('language')
      seti18nLocale(tiviLanguage)
    } else if (savedLanguage) {
      setSavedLanguage(savedLanguage)
      seti18nLocale(savedLanguage)
    } else if (tokenParsed?.locale) {
      const language = languageKeyToValue[tokenParsed.locale]
      setSavedLanguage(language)
      i18n.changeLanguage(tokenParsed.locale)
    } else if (currentLanguage) {
      setSavedLanguage(currentLanguage)
      seti18nLocale(currentLanguage)
    }
  }
}
