import clsx from 'clsx'
import PBStepStyles from './PBStep.module.scss'

export const PBStep = ({ step, label, isActive }) => {
  return (
    <div className={PBStepStyles['container']}>
      <div
        className={clsx(
          PBStepStyles['number'],
          isActive ? PBStepStyles['number--active'] : PBStepStyles['number--default']
        )}
      >
        <span className={PBStepStyles['number__text']}>{step}</span>
      </div>

      <span
        className={clsx(
          PBStepStyles['label'],
          isActive ? PBStepStyles['label--active'] : PBStepStyles['label--default']
        )}
      >
        {label}
      </span>
    </div>
  )
}
