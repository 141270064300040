import { useContext, useEffect, useState } from 'react'
import { KeycloakService } from '@nv/auth'
import { KeycloakAdminService } from '@nv/auth'
import { useSessionTimer } from '@nv/contexts'
import { LoginFlowContext } from '@nv/contexts'
import { AdminVoterAuthService } from '@nv/services'
import { VoterAuthService } from '@nv/services'
import { beforeUnload } from '@nv/utils'
import { DateTime } from 'luxon'
import { useHistory } from 'react-router-dom'

const SESSION_DURATION_MINS = 30
const REMAINING_TIME_WARN_MINS = 1

const formatTwodigits = (numStr) => {
  // let numStr = num.toString()
  numStr = numStr.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })
  return numStr
}

export function useKeycloakSessionTime() {
  const history = useHistory()
  const LOGIN_CONTEXT = useContext(LoginFlowContext)

  const admin = window.location.pathname?.startsWith('/admin') ? true : false
  const KcService = admin ? KeycloakAdminService : KeycloakService

  const onSessionExpired = async () => {
    try {
      window.removeEventListener('beforeunload', beforeUnload)
      if (!admin) {
        await VoterAuthService.logOut(history)
        LOGIN_CONTEXT.setLoginFlow('Login')
      } else {
        await AdminVoterAuthService.logOut(history)
        LOGIN_CONTEXT.setLoginFlow('Admin Login')
      }
    } catch (error) {
      //console.log(error)
    }
  }

  const [sessionTimeRemaining, setSessionTimeRemaining] = useState('')
  const { setSessionEnding } = useSessionTimer()

  const getSessionTimeRemaining = async () => {
    try {
      const sessionEndTime = DateTime.fromSeconds(KcService.getSessionStartTime())
        .plus({ minutes: SESSION_DURATION_MINS })
      const sessionEndTimeDiff = sessionEndTime.diffNow(['seconds', 'minutes', 'milliseconds'])

      const minutesLeft = sessionEndTimeDiff.get('minutes')
      const secondsLeft = sessionEndTimeDiff.get('seconds')
      const formattedMins = formatTwodigits(minutesLeft)
      const formattedSecs = formatTwodigits(secondsLeft)

      //console.log(`${formattedMins}:${formattedSecs}`)

      setSessionTimeRemaining(`${formattedMins} min ${formattedSecs} s`)
      if (admin && minutesLeft === REMAINING_TIME_WARN_MINS && secondsLeft === 0) {
        // display pop up
        window.removeEventListener('beforeunload', beforeUnload)
        setSessionEnding(true)
      }

      // admins are now getting 1 minute at the end of their session to decide to extend the session
      if (sessionEndTimeDiff <= 0) {
        onSessionExpired()
      }
    } catch (error) { /* empty */ }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      // for unauthenticated change password page
      getSessionTimeRemaining()
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return sessionTimeRemaining
}
