import { useEffect, useState } from 'react'
import { TextField } from '@nv/basic-components'
import { AdminVoterAuthService } from '@nv/services'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-number-input'
import * as Yup from 'yup'
import { AdminRoleDropdown } from '../../../admin_role_dropdown/AdminRoleDropDown'
import { ErrorLabel } from '../../../error_label/ErrorLabel'
import { ModalWrapper } from '../../ModalWrapper'
import NewAdminModalStyles from './NewAdminUserModal.module.scss'
import 'react-phone-number-input/style.css'

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  phone: '',
  roles: [],
}

export const NewAdminUserModal = ({
  rolesOptions,
  setRefreshList,
  onRequestClose = undefined,
  isOpen = undefined,
}) => {
  const { t } = useTranslation()
  const [roleValue, setRoleValue] = useState({})
  const [roleErr, setRoleErr] = useState(false)

  const schema = Yup.object().shape({
    firstName: Yup.string().required(t('admin.users.firstNameRequired')),
    lastName: Yup.string().required(t('admin.users.lastNameRequired')),
    email: Yup.string()
      .email(t('admin.users.wrongEmailFormat'))
      .required(t('form.validations.email')),
    username: Yup.string().required(t('auth.login.validations.username.admin')),
    phone: Yup.string().required(t('form.validations.phone')),
  })

  function isEmpty(obj) {
    return Object.keys(obj).length === 0
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (isEmpty(roleValue)) {
        setRoleErr(true)
        return
      }

      const userInput = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        username: values.username,
        phoneNumber: values.phone,
        roles: roleValue ? [roleValue] : [],
      }

      const result = await AdminVoterAuthService.adminCreateUser(userInput)
      if (result.success) {
        onClose()
        alert(t('form.messages.success'))
        setRefreshList(true)
      } else {
        // TODO: Handle error
      }
    },
  })

  const onClose = () => {
    formik.resetForm()
    setRoleValue({})
    setRoleErr(false)
    onRequestClose()
  }

  useEffect(() => {
    if (!isEmpty(roleValue)) {
      setRoleErr(false)
    }
  }, [roleValue])

  const btns = [
    {
      text: t('buttons.cancel'),
      onClick: onClose,
      isCancel: true,
    },
    {
      text: t('buttons.createUserAndSend'),
      onClick: () => formik.handleSubmit(),
    },
  ]

  return (
    <ModalWrapper
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      headerLabel={t('admin.users.newAdminUser')}
      btns={btns}
    >
      <div className={NewAdminModalStyles['inputs__container']}>
        <TextField
          formik={formik}
          type='text'
          field='firstName'
          placeholder={t('form.labels.firstName')}
          label={t('form.labels.firstName')}
          isRequired={true}
        />

        <TextField
          formik={formik}
          type='text'
          field='lastName'
          placeholder={t('form.labels.lastName')}
          label={t('form.labels.lastName')}
          isRequired={true}
        />

        <TextField
          formik={formik}
          type='text'
          field='username'
          placeholder={t('form.labels.username')}
          label={t('form.labels.username')}
          isRequired={true}
        />

        <TextField
          formik={formik}
          type='email'
          field='email'
          placeholder={t('form.labels.enterEmail')}
          label={t('form.labels.enterEmail')}
          isRequired={true}
        />

        {t('form.labels.phoneNumber')}
        <PhoneInput
          className={NewAdminModalStyles['phone__input']}
          defaultCountry={'CA'}
          placeholder={t('form.labels.phoneNumber')}
          value={formik.values.phone}
          onChange={(value) => {
            formik.setFieldValue('phone', value)
          }}
        />

        <AdminRoleDropdown
          title={t('admin.dropdown.role')}
          options={rolesOptions}
          value={roleValue}
          setValue={setRoleValue}
          isRequired={true}
        />

        {roleErr && <ErrorLabel label={t('admin.users.roleRequired')} />}
      </div>
    </ModalWrapper>
  )
}
